import { device } from 'device-dimensions';
import styled, { css } from 'styled-components';

import { SelectRow } from 'pages/Marketplace/styles';

import * as AS from 'styles/AppStyles';

export const StyledHeader = styled.header`
	background-color: white;
	height: 72px;
	position: relative;
	box-shadow: -2px 13px 15px -13px rgba(0, 0, 0, 0.32);
	-webkit-box-shadow: -2px 13px 15px -13px rgba(0, 0, 0, 0.32);
	-moz-box-shadow: -2px 13px 15px -13px rgba(0, 0, 0, 0.32);
`;

export const Container = styled.div`
	max-width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	height: 100%;
	@media ${device.mobileL} {
		display: none;
	}
`;

export const DropdownRow = styled(SelectRow)`
	width: auto;
	& > .react-select {
		& .select__control:hover .select__placeholder {
			color: #1cbf7b;
		}
		& .select__control:hover .select__indicator {
			color: #1cbf7b !important;
		}
	}
`;

export const MobileContainer = styled.div`
	display: none;
	@media ${device.mobileL} {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		height: 100%;
		padding: 0 10px;
	}
`;

export const ButtonDiv = styled.div`
	width: 70%;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	@media ${device.laptopL} {
		width: 80%;
	}
`;

export const Logo = styled.img`
	width: 150px;
	position: absolute;
	top: 8px;
	left: 20px;
	@media ${device.mobileL} {
		display: none;
	}
`;

export const MobileLogo = styled.img`
	display: none;
	@media ${device.mobileL} {
		display: flex;
		width: 58px;
	}
`;

export const MobileWalletButton = styled.button`
	background: linear-gradient(91.5deg, #1cbf7b 3%, #00b3cc 97.14%);
	padding: 13px;
	border-radius: 50%;
	border: none;
	color: white;
`;

export const MobileMenu = styled.div`
	display: none;
	@media ${device.mobileL} {
		display: block;
		color: #1cbf7b;
		width: 50px;
		transition: all 0.3s cubic-bezier(0, 0.55, 0.45, 1);
		${({ openMobileDrawer }) =>
			openMobileDrawer &&
			css`
				transform: rotateZ(90deg);
			`}
	}
`;

export const WalletButton = styled.button`
	justify-content: center;
	background: linear-gradient(91.5deg, #1cbf7b 3%, #00b3cc 97.14%);
	border: none;
	color: white;
	cursor: pointer;
	display: flex;
	align-items: center;
	font-family: Bord;
	font-size: 16px;
	font-weight: 400;
	height: 48px;
	line-height: 20px;
	padding: 14px 32px;
	gap: 12px;
	border-radius: 40px;
	width: 305px;
	transition: all 0.3s ease-in-out;
	&:hover {
		transform: scale(1.025);
	}
`;

// Mobile Drawer

export const DrawerContainer = styled.div`
	position: relative;
`;

export const WalletButtonRow = styled(AS.FlexRow)`
	padding: 30px 10px;
	border-bottom: 1px solid #eeeeee;
	border-top: 1px solid #eeeeee;
	width: 100%;
`;

export const DrawerMenu = styled(AS.Column)`
	justify-content: center;
	align-items: flex-end;
	text-align: right;
	padding: 30px 25px;
	gap: 30px;
`;

// View Wallet Container

export const ViewWalletContainer = styled.div`
	position: absolute;
	right: 10px;
	height: 300px;
	width: 500px;
	background-color: white;
	border-radius: 10px;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	z-index: 1;
`;

export const WalletContainerRow = styled(AS.FlexRow)`
	gap: 10px;
	padding: 15px 25px;
	justify-content: flex-start;
`;

export const MobileAddressText = styled(AS.EllipsisText)`
	font-family: 'Helvetica Neue';
	font-size: 18px;
	color: black;
`;

// React Select Styles for Marketplace

export const getMarketplaceSelectStyles = marketplaceMenuOpen => ({
	control: base => ({
		...base,
		backgroundColor: 'transparent',
		boxShadow: 'none',
		fontSize: '18px',
		padding: '5px',
		cursor: 'pointer',
		display: 'flex',
		overflow: 'hidden',
		position: 'relative',
		zIndex: 4,
		lineHeight: 1,
		transition: 'all 0.3s ease-in-out',
		alignItems: 'center',
		userSelect: 'none',
		border: 'none',
	}),
	menuPortal: base => ({ ...base, zIndex: 9999 }),
	valueContainer: (styles, { data, isDisabled, isFocused, isSelected }) => {
		return {
			...styles,
			color: 'white',
			padding: 0,
		};
	},
	placeholder: base => ({
		...base,
		color: 'black',
		marginRight: '10px',
		whiteSpace: 'nowrap',
		fontFamily: 'Bord',
		fontSize: '18px',
		textAlign: 'center',
		zIndex: 5,
		transition: 'all 0.3s ease-in-out',
	}),
	indicatorSeparator: () => ({ display: 'none' }),
	dropdownIndicator: base => ({
		...base,
		color: 'black',
		padding: '2px 2px 2px 0',
		transition: 'all 0.3s ease-in-out',
		fontSize: '16px',
		transform: marketplaceMenuOpen ? 'rotate(180deg)' : null,
	}),
	option: styles => {
		return {
			...styles,
			position: 'relative',
			backgroundColor: 'white',
			color: 'black',
			textAlign: 'center',
			fontFamily: 'Bord',
			fontSize: '18px',
			padding: '20px 15px',
			cursor: 'pointer',
			transition: 'all 0.3s ease-in-out',
			maxWidth: '100%',
			'&:hover': {
				backgroundColor: '#E8F9F2',
			},
		};
	},
	menuList: base => ({
		...base,
		height: '100%',
		maxHeight: '100%',
		paddingTop: 0,
		paddingBottom: 0,
		zIndex: 3,
		boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.12)',
		borderRadius: '8px',
		'  &::-webkit-scrollbar-track': {
			backgroundColor: 'whitesmoke',
			borderRadius: '7px',
			marginRight: '2px',

			'&:hover': {
				backgroundColor: 'whitesmoke',
			},
		},
		'&::-webkit-scrollbar': {
			width: '6px',
			backgroundColor: 'whitesmoke',
			'&:hover': { backgroundColor: 'whitesmoke' },
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: '#8e8e8e',
			borderRadius: '7px',
			transition: 'all 0.3s ease-in-out',
			'&:hover': { backgroundColor: '#757575', cursor: 'pointer !important' },
		},
	}),
});

// React Select Styles For My Account

export const getMyAccountSelectStyles = myAccountMenuOpen => ({
	control: base => ({
		...base,
		backgroundColor: 'transparent',
		boxShadow: 'none',
		fontSize: '18px',
		padding: '5px',
		cursor: 'pointer',
		display: 'flex',
		overflow: 'hidden',
		position: 'relative',
		zIndex: 4,
		lineHeight: 1,
		transition: 'all 0.3s ease-in-out',
		alignItems: 'center',
		userSelect: 'none',
		border: 'none',
	}),
	menuPortal: base => ({ ...base, zIndex: 9999 }),
	valueContainer: (styles, { data, isDisabled, isFocused, isSelected }) => {
		return {
			...styles,
			color: 'white',
			padding: 0,
		};
	},
	placeholder: base => ({
		...base,
		color: 'black',
		marginRight: '10px',
		whiteSpace: 'nowrap',
		fontFamily: 'Bord',
		fontSize: '18px',
		textAlign: 'center',
		zIndex: 5,
		transition: 'all 0.3s ease-in-out',
	}),
	indicatorSeparator: () => ({ display: 'none' }),
	dropdownIndicator: base => ({
		...base,
		color: 'black',
		padding: '2px 2px 2px 0',
		transition: 'all 0.3s ease-in-out',
		fontSize: '16px',
		transform: myAccountMenuOpen ? 'rotate(180deg)' : null,
	}),
	option: styles => {
		return {
			...styles,
			position: 'relative',
			backgroundColor: 'white',
			color: 'black',
			textAlign: 'center',
			fontFamily: 'Bord',
			fontSize: '18px',
			padding: '20px 15px',
			cursor: 'pointer',
			transition: 'all 0.3s ease-in-out',
			maxWidth: '100%',
			'&:hover': {
				backgroundColor: '#E8F9F2',
			},
		};
	},
	menuList: base => ({
		...base,
		width: '250px',
		paddingTop: 0,
		paddingBottom: 0,
		zIndex: 3,
		boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.12)',
		borderRadius: '8px',
		'  &::-webkit-scrollbar-track': {
			backgroundColor: 'whitesmoke',
			borderRadius: '7px',
			marginRight: '2px',

			'&:hover': {
				backgroundColor: 'whitesmoke',
			},
		},
		'&::-webkit-scrollbar': {
			width: '6px',
			backgroundColor: 'whitesmoke',
			'&:hover': { backgroundColor: 'whitesmoke' },
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: '#8e8e8e',
			borderRadius: '7px',
			transition: 'all 0.3s ease-in-out',
			'&:hover': { backgroundColor: '#757575', cursor: 'pointer !important' },
		},
	}),
});

// React Select Styles For Resource Center

export const getResourceCenterSelectStyles = resourceCenterMenuOpen => ({
	control: base => ({
		...base,
		backgroundColor: 'transparent',
		boxShadow: 'none',
		fontSize: '18px',
		padding: '5px',
		cursor: 'pointer',
		display: 'flex',
		overflow: 'hidden',
		position: 'relative',
		zIndex: 4,
		lineHeight: 1,
		transition: 'all 0.3s ease-in-out',
		alignItems: 'center',
		userSelect: 'none',
		border: 'none',
	}),
	menuPortal: base => ({ ...base, zIndex: 9999 }),
	valueContainer: (styles, { data, isDisabled, isFocused, isSelected }) => {
		return {
			...styles,
			color: 'white',
			padding: 0,
		};
	},
	placeholder: base => ({
		...base,
		color: 'black',
		marginRight: '10px',
		whiteSpace: 'nowrap',
		fontFamily: 'Bord',
		fontSize: '18px',
		textAlign: 'center',
		zIndex: 5,
		transition: 'all 0.3s ease-in-out',
	}),
	indicatorSeparator: () => ({ display: 'none' }),
	dropdownIndicator: base => ({
		...base,
		color: 'black',
		padding: '2px 2px 2px 0',
		transition: 'all 0.3s ease-in-out',
		fontSize: '16px',
		transform: resourceCenterMenuOpen ? 'rotate(180deg)' : null,
	}),
	option: styles => {
		return {
			...styles,
			position: 'relative',
			backgroundColor: 'white',
			color: 'black',
			textAlign: 'center',
			fontFamily: 'Bord',
			fontSize: '18px',
			padding: '20px 15px',
			cursor: 'pointer',
			transition: 'all 0.3s ease-in-out',
			maxWidth: '100%',
			'&:hover': {
				backgroundColor: '#E8F9F2',
			},
		};
	},
	menuList: base => ({
		...base,
		width: '100%',
		height: '100%',
		minHeight: '100%',
		overflowY: 'hidden',
		paddingTop: 0,
		paddingBottom: 0,
		zIndex: 3,
		boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.12)',
		borderRadius: '8px',
	}),
});
