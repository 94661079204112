import { testMode } from './testMode';

const testPlatforms = [
	{
		name: 'decentraland',
		address: '0xc7e4Ac0747072e020B19156462aE930CA20Bfd1E',
	},
	{
		name: 'somnium-space',
		address: '0xE944B9A6eFBE31D0453569e241a03a74A995ed50',
	},
	{
		name: 'the-sandbox',
		address: '0x28721Aea4147AA7de096DC9F770AD93dcea3a440',
	},
	{
		name: 'voxels',
		address: '0x1c67e5c3b92A139681f38516863459023565f721',
	},
];

const mainPlatforms = [
	{
		name: 'decentraland',
		address: '0xF87E31492Faf9A91B02Ee0dEAAd50d51d56D5d4d',
	},
	{
		name: 'somnium-space',
		address: '0x913ae503153d9A335398D0785Ba60A2d63dDB4e2',
	},
	{
		name: 'the-sandbox',
		address: '0x5CC5B05a8A13E3fBDB0BB9FcCd98D38e50F90c38',
	},
	{
		name: 'voxels',
		address: '0x79986aF15539de2db9A5086382daEdA917A9CF0C',
	},
];

export const platforms = testMode ? testPlatforms : mainPlatforms;

export const platformAddrToName = address => {
	const addr = address.toLowerCase();
	const platform = platforms.find(platform => platform.address.toLowerCase() === addr);
	return platform.name;
};

export const isValidPlatform = address => {
	const addr = address.toLowerCase();
	return platforms.some(platform => platform.address.toLowerCase() === addr);
};
