import styled from 'styled-components/macro';

export const RoundButton = styled.button`
	color: white;
	background-color: ${({ backgroundColor }) => backgroundColor || '#1cbf7b'};
	width: ${({ width }) => width || '100px'};
	border-radius: 40px;
	height: 56px;
	font-size: 16px;
	border: 1px solid #1cbf7b;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.3s;
	padding: 14px 32px 14px 32px;
	font-family: 'Bord', sans-serif;
	font-weight: 400;
	box-shadow: none;
	transition: box-shadow 0.2s ease-in-out;
	&:disabled {
		cursor: default;
	}
	&:active {
		opacity: 0.85;
	}
	&:hover {
		box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
	}
`;
