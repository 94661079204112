import { createGlobalStyle } from 'styled-components';

import Bord from './Bord.ttf';
import HelveticaNeue from './Helveticaneue.ttf';

export default createGlobalStyle`
    @font-face {
        font-family: 'Bord';
        src: local('Bord'), local('Bord'),
        url(${Bord}) format('truetype');
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: "Helvetica Neue";
        src: local('Helvetica Neue'), local('Helvetica Neue'),
        url(${HelveticaNeue}) format('truetype');
        font-weight: 400;
        font-style: normal;
    }
`;
