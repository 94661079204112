import { RefreshOutline } from '@styled-icons/evaicons-outline/RefreshOutline';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Error } from 'pages/Error';

import { API_STATUS } from 'components/Common/Constants';
import { StyledLoader } from 'components/Loader/StyledLoader';

import { setNotification } from 'state/features/notificationSlice';

import { createTokenId } from 'utils/createTokenId';

import * as AS from 'styles/AppStyles';

import ListingsCard from '../ListingsCard';
import * as S from '../styles';

const ProfileWatchlist = ({ gridSelected, platformFilter, searchNFTsValue }) => {
	const dispatch = useDispatch();

	const [watchList, setWatchList] = useState([]);

	const allNFTs = useSelector(state => state.NFTs.value);
	const allNFTEvents = useSelector(state => state.NFTs.events);
	const getNFTEventsStatus = useSelector(state => state.NFTs.eventsStatus);

	// Wallet Pub Key

	const walletPubKey = useSelector(state => state.wallet.address);

	// Find all the NFTs that the user has bid on

	const userBiddedNFTs = () => {
		let eventNFTs = [];
		let filteredNFTs = [];

		const activeNFTs = allNFTs.filter(token => {
			const b1 = token.details.isActive;
			const b2 = token.details.endDate.toNumber() * 1000 > Date.now();
			const b3 = platformFilter === 'all' ? true : token.platform === platformFilter;
			const b4 = searchNFTsValue === '' ? true : token.title?.toLowerCase().includes(searchNFTsValue.toLowerCase());
			return b1 && b2 && b3 && b4;
		});

		const userNFTevents = allNFTEvents.filter(event => {
			const b1 = event.args.bidder === walletPubKey;
			return b1;
		});

		const userNFTIDs = userNFTevents.map(event => {
			return createTokenId(event.args.nft, event.args.salt, event.args.tokenId);
		});

		activeNFTs.forEach(nft => {
			userNFTIDs.forEach(token => {
				if (token === nft.id && !eventNFTs.includes(token)) {
					eventNFTs = [...eventNFTs, token];
					filteredNFTs = [...filteredNFTs, nft];
				}
			});
		});
		return filteredNFTs;
	};

	useEffect(() => {
		setWatchList(userBiddedNFTs());
	}, [allNFTEvents, allNFTs, platformFilter, searchNFTsValue]);

	return (
		<>
			{getNFTEventsStatus === API_STATUS.PENDING ? (
				<StyledLoader />
			) : getNFTEventsStatus === API_STATUS.REJECTED ? (
				<Error />
			) : (
				<>
					<S.ListingsFoundDiv>
						<S.RefreshOutlineDiv
							onClick={() =>
								dispatch(
									setNotification({
										msg: 'We have a queued an update for you! Please check back later...',
										alertType: 'success',
									})
								)
							}
						>
							<RefreshOutline />
						</S.RefreshOutlineDiv>
						<AS.Text color="black" fontSize="18px" fontFamily="Helvetica Neue" userSelectNone>
							<span style={{ fontWeight: 'bold' }}>{watchList.length}</span> Listings Found
						</AS.Text>
					</S.ListingsFoundDiv>
					<S.ListingsCardRow gridSelected={gridSelected}>
						{watchList?.map((nft, index) => {
							return <ListingsCard key={nft.id} noHover={false} nft={nft} />;
						})}
					</S.ListingsCardRow>
				</>
			)}
		</>
	);
};

export default ProfileWatchlist;
