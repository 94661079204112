import { Alchemy, Network } from 'alchemy-sdk';

import { createTokenId } from './createTokenId';
import { parsePlatformMetadata } from './parsePlatformMetadata';
import { testMode } from './testMode';
import { isValidPlatform, platformAddrToName } from './validatedPlatforms';

const settings = {
	apiKey: '-brfzcTz75Cv1JxfjosPWxg75s2oPLxx',
	network: testMode ? Network.ETH_GOERLI : Network.ETH_MAINNET,
};

export const validatedOwnedNFTs = async walletPubKey => {
	try {
		const alchemy = new Alchemy(settings);
		const tokens = await alchemy.nft.getNftsForOwner(walletPubKey);
		tokens.ownedNfts = tokens?.ownedNfts.filter(token => isValidPlatform(token.contract.address));
		tokens.ownedNfts = tokens?.ownedNfts.map(token => {
			const platform = platformAddrToName(token.contract.address);
			const props = parsePlatformMetadata(token.rawMetadata, platform);
			token = Object.assign({}, token, props);
			token = {
				...token,
				id: createTokenId(token.contract.address, 0, token.tokenId),
				platform: platform,
			};
			return token;
		});
		return tokens;
	} catch (error) {
		console.error(error);
		return [];
	}
};
