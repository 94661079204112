import { AnimatePresence } from 'framer-motion';

import * as S from './styles';

// import closeIcon from '../../svgs/svg-close.svg';

const Modal = ({ children, isOpen, animate, onChange }) => {
	const backdropVariants = {
		opened: { opacity: 0.6, transition: { duration: 0.5 } },
		closed: { opacity: 0, transition: { duration: 0.5 } },
		openedBody: {
			opacity: 1,
			top: `${50}%`,
			transition: { duration: 0.5 },
		},
		closedBody: { top: '30%', opacity: 0, transition: { duration: 0.5 } },
	};
	return (
		<>
			<AnimatePresence>
				{isOpen && (
					<>
						<S.ModalBackdrop
							animate={'opened'}
							initial={animate ? 'closed' : false}
							exit={'closed'}
							variants={backdropVariants}
							onClick={() => onChange(false)}
						/>
						<S.ModalBody animate={'openedBody'} initial={animate ? 'closedBody' : false} exit={'closedBody'} variants={backdropVariants}>
							{children}
						</S.ModalBody>
					</>
				)}
			</AnimatePresence>
		</>
	);
};

export default Modal;
