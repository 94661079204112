import { device } from 'device-dimensions';
import styled, { css } from 'styled-components/macro';

import { ReorderIconsRow } from 'pages/Marketplace/styles';

import { Card } from 'components/Card/styles';
import { StatusButton } from 'components/Common/Filters/styles';

import * as AS from 'styles/AppStyles';

export const Container = styled.div`
	overflow-x: auto;
	height: 100%;
	min-height: 100vh;
	position: relative;
	padding: 3%;
	display: flex;
	flex-direction: column;
	align-items: center;
	@media ${device.mobileL} {
		padding: 0;
		overflow-x: hidden;
	}
`;

export const MainRow = styled.div`
	display: grid;
	grid-template-columns: 0.5fr 0.5fr;
	grid-gap: 35px;
	align-items: flex-start;
	width: 100%;
	@media ${device.mobileL} {
		grid-template-columns: none;
		grid-template-rows: auto;
		padding: 15px;
	}
`;

export const MainColumn = styled(AS.Column)`
	position: relative;
`;

export const ParcelText = styled(AS.Text)`
	font-size: 28px;
	color: black;
	user-select: none;
	@media ${device.mobileL} {
		font-size: 24px;
	}
`;

export const SubRow = styled(AS.FlexRow)`
	@media ${device.mobileL} {
		padding: 0 15px;
	}
`;

export const NFTImage = styled.img`
	border-radius: 10px;
	width: 100%;
	max-width: 850px;
	max-height: 850px;
	height: 100%;
	object-fit: cover;
	@media ${device.mobileM} {
		width: 90%;
	}
`;

export const DescriptionRow = styled(AS.FlexRow)`
	@media ${device.mobileM} {
		width: 95%;
	}
`;

export const SaleContainer = styled.div`
	border: 1px solid #dbdbdb;
	border-radius: 20px;
	padding: 25px;
	width: 100%;
`;

export const SaleText = styled(AS.Text)`
	font-size: 18px;
	color: black;
	font-family: 'Helvetica Neue';

	& br {
		display: none;
		@media ${device.mobileL} {
			display: block;
		}
	}
	@media ${device.mobileL} {
		text-align: center;
	}
`;

export const TimerCard = styled.div`
	background: #f4f4f4;
	border-radius: 20px;
	padding: 10px;
	width: 75%;
	@media ${device.mobileL} {
		width: 100%;
	}
`;

export const TimerRow = styled(AS.FlexRow)`
	justify-content: center;
	@media ${device.mobileL} {
		display: grid;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		grid-template-rows: repeat(2, minmax(0, 1fr));
		grid-column-gap: 15px;
		grid-row-gap: 15px;
	}
`;

export const PriceRow = styled(AS.FlexRow)`
	align-items: flex-start;
	padding-bottom: 20px;
	border-bottom: 1px solid #dbdbdb;
	@media ${device.mobileL} {
		gap: 25px;
		flex-direction: column;
	}
`;

export const BuyNowRow = styled(AS.FlexRow)`
	position: relative;
	justify-content: center;
`;

export const MetaDataContainer = styled(AS.FlexRow)`
	justify-content: center;
	@media ${device.mobileL} {
		padding: 0 15px;
	}
`;

export const MetaDataCard = styled(Card)`
	background: #f8fdfb;
	border: 1px solid #1cbf7b;
	border-radius: 20px;
	padding: 20px 45px;
	&:hover {
		cursor: auto;
		box-shadow: none;
	}
`;

// Metadata Container Row for Decentraland

export const MetaDataDecentralandRow = styled.div`
	display: grid;
	grid-template-columns: 0.5fr 0.5fr;
	grid-template-rows: 0.5fr 0.5fr;
	width: 100%;
	grid-template-areas:
		'metadata details'
		'visitors  highlights';
	grid-gap: 30px;
	@media ${device.mobileL} {
		grid-template-columns: 1fr;
		grid-template-rows: none;
		grid-template-areas:
			'metadata'
			'visitors'
			'details'
			'highlights';
	}
	justify-content: center;
`;

// Grid-area items

export const MetaData = styled.div`
	grid-area: metadata;
`;

export const Details = styled.div`
	grid-area: details;
`;

export const Visitors = styled.div`
	grid-area: visitors;
`;

export const Hightlights = styled.div`
	grid-area: highlights;
`;

export const MetaDataText = styled(AS.Text)`
	color: black;
	font-size: 24px;
	user-select: none;
	@media ${device.mobileL} {
		text-align: center;
	}
`;

export const HighlightsButtonDiv = styled.div`
	display: flex;
	grid-gap: 20px;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	@media ${device.mobileL} {
		flex-wrap: wrap;
		justify-content: center;
	}
`;

export const RoadHighlightsButton = styled(StatusButton)`
	background-color: transparent;
	color: black;
	height: 65px;
	border-radius: 22px;
	grid-area: road;
`;

export const PlazaHighlightsButton = styled(RoadHighlightsButton)`
	grid-area: plaza;
`;

export const DistrictHighlightsButton = styled(RoadHighlightsButton)`
	grid-area: district;
`;

// Metadata Container Row for Sandbox

export const MetaDataSandboxRow = styled.div`
	display: grid;
	grid-template-columns: 0z5fr 0.5fr;
	width: 100%;
	@media ${device.mobileL} {
		grid-template-columns: none;
		grid-template-rows: auto;
		justify-content: center;
		grid-gap: 20px;
	}
`;

export const UniqueVisitorsColumn = styled(AS.Column)`
	@media ${device.mobileL} {
		text-align: center;
		align-items: center;
	}
`;

export const MetaDataColumn = styled(AS.Column)`
	@media ${device.mobileL} {
		align-items: center;
	}
`;

export const SpacerDiv = styled.div`
	display: block;
	@media ${device.mobileL} {
		display: none;
	}
`;

export const MetaDataSubRow = styled.div`
	display: grid;
	grid-template-columns: 0.3fr 0.4fr 0.3fr;
	width: 100%;
	justify-content: space-between;
	@media ${device.mobileL} {
		grid-template-columns: none;
		grid-template-rows: repeat(2, minmax(0, 1fr));
		justify-content: center;
	}
`;

export const MoreFromHeader = styled(AS.FlexRow)`
	gap: 18px;
	justify-content: center;
	@media ${device.mobileL} {
		flex-direction: column;
		gap: 0;
	}
`;

export const MoreFromRow = styled.div`
	display: grid;
	grid-template-columns: repeat(5, minmax(0, 1fr));
	grid-gap: 15px;
	@media ${device.mobileL} {
		grid-template-columns: none;
		grid-template-rows: auto;
		padding: 0 15px;
	}
`;

export const ViewMoreButtonRow = styled(AS.FlexRow)`
	justify-content: center;
	@media ${device.mobileL} {
		margin-bottom: 25px;
	}
`;

export const ShareNFTDiv = styled(ReorderIconsRow)`
	position: absolute;
	right: 0;
	justify-content: center;
	@media ${device.mobileL} {
		display: flex;
		height: 38px;
		top: -15px;
		width: 100px;
	}
`;

export const ShareIcon = styled.div`
	color: #8e8e8e;
	width: 60px;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	${({ gridSelected }) =>
		gridSelected
			? css`
					background-color: #f4fcf8;
					color: #1cbf7b;
					border-top-right-radius: 40px;
					border-bottom-right-radius: 40px;
					&:hover {
						cursor: pointer;
					}
			  `
			: css`
					&:hover {
						cursor: pointer;
						color: #1cbf7b;
					}
			  `}
`;

export const ReloadIcon = styled.div`
	color: #8e8e8e;
	width: 60px;
	-webkit-transition: all 0.3s ease-in-out;
	transition: color 0.3s ease-in-out, background-color 0.1s ease-in-out;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	${({ gridAltSelected }) =>
		gridAltSelected
			? css`
					background-color: #f4fcf8;
					color: #1cbf7b;
					border-top-left-radius: 40px;
					border-bottom-left-radius: 40px;
					&:hover {
						cursor: pointer;
					}
			  `
			: css`
					&:hover {
						cursor: pointer;
						color: #1cbf7b;
					}
			  `}
`;

export const ModalCard = styled.div`
	cursor: pointer;
	width: 85%;
	background-color: white;
	border: 1px solid #dbdbdb;
	box-shadow: none;
	border-radius: 10px;
	padding: 15px;
	transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
	&:hover {
		box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
	}
`;
