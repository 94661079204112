import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllMetadata, sendTokenMetadata } from 'actions/launchpad';
import { ethers } from 'ethers';

import { API_STATUS } from 'components/Common/Constants';

import { setNotification } from 'state/features/notificationSlice';

import { ETHProviderWithWallet } from 'utils/ETHProvider';
import { createTokenId } from 'utils/createTokenId';
import { createTokenMetadataBody } from 'utils/createTokenMetadataBody';
import { isAuthAlreadySet } from 'utils/isAuthAlreadySet';
import { parseMarketAuctions } from 'utils/parseMarketAuctions';
import { parsePlatformMetadata } from 'utils/parsePlatformMetadata';
import { platformAddrToName, platforms } from 'utils/validatedPlatforms';

import { setLoading } from './loadingSlice';

const initialState = {
	value: [],
	alchemyNFTs: [],
	events: [],
	status: null,
	eventsStatus: null,
	alchemyLoading: false,
	feePercent: '',
	error: '',
	platformRoyaltyFees: [],
	unfoundNFT: null,
	sellTrx: null,
};

// Async functions

export const getUnfoundNFT = createAsyncThunk('NFTs/getUnfoundNFT', async ({ contractAddressNFT, tokenId }, { dispatch }, thunkAPI) => {
	try {
		let foundNFT = await sendTokenMetadata(contractAddressNFT, tokenId);
		const id = createTokenId(contractAddressNFT, 0, tokenId);
		const platformName = platformAddrToName(contractAddressNFT);
		foundNFT = {
			...foundNFT,
			tokenId: foundNFT.id,
			id,
			platform: platformName,
		};
		const props = parsePlatformMetadata(foundNFT.metadata, platformName);
		let { metadata, ...NFT } = foundNFT;
		NFT = Object.assign({}, NFT, props);
		dispatch(setNotification({ msg: 'NFT Found!', alertType: 'success' }));
		return NFT;
	} catch (error) {
		dispatch(setLoading(false));
		const message = error.toString();
		dispatch(setNotification({ msg: message, alertType: 'error' }));
		return thunkAPI.rejectWithValue(message);
	}
});

// Get Platform Royalty Fees

export const getRoyaltyFees = createAsyncThunk('NFTs/getRoyaltyFees', async (_, { dispatch, getState }, thunkAPI) => {
	const state = getState();
	const { marketplaceContract } = state;
	const ethereumContract = marketplaceContract.noWallet;
	try {
		const fees = await Promise.all(
			platforms.map(async platform => {
				const { royaltyBasisPoints } = await ethereumContract.platforms(platform.address);
				return {
					platform: platform.name,
					fees: royaltyBasisPoints.toNumber(),
				};
			})
		);
		return fees;
	} catch (error) {
		const message = error.toString();
		dispatch(setNotification({ msg: message, alertType: 'error' }));
		return thunkAPI.rejectWithValue(message);
	}
});

// Get all NFT's

export const getNFTs = createAsyncThunk('NFTs/getNFTs', async (_, { dispatch, getState }, thunkAPI) => {
	const state = getState();
	const { marketplaceContract } = state;
	const ethereumContract = marketplaceContract.noWallet;
	try {
		const marketAuctions = await ethereumContract.getAllTokenAuctions();
		const newTokensArray = await createTokenMetadataBody(marketAuctions);
		const newTokensMetadata = await getAllMetadata(newTokensArray);
		let feePercent = await ethereumContract.feeBasisPoints();
		feePercent = parseInt(feePercent.toString()) / 100;
		const tokens = await parseMarketAuctions(marketAuctions, newTokensMetadata);
		return { tokens, feePercent };
	} catch (error) {
		const message = error.toString();
		dispatch(setNotification({ msg: message, alertType: 'error' }));
		return thunkAPI.rejectWithValue(message);
	}
});

// Sell NFT

export const sellNFT = createAsyncThunk(
	'NFTs/sellNFT',
	async (
		{
			erc721Contract,
			contractAddressNFT,
			tokenId,
			startingPriceWei,
			buyNowPriceWei,
			startDate,
			endDate,
			isAuctionAllowed,
			isBuyNowAllowed,
		},
		{ dispatch, getState },
		thunkAPI
	) => {
		const state = getState();
		const { marketplaceContract } = state;
		try {
			dispatch(setLoading(true));
			const marketAddr = marketplaceContract.withWallet.address;
			const isAuthSet = await isAuthAlreadySet(erc721Contract, tokenId, marketAddr);
			if (!isAuthSet) {
				const authTrx = await erc721Contract.approve(marketAddr, tokenId);
				await authTrx.wait();
			}
			await sendTokenMetadata(contractAddressNFT, tokenId);
			const sellTrx = await marketplaceContract.withWallet.createTokenAuction(
				contractAddressNFT,
				tokenId,
				startingPriceWei,
				buyNowPriceWei,
				startDate,
				endDate,
				isAuctionAllowed,
				isBuyNowAllowed
			);
			await sellTrx.wait();
			dispatch(getNFTs());
			dispatch(setNotification({ msg: 'NFT Listed!', alertType: 'success' }));
			dispatch(setLoading(false));
			return sellTrx;
		} catch (error) {
			dispatch(setLoading(false));
			const message = error.toString();
			dispatch(setNotification({ msg: message, alertType: 'error' }));
			return thunkAPI.rejectWithValue(message);
		}
	}
);

// Buy NFT

export const buyNowNFT = createAsyncThunk('NFTs/buyNowNFT', async ({ storefrontNFT }, { dispatch, getState }, thunkAPI) => {
	const state = getState();
	const { marketplaceContract } = state;
	try {
		dispatch(setLoading(true));
		const trx = await marketplaceContract.withWallet.buyNow(
			storefrontNFT.token.nft,
			storefrontNFT.token.tokenId,
			storefrontNFT.token.salt,
			{ value: storefrontNFT.details.buyNowPrice }
		);
		await trx.wait();
		dispatch(getNFTs());
		dispatch(setNotification({ msg: 'NFT Bought Now!', alertType: 'success' }));
		dispatch(setLoading(false));
		return trx;
	} catch (error) {
		dispatch(setLoading(false));
		const message = error.toString();
		dispatch(setNotification({ msg: message, alertType: 'error' }));
		return thunkAPI.rejectWithValue(message);
	}
});

// Bid on NFT

export const bidNFT = createAsyncThunk('NFTs/bidNFT', async ({ storefrontNFT, bidWei }, { dispatch, getState }, thunkAPI) => {
	const state = getState();
	const { marketplaceContract } = state;
	try {
		dispatch(setLoading(true));
		const trx = await marketplaceContract.withWallet.bid(storefrontNFT.token.nft, storefrontNFT.token.tokenId, storefrontNFT.token.salt, {
			value: bidWei,
		});
		await trx.wait();
		dispatch(getNFTs());
		dispatch(setNotification({ msg: 'NFT Bid On!', alertType: 'success' }));
		dispatch(setLoading(false));
		return trx;
	} catch (error) {
		dispatch(setLoading(false));
		const message = error.toString();
		dispatch(setNotification({ msg: message, alertType: 'error' }));
		return thunkAPI.rejectWithValue(message);
	}
});

// Relist NFT

export const relistNFT = createAsyncThunk(
	'NFTs/relistNFT',
	async (
		{ NFTData, startingPriceWei, buyNowPriceWei, startDate, endDate, isAuctionAllowed, isBuyNowAllowed },
		{ dispatch, getState },
		thunkAPI
	) => {
		const state = getState();
		const { marketplaceContract } = state;
		try {
			dispatch(setLoading(true));
			const trx = await marketplaceContract.withWallet.updateUnsoldToken(
				NFTData.token.nft,
				NFTData.token.tokenId,
				NFTData.token.salt,
				startingPriceWei,
				buyNowPriceWei,
				startDate,
				endDate,
				isAuctionAllowed,
				isBuyNowAllowed,
        {gasLimit: 100000}
			);
			await trx.wait();
			dispatch(getNFTs());
			dispatch(setNotification({ msg: 'NFT Listed!', alertType: 'success' }));
			dispatch(setLoading(false));
			return trx;
		} catch (error) {
			dispatch(setLoading(false));
			const message = error.toString();
			dispatch(setNotification({ msg: message, alertType: 'error' }));
			return thunkAPI.rejectWithValue(message);
		}
	}
);

// Redeem NFT

export const redeemNFT = createAsyncThunk('NFTs/redeemNFT', async ({ NFTData }, { dispatch, getState }, thunkAPI) => {
	const state = getState();
	const { marketplaceContract } = state;
	try {
		dispatch(setLoading(true));
		const trx = await marketplaceContract.withWallet.cancelSale(NFTData.token.nft, NFTData.token.tokenId, NFTData.token.salt);
		await trx.wait();
		dispatch(getNFTs());
		dispatch(setNotification({ msg: 'NFT Redeemed!', alertType: 'success' }));
		dispatch(setLoading(false));
		return trx;
	} catch (error) {
		dispatch(setLoading(false));
		const message = error.toString();
		dispatch(setNotification({ msg: message, alertType: 'error' }));
		return thunkAPI.rejectWithValue(message);
	}
});

// Execute Sale NFT

export const finalizeSaleNFT = createAsyncThunk('NFTs/finalizeSaleNFT', async ({ NFTData }, { dispatch, getState }, thunkAPI) => {
	const state = getState();
	const { marketplaceContract } = state;
	try {
		dispatch(setLoading(true));
		const trx = await marketplaceContract.withWallet.executeSale(NFTData.token.nft, NFTData.token.tokenId, NFTData.token.salt);
		await trx.wait();
		dispatch(getNFTs());
		dispatch(setNotification({ msg: 'NFT Sale Finalized!', alertType: 'success' }));
		dispatch(setLoading(false));
		return trx;
	} catch (error) {
		dispatch(setLoading(false));
		const message = error.toString();
		dispatch(setNotification({ msg: message, alertType: 'error' }));
		return thunkAPI.rejectWithValue(message);
	}
});

// Get All NFT Bids

export const getAllNFTBids = createAsyncThunk('NFTs/getAllNFTBids', async (_, { dispatch, getState }, thunkAPI) => {
	const state = getState();
	const { marketplaceContract } = state;
	const ethereumContract = marketplaceContract.withWallet;
	try {
		const provider = ETHProviderWithWallet();
		const filter = {
			address: ethereumContract.address,
			topics: [
				// the name of the event, parnetheses containing the data type of each event, no spaces
				ethers.utils.id('Bid(address,uint256,uint256,uint256,address)'),
			],
			fromBlock: 0,
			toBlock: 'latest',
		};

		const data = await provider.getLogs(filter);

		// You can also pull in your JSON ABI; I'm not sure of the structure inside artifacts
		let abi = ['event Bid(address nft, uint256 tokenId, uint256 salt, uint256 bidValue, address bidder)'];
		let iface = new ethers.utils.Interface(abi);

		let events = data.map(item => iface.parseLog(item));

		return events;
	} catch (error) {
		const message = error.toString();
		dispatch(setNotification({ msg: message, alertType: 'error' }));
		return thunkAPI.rejectWithValue(message);
	}
});

export const NFTSlice = createSlice({
	name: 'NTFs',
	initialState,
	reducers: {
		startLoading: (state, action) => {
			return {
				...state,
				alchemyLoading: action.payload,
			};
		},
		hasError: (state, action) => {
			return {
				...state,
				error: action.payload.error,
				alchemyLoading: action.payload.loading,
			};
		},
		setAlchemyNFTs: (state, action) => {
			return {
				...state,
				alchemyNFTs: action.payload.NFTs,
				alchemyLoading: action.payload.loading,
			};
		},
		clearUnfoundNFT: (state, action) => {
			return {
				...state,
				unfoundNFT: null,
			};
		},
		clearSellTrx: (state, action) => {
			return {
				...state,
				sellTrx: null,
			};
		},
	},
	extraReducers: {
		[getNFTs.pending]: (state, action) => {
			state.status = API_STATUS.PENDING;
		},
		[getNFTs.fulfilled]: (state, action) => {
			state.status = API_STATUS.FULFILLED;
			state.value = action.payload.tokens;
			state.feePercent = action.payload.feePercent;
		},
		[getNFTs.rejected]: (state, action) => {
			state.status = API_STATUS.REJECTED;
		},
		[getAllNFTBids.pending]: (state, action) => {
			state.eventsStatus = API_STATUS.PENDING;
		},
		[getAllNFTBids.fulfilled]: (state, action) => {
			state.eventsStatus = API_STATUS.FULFILLED;
			state.events = action.payload;
		},
		[getAllNFTBids.rejected]: (state, action) => {
			state.eventsStatus = API_STATUS.REJECTED;
		},
		[getRoyaltyFees.fulfilled]: (state, action) => {
			state.platformRoyaltyFees = action.payload;
		},
		[getUnfoundNFT.fulfilled]: (state, action) => {
			state.unfoundNFT = action.payload;
		},
		[sellNFT.fulfilled]: (state, action) => {
			state.sellTrx = action.payload;
		},
	},
});

export const { setAlchemyNFTs, startLoading, hasError, clearUnfoundNFT, clearSellTrx } = NFTSlice.actions;

export default NFTSlice.reducer;
