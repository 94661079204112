import { device } from 'device-dimensions';
import styled from 'styled-components';

import { RoundButton } from 'components/Button/styles';
import { Spacer } from 'components/Spacer/styles';

import * as AS from 'styles/AppStyles';

import SplashBG from 'images/SplashBG.webp';
import SplashBGMobile from 'images/SplashBGMobile.webp';

export const MainContainer = styled(AS.Column)`
	padding: 4% 8% 4% 8%;
	height: 100%;
	background-image: url(${SplashBG});
	background-size: 100% 100%;
	align-items: center;
	flex-direction: column;
	justify-content: space-between;
	@media ${device.mobileL} {
		background-image: url(${SplashBGMobile});
		background-size: 100%;
	}
`;

export const MainTitle = styled(AS.Text)`
	color: black;
	font-size: 54px;
	@media ${device.mobileL} {
		text-align: center;
		font-size: 32px;
	}
`;

export const MainContainerRow = styled(AS.FlexRow)`
	justify-content: center;
	@media ${device.mobileL} {
		display: none;
	}
`;

export const ListingsContainer = styled(AS.Column)`
	padding: 4% 8% 3% 8%;
	height: 100%;
	align-items: center;
	flex-direction: column;
	justify-content: space-between;
	background-color: white;
`;

export const BenefitFromContainer = styled(AS.Column)`
	border-top: 1px solid #d9d9d9;
	background-color: white;
	padding: 4% 7% 5% 7%;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: flex-start;
	background-color: white;
	@media ${device.mobileL} {
		display: flex;
		flex-direction: column;
	}
`;

export const SupportedPlatformsContainer = styled(AS.Column)`
	background: linear-gradient(92.31deg, #1cbf7b 3.36%, #d9d9d9 74.47%);
	padding: 4% 7% 4% 7%;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
`;

export const SupportedPlatformsImage = styled.img`
	width: 50%;
	@media ${device.mobileL} {
		width: 75%;
	}
`;

export const FutureRealEstateContainer = styled(AS.Column)`
	background: white;
	padding: 4% 7% 4% 7%;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
`;

export const FutureRealEstateColumn = styled(AS.Column)`
	align-items: center;
	@media ${device.mobileL} {
		text-align: center;
	}
`;

export const GettingStartedContainer = styled(AS.Column)`
	background: white;
	padding: 4% 7% 4% 7%;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	border-top: 1px solid #d9d9d9;
`;

export const GettingStartedHeader = styled(AS.Text)`
	font-size: 40px;
	user-select: none;
	@media ${device.mobileL} {
		margin-top: 20px;
		font-size: 28px;
	}
	@media ${device.mobileM} {
		margin-top: 20px;
		font-size: 25px;
	}
`;

export const GettingStartedCard = styled.div`
	border: 1px solid #dbdbdb;
	border-radius: 10px;
	transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	&:hover {
		cursor: pointer;
		border-color: #1cbf7b;
		box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
	}
`;

export const GettingStartedMainRow = styled(AS.FlexRow)`
	gap: 15px;
	@media ${device.mobileL} {
		flex-direction: column;
	}
`;

export const ExploreButton = styled(RoundButton)`
	background-color: white;
	border: 1px solid #1cbf7b;
	color: #1cbf7b;
	width: 155px;
`;

export const LogoMain = styled.img`
	width: 31%;
	margin: 35px 0;
	@media ${device.mobileL} {
		width: 70%;
		margin: 0 0 25px 0;
	}
`;

export const FeaturedListingHeader = styled(AS.FlexRow)`
	justify-content: center;
	@media ${device.mobileL} {
		flex-direction: column;
	}
`;

export const PlatformRow = styled(AS.FlexRow)`
	justify-content: center;
	gap: 15px;
	@media ${device.mobileL} {
		flex-direction: column;
	}
`;

export const PlatFormDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background: #f4fcf8;
	border: 1px solid #dbdbdb;
	border-radius: 10px;
	padding: 15px 20px;
	width: 80%;
	height: 84px;
	text-align: center;
	transition: all 0.3s ease-in-out;
	&:hover {
		cursor: pointer;
		background: #1cbf7b;
		border-color: #1cbf7b;
		box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
	}
	@media ${device.mobileL} {
		height: 68px;
	}
`;

export const PlatformImages = styled.img`
	width: 30%;
	@media ${device.mobileL} {
		width: 50%;
	}
`;

export const FeaturedListingColumn = styled.div`
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-gap: 25px;
	align-items: flex-start;
	@media ${device.mobileL} {
		grid-template-columns: auto;
	}
`;

export const FeaturedListingSpacer = styled(Spacer)`
	height: 40px;
	@media ${device.mobileL} {
		height: 20px;
	}
`;

export const FeaturedListingButtonRow = styled(AS.FlexRow)`
	justify-content: center;
	@media ${device.mobileL} {
		margin-bottom: 20px;
	}
`;

export const BenefitFromHeader = styled(AS.FlexRow)`
	flex-direction: column;
	align-items: flex-start;
	@media ${device.mobileL} {
		align-items: center;
		text-align: center;
	}
`;

export const BenefitFromButtonRow = styled(AS.FlexRow)`
	gap: 25px;
	@media ${device.mobileL} {
		flex-direction: column;
		margin-bottom: 40px;
	}
`;
