import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { Spacer } from 'components/Spacer/Spacer';

import * as AS from 'styles/AppStyles';

import TerrenaLogo from 'images/Marketplace.webp';

import * as S from './styles';

const Terms = () => {
	const topRef = useRef();
	const navigate = useNavigate();
	return (
		<S.MainContainer>
			<S.Card>
				<S.BackCardRow ref={topRef}>
					<S.BackCard onClick={() => navigate('/')}>
						<S.ArrowLeftIcon />
						<S.BackFont>Back To Home Page</S.BackFont>
					</S.BackCard>
					<S.Logo src={TerrenaLogo} alt="logo" />
				</S.BackCardRow>
				<S.SubHeader>
					<S.SubHeaderFont>terms of use</S.SubHeaderFont>
				</S.SubHeader>
				<S.TextCard>
					<S.ParagraphFont>
						These Terms of Service (“Terms”) set out the terms and conditions based on which you can access and use the Terrena platform,
						website located at{' '}
						<a style={{ color: '#1cbf7b' }} href="https://www.terrena.io" target="_blank" rel="noopener noreferrer">
							https://www.terrena.io
						</a>{' '}
						(the “Website”) and related services (collectively the “Services” or “Terrena”).
					</S.ParagraphFont>
					<S.ParagraphFont>
						The Services are owned and operated by Développement Terrena Inc., a company registered and existing under the laws of Quebec,
						Canada.
					</S.ParagraphFont>
					<S.ParagraphFont>
						PLEASE READ THESE TERMS CAREFULLY BEFORE USING THE SERVICES AS THEY ARE BINDING ON YOU. WE ARE ONLY WILLING TO MAKE THE SERVICES
						AVAILABLE TO YOU IF YOU ACCEPT ALL OF THE TERMS SET FORTH HEREIN. BY USING THE SERVICES, OR ANY PART OF IT YOU ARE CONFIRMING
						THAT YOU UNDERSTAND AND AGREE TO BE BOUND BY ALL OF THESE TERMS. IF YOU ARE ACCEPTING THESE TERMS ON BEHALF OF A COMPANY OR
						OTHER LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE LEGAL AUTHORITY TO ACCEPT THESE TERMS ON THAT ENTITY’S BEHALF, IN WHICH CASE
						“YOU” WILL MEAN THAT ENTITY. IF YOU DO NOT AGREE TO THESE TERMS OR IF YOU DO NOT HAVE AUTHORITY TO ENTER INTO THESE TERMS ON
						BEHALF OF A COMPANY, YOU MAY NOT ACCESS OR USE THE SERVICES.
					</S.ParagraphFont>
					<Spacer height={25} />
					<S.HeadingFont>1. Access to Terrena</S.HeadingFont>
					<Spacer height={25} />
					<S.ParagraphFont>
						Terrena is a decentralized platform for users to buy and sell Non-Fungible Tokens (NFT) as well as to take part in NFT auctions.
						Your access and use of Terrena is conditioned on the terms and conditions set out in these Terms.
					</S.ParagraphFont>
					<S.ParagraphFont>
						Access to Terrena is provided free of charge. It is your responsibility to make any and all arrangements necessary in order to
						access Terrena. The access is provided on an “as is” and “as available” basis. We may alter, suspend or discontinue any and all
						of Terrena at any time and without notice. We will not be liable to you in any way if Terrena is unavailable at any time and for
						any period.
					</S.ParagraphFont>
					<S.ParagraphFont>
						In order to access Terrena, you must connect via your digital wallet. We currently support MetaMask and Coinbase digital
						wallets. Your use of the said digital wallets are governed by the respective terms and conditions, as well as privacy policies
						of such digital wallet providers. You must also hold a relevant amount of Ether (ETH) in your digital wallet to carry out
						transactions on Terrena.
					</S.ParagraphFont>
					<S.ParagraphFont>
						You must be of legal age in your jurisdiction to access and use the Services. If you are not of legal age to enter into these
						Terms, please do not access and use the Services.
					</S.ParagraphFont>
					<Spacer height={25} />
					<S.HeadingFont>2. License</S.HeadingFont>
					<Spacer height={25} />
					<S.ParagraphFont>
						Terrena grants you a personal, limited, non-exclusive, non-commercial, and non-transferable license to access and use the
						Services only as expressly permitted under these Terms. Any violation by you of the Terms may give rise to immediate termination
						of your right to use the Services, as well as potential liability for copyright and other Intellectual Property Rights
						infringement depending on the circumstances. Any use of the Services other than as specifically authorized in the Terms, without
						our prior written permission is strictly prohibited and will terminate the license granted herein.
					</S.ParagraphFont>
					<Spacer height={25} />
					<S.HeadingFont>3. Your Obligations </S.HeadingFont>
					<Spacer height={25} />
					<S.ParagraphFont>
						You acknowledge and expressly agree that you are responsible for your own conduct while using Terrena, and for any consequences
						thereof. You agree to use Terrena only for purposes that are legal, proper and in accordance with these Terms and any applicable
						laws or regulations.
					</S.ParagraphFont>
					<S.ParagraphFont>
						You acknowledge and expressly agree that you, you shall not, and shall not allow any third party to:
					</S.ParagraphFont>
					<S.ParagraphFont>
						(i) send, upload, distribute or disseminate any unlawful, defamatory, harassing, abusive, and fraudulent content;
					</S.ParagraphFont>
					<S.ParagraphFont>
						(ii) distribute software viruses, worms, Trojan horses, corrupted files, or any other malware or any other items of a
						destructive or deceptive nature;
					</S.ParagraphFont>
					<S.ParagraphFont>
						(iii) upload, post, transmit or otherwise make available through Terrena any content that infringes the intellectual proprietary
						rights of others;
					</S.ParagraphFont>
					<S.ParagraphFont>(iv) use Terrena to violate the legal rights of others;</S.ParagraphFont>
					<S.ParagraphFont>
						(v) engage in, promote, or encourage illegal activity, including, without limiting to, money laundering;
					</S.ParagraphFont>
					<S.ParagraphFont>(vi) interfere with other users' enjoyment of Terrena;</S.ParagraphFont>
					<S.ParagraphFont>(vii) exploit Terrena for any unauthorized commercial purpose;</S.ParagraphFont>
					<S.ParagraphFont>
						(viii) copy, alter, translate, modify, create derivative works from, reproduce, resell, reverse assemble, reverse engineer,
						reverse compile any portion of Terrena; =
					</S.ParagraphFont>
					<S.ParagraphFont>
						(ix) remove any copyright, trademark or other proprietary rights notices contained in or on Terrena;
					</S.ParagraphFont>
					<S.ParagraphFont>(x) reformat or “frame” or “mirror” any portion of the Terrena;</S.ParagraphFont>
					<S.ParagraphFont>
						(xi) use any robot, spider, site search/retrieval application, or other device to retrieve or index any portion of Terrena or
						the content posted on the thereof, or to collect information about its users for any unauthorized purpose; and
					</S.ParagraphFont>
					<S.ParagraphFont>
						(xii) access or use Terrena for the purpose of creating a product or service that is competitive with any of our products or
						services.
					</S.ParagraphFont>
					<S.ParagraphFont>
						If you engage in any of the activities prohibited by this section, we may, at our sole and absolute discretion, without notice
						to you, and without limiting any of our other rights or remedies at law or in equity, immediately suspend your use of Terrena.
					</S.ParagraphFont>
					<Spacer height={25} />
					<S.HeadingFont>4. Disclaimer of Warranties</S.HeadingFont>
					<Spacer height={25} />
					<S.ParagraphFont>
						You acknowledge that you are not required to use the Services and you participate in the Services at your sole discretion. As a
						result, you are solely responsible for visiting the Website and using Services.
					</S.ParagraphFont>
					<S.ParagraphFont>
						Terrena is provided on an "as is" and “as available” basis, without warranty of any kind, either expressed or implied,
						including, without limitation, warranties that it is free of defects, merchantable, fit for a particular purpose or
						non-infringing. The entire risk as to the quality and performance of the Services is with you.
					</S.ParagraphFont>
					<S.ParagraphFont>
						You also acknowledge that your computer or mobile device and the Internet may affect the performance and operation of the
						Services. We are not responsible for any malfunction or problem caused by your equipment, internet connection, or a third-party
						provider.
					</S.ParagraphFont>
					<S.ParagraphFont>
						We reserve the right, in our sole discretion, to temporarily disable our Website or the Services and/or certain parts thereof
						for any reasons that we deem relevant. If Terrena goes disabled, either in part or in whole, you shall not hold us accountable
						for your loss of profits or revenue, loss of reputation, or business interruptions.
					</S.ParagraphFont>
					<Spacer height={25} />
					<S.HeadingFont>5. Risk Disclosure</S.HeadingFont>
					<Spacer height={25} />
					<S.ParagraphFont>
						<span style={{ fontWeight: 'bold' }}>No Fiduciary Duty.</span> These Terms do not create, or impose a fiduciary relationship
						between us and you. Your Account is not a checking, savings or any other type of account, and is therefore not covered by any
						insurance against losses.
					</S.ParagraphFont>
					<S.ParagraphFont>
						<span style={{ fontWeight: 'bold' }}>Volatility of Digital Assets.</span> The volatility and unpredictability of the price of
						digital assets, including NFTs may result in significant loss over a short period of time.
					</S.ParagraphFont>
					<S.ParagraphFont>
						<span style={{ fontWeight: 'bold' }}>Regulatory Risks.</span> Legislative and regulatory changes may also negatively affect the
						use, transfer, exchange, and value of digital assets, including the NFTs.
					</S.ParagraphFont>
					<S.ParagraphFont>
						<span style={{ fontWeight: 'bold' }}>Irreversible Transactions.</span> Transactions in digital assets, and thus in NFTs may be
						irreversible, and, accordingly, losses due to fraudulent or accidental transactions may not be recoverable.
					</S.ParagraphFont>
					<S.ParagraphFont>
						<span style={{ fontWeight: 'bold' }}>Technology Risk.</span> We do not own or control any of the underlying software through
						which blockchain networks are established and digital assets are created and transacted. In general, the underlying software for
						blockchain networks tends to be open source such that anyone can use, copy, modify, and distribute it. Terrena is built on top
						of the Ethereum blockchain which we have no control of. By using the Services, you understand and acknowledge that we are not
						responsible for the operation of the underlying software and networks that support digital assets and that we make no guarantee
						of functionality, security, or availability of such software and networks.
					</S.ParagraphFont>
					<S.ParagraphFont>
						<span style={{ fontWeight: 'bold' }}>Forks.</span> The underlying network protocols may be subject to sudden changes in
						operating rules, and third parties may from time to time create a copy of a blockchain network and implement changes in
						operating rules or other features (“Forks”) that may result in more than one version of a network and more than one version of a
						digital asset. These events are wholly outside of our control, and our ability to deliver the Services may be affected due to
						them.
					</S.ParagraphFont>
					<S.ParagraphFont>
						The risks mentioned herein are only some of the risks involved in using the Services and trading in digital assets and do not
						constitute an exhaustive list of such risks. You are recommended to carefully consider whether holding or investing through
						digital assets in general and/or using the Services suits you in light of your financial condition and awareness of the risks.
					</S.ParagraphFont>
					<Spacer height={25} />
					<S.HeadingFont>6. No Professional Advice </S.HeadingFont>
					<Spacer height={25} />
					<S.ParagraphFont>
						Terrena is not a financial institution, a financial service provider. Terrena has not received any license or authorization from
						any regulatory authority. We do not, and shall not at any time, give any financial advice whatsoever, including with regards to
						the purchase of digital assets.
					</S.ParagraphFont>
					<S.ParagraphFont>
						All information displayed on Terrena is provided for informational purposes only and is not intended to provide and shall not
						constitute professional advice of any nature, including investment advice, financial advice, or trading advice. Terrena and its
						third-party providers are not responsible for any errors, incompleteness, inaccuracies, delays or actions taken depending on the
						information contained therein. Before making any decisions based on any information or other content on Terrena, you alone are
						responsible for evaluating the merits and risks associated with utilizing such information.
					</S.ParagraphFont>
					<Spacer height={25} />
					<S.HeadingFont>7. Intellectual Property Rights</S.HeadingFont>
					<Spacer height={25} />
					<S.ParagraphFont>
						You expressly acknowledge and agree that any and all intellectual property rights ("Intellectual Property Rights") associated
						with Terrena and its Content (including, but without limitation to all art, design, text, graphics, user interfaces, “look and
						feel”, photos, audio, video, complication of the content, code and data, computer code and all other forms of information or
						data) are the sole property of Développement Terrena Inc., and its affiliates or are licensed for our use, unless otherwise is
						expressly set forth in these Terms. The Content is protected by Intellectual Property Rights and other laws available in Canada
						and other countries. Elements of Terrena are also protected by trade name, trade secret, unfair competition, and other laws and
						may not be copied or imitated in whole or in part. All customized graphics, icons, and other items that appear on Terrena are
						trademarks, service marks or trade name ("Marks") of Développement Terrena Inc., its affiliates or other entities that have
						granted us the right and license to use such Marks and may not be used or interfered with in any manner without our express
						written consent.
					</S.ParagraphFont>
					<S.ParagraphFont>
						Except as otherwise expressly authorized by these Terms, you are not allowed to reproduce, copy, distribute, sell, rent,
						sub-licence, store, or in any other manner reuse the Content in any way without our prior written permission or that of an
						appropriate third party. Except as expressly provided herein, we do not grant to you any express or implied rights to our
						Intellectual Property Rights or that of any third party.
					</S.ParagraphFont>
					<Spacer height={25} />
					<S.HeadingFont>8. Feedback</S.HeadingFont>
					<Spacer height={25} />
					<S.ParagraphFont>
						You may choose to submit comments, bug reports, ideas or other feedback about Terrena, including without limitation about how to
						improve Terrena (collectively, “Feedback”). By sending any Feedback, you agree that we are free to use and distribute such
						Feedback to third parties at our discretion and without any compensation to you, whether on a non-confidential basis or
						otherwise. You hereby grant us a perpetual, irrevocable, non-exclusive, worldwide license under all rights necessary for us to
						incorporate and use your Feedback for any purpose.
					</S.ParagraphFont>
					<Spacer height={25} />
					<S.HeadingFont>9. Links to Third-Party Websites</S.HeadingFont>
					<Spacer height={25} />
					<S.ParagraphFont>
						We may include links to other websites (Third-Party Sites) on Terrena. Unless expressly stated, these Third-Party Sites are not
						owned or controlled by us. We neither assume nor accept responsibility or liability for the content of these Third-Party Sites.
						The inclusion of a link to another website on Terrena is for information only and does not imply any endorsement of the websites
						themselves or of those in control of them.
					</S.ParagraphFont>
					<S.HeadingFont>10. Limitation of Liability</S.HeadingFont>
					<S.ParagraphFont style={{ marginBottom: '25px' }}>
						TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL WE OR ANY OF OUR FOUNDERS, OFFICERS, DIRECTORS,
						REPRESENTATIVES, AGENTS, EMPLOYEES, CONSULTANTS, LAWYERS, AND OTHER PERSONNEL AUTHORIZED TO ACT ON OUR BEHALF BE LIABLE TO YOU
						UNDER CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE, OR ANY OTHER LEGAL OR EQUITABLE THEORY, FOR: (A) ANY LOST REVENUE, DATA
						LOSS, LOSS OF ANTICIPATED PROFITS, OR DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, COMPENSATORY, OR CONSEQUENTIAL DAMAGES OF
						ANY KIND WHATSOEVER RESULTING FROM: (I) YOUR USE OF, OR CONDUCT IN CONNECTION WITH TERRENA; (II) ANY INTERRUPTION OR CESSATION
						OF TRANSMISSION TO OR FROM TERRENA; OR (III) ANY BUGS, VIRUSES, TROJAN HORSES, OR ANY OTHER HARMFUL AND DISRUPTIVE CODE THAT ARE
						FOUND IN TERRENA OR THAT MAY BE TRANSMITTED TO OR THROUGH TERRENA BY ANY THIRD PARTY, REGARDLESS OF THE SOURCE OF ORIGINATION,
						OR (B) ANY DIRECT DAMAGES IN EXCESS OF (IN THE AGGREGATE) OF THE GREATER OF EUR 100.00.
					</S.ParagraphFont>
					<S.ParagraphFont style={{ marginBottom: '25px' }}>
						THESE LIMITATIONS APPLY REGARDLESS OF LEGAL THEORY, WHETHER BASED ON TORT, STRICT LIABILITY, BREACH OF CONTRACT, BREACH OF
						WARRANTY, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT WE WERE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
					</S.ParagraphFont>
					<Spacer height={25} />
					<S.HeadingFont>11. Indemnification</S.HeadingFont>
					<Spacer height={25} />
					<S.ParagraphFont>
						You agree to indemnify and hold us and any of our founders, officers, employees, and agents harmless, including costs and
						attorneys’ fees, from any claim or demand made by any third party due to or arising out of (i) your use of Terrena, (ii) your
						violation of these Terms, or (iii) your violation of applicable laws or regulations.
					</S.ParagraphFont>
					<S.ParagraphFont>
						We retain the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to
						indemnify us, and you agree to cooperate with our defense of these claims. You agree not to settle any matter without our prior
						written consent. We will use reasonable efforts to notify you of any such claim, action or proceeding upon becoming aware of it.
					</S.ParagraphFont>
					<Spacer height={25} />
					<S.HeadingFont>12. Data Protection</S.HeadingFont>
					<Spacer height={25} />
					<S.ParagraphFont>
						Your access and use of Terrena is also conditioned on your acceptance of our Privacy Policy. Our Privacy Policy sets out data
						protection practices and principles we employ in relation to Terrena. Please read our Privacy Policy carefully before using
						Terrena.
					</S.ParagraphFont>
					<Spacer height={25} />
					<S.HeadingFont>13. Law and Jurisdiction</S.HeadingFont>
					<Spacer height={25} />
					<S.ParagraphFont>
						These Terms, and the relationship between you and us, whether contractual or otherwise, shall be governed by, and construed in
						accordance with the laws of Quebec, Canada without regard to its conflict of law provisions.
					</S.ParagraphFont>
					<S.ParagraphFont>
						In the event of any controversy, dispute or claim (collectively “dispute”) between you and us arising out of or in connection
						with your use of Terrena, the parties shall attempt, in good faith, to resolve any such dispute among themselves in an amicable
						fashion. If the parties are not able to resolve any such dispute within fifteen (15) days, then either party may submit such
						dispute to mediation. If the dispute cannot be resolved through mediation within a month of the submission to mediation, then
						the parties shall be free to initiate legal proceedings and pursue any right or remedy available to them in which case the
						competent courts of Quebec will have the jurisdiction to hear the cases.
					</S.ParagraphFont>
					<Spacer height={25} />
					<S.HeadingFont>14. Severance</S.HeadingFont>
					<Spacer height={25} />
					<S.ParagraphFont>
						The provisions of these Terms are independent and severable from each other. If at any time any provision of the Terms is or
						becomes illegal or unenforceable in any respect under the law of any jurisdiction, this does not affect or impair: a) the
						legality, validity, or enforceability in that jurisdiction of any other provision of these Terms; nor b) the legality, validity,
						or enforceability under the law of any other jurisdiction of that or any other provision of the Terms.
					</S.ParagraphFont>
					<Spacer height={25} />

					<S.HeadingFont>15. Changes to the Terms</S.HeadingFont>
					<Spacer height={25} />
					<S.ParagraphFont>
						We may make changes to these Terms at any time at our sole discretion. Such changes may be done with or without notice. Any such
						changes will become binding on you upon your first use of Terrena after the changes have been implemented. You are therefore
						advised to check this page from time to time.
					</S.ParagraphFont>
					<S.ParagraphFont>
						In the event of any conflict between the current version of these Terms and any previous versions, the provisions current and in
						effect shall prevail unless it is expressly stated otherwise.
					</S.ParagraphFont>
					<Spacer height={25} />
					<S.HeadingFont> 16. Contacts</S.HeadingFont>
					<Spacer height={25} />
					<S.ParagraphFont>
						If you have any questions we would be happy to answer them. Do not hesitate to contact us for any inquiries you might have
						through the following contract details:{' '}
						<a
							style={{ color: '#1cbf7b' }}
							target="_blank"
							rel="noopener noreferrer"
							href="mailto:support@terrena.io?subject=Questions Regarding Terrena's Terms of Use"
						>
							Email: Support@terrena.io
						</a>
					</S.ParagraphFont>
					<AS.FlexRow style={{ justifyContent: 'flex-end' }}>
						<S.BackCard
							onClick={() => {
								topRef?.current.scrollIntoView({
									behavior: 'smooth',
									block: 'start',
									inline: 'nearest',
								});
							}}
						>
							<S.ArrowUpIcon />
							<S.BackFont>Back To The Top</S.BackFont>
						</S.BackCard>
					</AS.FlexRow>
				</S.TextCard>
			</S.Card>
		</S.MainContainer>
	);
};

export default Terms;
