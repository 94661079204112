import { Discord } from '@styled-icons/boxicons-logos/Discord';
import { LinkedinSquare } from '@styled-icons/boxicons-logos/LinkedinSquare';
import { Twitter } from '@styled-icons/boxicons-logos/Twitter';
import styled, { css } from 'styled-components';

export const StyledDiscord = styled(Discord)`
	margin: 0 15px;
	color: white;
	background-color: #1cbf7b;
	z-index: 2;
	transition: all 0.3s ease-in-out;
	height: 54px;
	width: 54px;
	padding: 10px;
	border-radius: 50%;
	${props =>
		props['data-white-bg']
			? css`
					background-color: white;
					color: #1cbf7b;
			  `
			: css`
					color: white;
					background-color: #1cbf7b;
			  `}
	&:hover {
		cursor: pointer;
		transform: scale(1.1);
	}
`;

export const StyledLinkedIn = styled(LinkedinSquare)`
	margin: 0 15px;
	z-index: 2;
	transition: all 0.3s ease-in-out;
	height: 54px;
	width: 54px;
	padding: 10px;
	border-radius: 50%;
	${props =>
		props['data-white-bg']
			? css`
					background-color: white;
					color: #1cbf7b;
			  `
			: css`
					color: white;
					background-color: #1cbf7b;
			  `}
	&:hover {
		cursor: pointer;
		transform: scale(1.1);
	}
`;

export const StyledTwitter = styled(Twitter)`
	margin: 0 15px;
	z-index: 2;
	transition: all 0.3s ease-in-out;
	height: 54px;
	width: 54px;
	padding: 10px;
	border-radius: 50%;
	${props =>
		props['data-white-bg']
			? css`
					background-color: white;
					color: #1cbf7b;
			  `
			: css`
					color: white;
					background-color: #1cbf7b;
			  `}
	&:hover {
		cursor: pointer;
		transform: scale(1.1);
	}
`;
