import MainContract from 'contractABIs/marketplaceMainnet.json';
import TestContract from 'contractABIs/marketplaceTestnet.json';
import { ethers } from 'ethers';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Error } from 'pages/Error';

import { API_STATUS } from 'components/Common/Constants';
import { StyledLoader } from 'components/Loader/StyledLoader';

import { getAllNFTBids, getNFTs, getRoyaltyFees, hasError, setAlchemyNFTs, startLoading } from 'state/features/NFTSlice';
import { setMarketplaceContractNoWallet, setMarketplaceContractWithWallet } from 'state/features/marketplaceContractSlice';
import { setNotification } from 'state/features/notificationSlice';
import { store } from 'state/store';

import { ETHProvider, ETHProviderWithWallet } from 'utils/ETHProvider';
import { testMode } from 'utils/testMode';
import { validatedOwnedNFTs } from 'utils/validatedOwnedNFTs';

export const NFTStateWrapper = ({ children }) => {
	const dispatch = useDispatch();
	const walletPubKey = useSelector(state => state.wallet.address);

	const Contract = testMode ? TestContract : MainContract;

	// Set Ethereum Contract in store

	useEffect(() => {
		if (walletPubKey) {
			const provider = ETHProviderWithWallet();
			const marketplaceContract = new ethers.Contract(Contract.address, Contract.abi, provider);
			const signer = provider.getSigner();
			const marketplaceContractWithSigner = marketplaceContract.connect(signer);
			dispatch(setMarketplaceContractWithWallet(marketplaceContractWithSigner));
		} else {
			const provider = ETHProvider();
			const marketplaceContract = new ethers.Contract(Contract.address, Contract.abi, provider);
			dispatch(setMarketplaceContractNoWallet(marketplaceContract));
		}
	}, [walletPubKey]);

	// Get NFTs from api call

	useEffect(() => {
		dispatch(getNFTs());
		dispatch(getRoyaltyFees());
	}, [dispatch]);

	const getNFTsStatus = useSelector(state => state.NFTs.status);

	// Fetch From Alchemy When User Connects Their Wallet

	const getUserTokensFromAlchemy = async () => {
		dispatch(startLoading(true));
		const userTokens = await validatedOwnedNFTs(walletPubKey);
		try {
			dispatch(setAlchemyNFTs({ NFTs: userTokens, loading: false }));
		} catch (error) {
			dispatch(hasError({ error: error, loading: false }));
			dispatch(setNotification({ msg: error.msg, alertType: 'error' }));
		}
	};

	useEffect(() => {
		if (walletPubKey) {
			getUserTokensFromAlchemy();
		}
	}, [walletPubKey]);

	// Get all NFT bids

	useEffect(() => {
		if (walletPubKey) {
			dispatch(getAllNFTBids());
		}
	}, [walletPubKey, dispatch]);

	return getNFTsStatus === API_STATUS.FULFILLED ? (
		children
	) : getNFTsStatus === API_STATUS.PENDING ? (
		<StyledLoader />
	) : getNFTsStatus === API_STATUS.REJECTED ? (
		<Error />
	) : null;
};
