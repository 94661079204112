import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	active: false,
	address: '',
	balance: null,
	walletName: '',
};

export const walletSlice = createSlice({
	name: 'wallet',
	initialState,
	reducers: {
		setWallet: (state, action) => {
			return {
				...state,
				active: action.payload.active,
				address: action.payload.address,
				balance: action.payload.balance,
				walletName: action.payload.walletName,
			};
		},
	},
});

export const { setWallet } = walletSlice.actions;

export default walletSlice.reducer;
