import { useState } from 'react';
import { useDispatch } from 'react-redux';
import isEmail from 'validator/es/lib/isEmail';

import { Button } from 'components/Button/Button';
import IconsRow from 'components/Icons/IconsRow';
import ButtonLoader from 'components/Loader/ButtonLoader';
import { Spacer } from 'components/Spacer/Spacer';

import { setNotification } from 'state/features/notificationSlice';

import * as AS from 'styles/AppStyles';

import Logo from 'images/logo-icon.webp';

import * as S from './styles';

const Welcome = () => {
	const dispatch = useDispatch();

	const [inputChange, setInputChange] = useState('');
	const [disableButton, setDisableButton] = useState(false);

	const sendEmailInvitation = () => {
		if (!inputChange || isEmail(inputChange) === false) {
			dispatch(
				setNotification({
					msg: 'Please enter a valid email address!',
					alertType: 'error',
				})
			);
		} else {
			window._scq.push([
				'identify',
				{
					email: inputChange,
					tags: ['SignUp'],
				},
			]);
			dispatch(
				setNotification({
					msg: 'Your email has been added to the mailing list!',
					alertType: 'success',
				})
			);
			setDisableButton(true);
			setTimeout(() => {
				setDisableButton(false);
			}, 10000);
		}
	};

	return (
		<>
			<S.Container flex>
				<div>
					<AS.FlexRow isCentered>
						<S.Header>coming soon</S.Header>
					</AS.FlexRow>
					<Spacer height={5} />
					<AS.FlexRow isCentered>
						<S.Subheader>nft marketplace for land in the metaverse</S.Subheader>
					</AS.FlexRow>
				</div>
				<S.LogoImage src={Logo} alt="logo" />
				<AS.Column alignItems="center" justifyContent="center">
					<AS.FlexRow isCentered>
						<S.NewsletterText>subscribe to our newsletter to stay updated</S.NewsletterText>
					</AS.FlexRow>
					<Spacer height={20} />
					<S.EmailInputRow isCentered>
						<S.EmailInput isCentered value={inputChange} onChange={e => setInputChange(e.target.value)} placeholder="Enter your email" />
						<ButtonLoader apiCall={async () => await sendEmailInvitation()}>
							<Button disabled={!inputChange || disableButton} width="200px" text="sign up" />
						</ButtonLoader>
					</S.EmailInputRow>
					<Spacer height={15} />
					<AS.FlexRow isCentered>
						<IconsRow />
					</AS.FlexRow>
				</AS.Column>
			</S.Container>
		</>
	);
};

export default Welcome;
