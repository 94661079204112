import { device } from 'device-dimensions';
import styled from 'styled-components/macro';

import { Input } from 'components/Input/styles';

import * as AS from 'styles/AppStyles';

import SplashBG from 'images/SplashBG.webp';
import SplashBGMobile from 'images/SplashBGMobile.webp';

export const Container = styled(AS.Column)`
	overflow-x: auto;
	height: 100%;
	min-height: 100vh;
	position: relative;
	padding: 3% 0 1% 0;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	align-items: center;
	background-image: url(${SplashBG});
	background-size: 100% 100%;
	@media ${device.mobileL} {
		padding: 30% 0 15% 0;
		background-image: url(${SplashBGMobile});
	}
	@media ${device.mobileM} {
		padding: 15% 0 10% 0;
	}
`;

export const Header = styled(AS.Text)`
	font-size: 54px;
	@media ${device.mobileL} {
		color: black;
		font-size: 40px;
	}
`;

export const Subheader = styled(AS.Text)`
	color: black;
	font-size: 18px;
	@media ${device.mobileL} {
		display: none;
	}
`;

export const NewsletterText = styled(AS.Text)`
	color: black;
	font-size: 18px;
	@media ${device.mobileL} {
		text-align: center;
		padding: 0 5px;
	}
`;

export const EmailInputRow = styled(AS.Row)`
	width: auto;
	justify-content: center;
	@media ${device.mobileL} {
		flex-direction: column;
		align-items: center;
	}
`;

export const EmailInput = styled(Input)`
	margin-right: 15px;
	@media ${device.mobileL} {
		width: 320px;
		margin-bottom: 15px;
	}
`;

export const LogoImage = styled.img`
	width: 30%;
	margin: 35px 0;
	@media ${device.mobileL} {
		width: 70%;
	}
`;
