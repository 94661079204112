import { Xmark } from '@styled-icons/fa-solid/Xmark';
import { PlusSquare } from '@styled-icons/feather/PlusSquare';
import { device } from 'device-dimensions';
import styled from 'styled-components/macro';

import { Card } from 'components/Card/Card';
import { StatusButton } from 'components/Common/Filters/styles';
import { Input } from 'components/Input/styles';
import { Spacer } from 'components/Spacer/styles';

import * as AS from 'styles/AppStyles';

export const Container = styled(AS.Column)`
	overflow-x: auto;
	height: 100%;
	min-height: 100vh;
	position: relative;
	padding: 5% 8%;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const MainRow = styled(AS.FlexRow)`
	display: grid;
	grid-template-columns: 0.5fr 0.5fr;
	grid-gap: 15px;
	align-items: flex-start;
	@media ${device.mobileL} {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}
`;

export const PreviewColumn = styled(AS.Column)`
	padding: 0 45% 0 0;
	@media ${device.mobileL} {
		width: fit-content;
		padding: 15px;
	}
`;

export const PreviewText = styled(AS.Text)`
	font-size: 28px;
	color: black;
	user-select: none;
	@media ${device.mobileL} {
		display: none;
	}
`;

export const ListItemText = styled(AS.Text)`
	font-size: 40px;
	@media ${device.mobileL} {
		font-size: 28px;
		text-align: center;
	}
`;

export const MainSpacer = styled(Spacer)`
	height: 50px;
	@media ${device.mobileL} {
		height: 25px;
	}
`;

export const EthereumIcon = styled.img`
	width: 25px;
`;

export const DescriptionTextArea = styled.div`
	max-width: 95%;
	resize: none;
	width: 100%;
	padding: 15px;
	border: 1px solid #dbdbdb;
	border-radius: 10px;
	transition: all 0.3s ease-in-out;
	font-size: 16px;
	font-family: 'Helvetica Neue';
	height: auto;
	max-height: 500px;
	cursor: auto;
`;

export const DurationRow = styled(AS.FlexRow)`
	gap: 15px;
	flex-wrap: wrap;
	@media ${device.mobileL} {
		/* flex-direction: column; */
	}
`;

export const DurationButton = styled(StatusButton)`
	font-family: Bord;
`;

export const CalendarCard = styled(Card)`
	background-color: white;
	min-height: 150px;
	height: 575px;
	padding: 25px;
	&:hover {
		cursor: auto;
		box-shadow: none;
	}
	@media ${device.mobileL} {
		height: 100%;
	}
`;

export const TimesIcon = styled(Xmark)`
	position: absolute;
	top: -5px;
	width: 10px;
	right: 0;
	transition: all 0.3s ease-in-out;
	&:hover {
		cursor: pointer;
		transform: scale(1.1);
	}
`;

export const CalendarRow = styled.div`
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	grid-gap: 10px;
	max-width: 100%;
	margin-bottom: 50px;
	@media ${device.mobileL} {
		grid-template-columns: none;
		justify-content: center;
		grid-gap: 25px;
	}
`;

export const DateButton = styled(StatusButton)`
	width: 90%;
	@media ${device.mobileL} {
		width: 250px;
	}
`;

export const CalendarSpacer = styled(Spacer)`
	display: none;
	@media ${device.mobileL} {
		height: 20px;
	}
`;

export const BuyNowInput = styled(Input)`
	border: 1px solid #dbdbdb;
	width: 50%;
	height: 48px;
	@media ${device.mobileL} {
		width: 100%;
	}
`;

export const StartingPriceInput = styled(Input)`
	border: 1px solid #dbdbdb;
	width: 50%;
	height: 48px;
	@media ${device.mobileL} {
		width: 100%;
	}
`;

export const SelectFont = styled(AS.Text)`
	color: black;
	font-family: Helvetica Neue;
	font-size: 18px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
	transition: all 0.3s ease-in-out;
`;

export const TimeInputRow = styled(AS.FlexRow)`
	justify-content: space-evenly;
	width: 100%;
`;

export const TimeInput = styled(Input)`
	width: 85%;
`;

// Styled Loader Overlay

export const Overlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #f6f6f6;
	filter: blur(10px);
	z-index: 5;
	opacity: 0.45;
`;

export const PlusIconDiv = styled.div`
	&.Tooltip {
		position: relative;
		&:before {
			transition: all 0.2s ease-in-out;
			content: attr(data-text);
			opacity: 0;
			position: absolute;
			top: -40px;
			left: 50%;
			transform: translateX(-50%);
			width: auto;
			padding: 10px;
			border-radius: 10px;
			background: #ffffff;
			color: #000;
			text-align: center;
			box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
			font-size: 12px;
			z-index: 5;
			white-space: nowrap;
			overflow: hidden;
		}
		&:after {
			content: '';
			transition: all 0.2s ease-in-out;
			position: absolute;
			margin-top: -8px;
			top: 0;
			left: 15px;
			border: 10px solid #f3f1f1;
			border-color: #ffffff transparent transparent transparent;
			z-index: 5;
			opacity: 0;
		}

		&:hover:before {
			opacity: 1;
		}
		&:hover:before,
		:hover:after {
			opacity: 1;
		}
	}
`;

export const PlusIcon = styled(PlusSquare)`
	color: #1cbf7b;
	width: 50px;
	transition: all 0.3s ease-in-out;
	&:hover {
		cursor: pointer;
		color: #15925e;
	}
`;

export const PlatformRow = styled(AS.FlexRow)`
	justify-content: center;
	gap: 15px;
	flex-direction: column;
`;

export const PlatFormDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ffffff;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
	border: 1px solid #dbdbdb;
	border-radius: 10px;
	padding: 15px 20px;
	width: 80%;
	height: 84px;
	text-align: center;
	transition: all 0.3s ease-in-out;
	&:hover {
		cursor: pointer;
		background: #1cbf7b;
		border-color: #1cbf7b;
		box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
	}
	@media ${device.mobileL} {
		height: 68px;
	}
`;

export const PlatformImages = styled.img`
	width: 30%;
	@media ${device.mobileL} {
		width: 50%;
	}
`;
