import { device } from 'device-dimensions';
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    &::selection{
      background: #1cbf7bd0;
      color:white;
    }
  }
  body {
    font-family: 'Roboto', sans-serif;
    font-size: 1.15em;
    margin: 0;
    padding: 0;
    min-height: 100vh;
  }

  html {
  height: 100%;
}

  p {
    opacity: 1;
    line-height: 1.5;
  }

  img {
    max-width: 100%;
}
  a {
    text-decoration: none;
    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
      text-decoration: none;
    }
  }

  /* React Calendar inside the List Item page */

  .react-calendar {
    max-width:90%;
    @media ${device.mobileL} {
    max-width:80%;
  }
    &__tile--now {
      background: #bff1dd;
    }
    &__tile--active {
      background: #1cbf7b;
      color:white !important;
      &:enabled:hover {
        background: #1cbf7b;
        color:white !important;
      }
    }
    &__month-view__days__day--weekend {
      color:black;
      &:enabled:focus:hover {
        color:white;
      }
    }
  }

  /* Styled Link */

  .Styled-Link {
    font-family: "Bord";
    font-size: 18px;
    transition: all 0.3s ease-in-out;
    &:hover {
      color: #1cbf7b !important;
    }
  }
`;

export default GlobalStyles;
