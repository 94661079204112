import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { Spacer } from 'components/Spacer/Spacer';

import * as AS from 'styles/AppStyles';

import TerrenaLogo from 'images/Marketplace.webp';

import * as S from './styles';

const Privacy = () => {
	const topRef = useRef();
	const navigate = useNavigate();
	return (
		<S.MainContainer>
			<S.Card>
				<S.BackCardRow ref={topRef}>
					<S.BackCard onClick={() => navigate('/')}>
						<S.ArrowLeftIcon />
						<S.BackFont>Back To Home Page</S.BackFont>
					</S.BackCard>
					<S.Logo src={TerrenaLogo} alt="logo" />
				</S.BackCardRow>
				<S.SubHeader>
					<S.SubHeaderFont>privacy policy</S.SubHeaderFont>
				</S.SubHeader>
				<S.TextCard>
					<Spacer height={25} />
					<S.HeadingFont> Last Updated: Sep 29, 2022</S.HeadingFont>
					<Spacer height={25} />
					<S.ParagraphFont>
						At Terrena we take your privacy seriously. We make our best efforts to ensure the privacy of everyone who accesses and uses the
						Terrena platform, the website located at{' '}
						<a style={{ color: '#1cbf7b' }} href="https://www.terrena.io" target="_blank" rel="noopener noreferrer">
							https://www.terrena.io
						</a>{' '}
						(the “Website”) and other related services (collectively, the “Services”, or “Terrena”) and only collect and use your personal
						data as described in this Privacy Policy.
					</S.ParagraphFont>
					<S.ParagraphFont>
						YOU ARE STRONGLY RECOMMENDED TO READ THIS PRIVACY POLICY CAREFULLY AND ENSURE THAT YOU UNDERSTAND IT. IF YOU DO NOT AGREE TO THE
						PRIVACY POLICY YOU SHOULD STOP USING THE SERVICES IMMEDIATELY.
					</S.ParagraphFont>
					<Spacer height={25} />
					<S.HeadingFont>1. Personal Data We Collect</S.HeadingFont>
					<Spacer height={25} />
					<S.ParagraphFont>
						We collect personal and non-personal data from you when you access and use the Services. Personal data is any data that can
						directly or indirectly identify a person such as your name, email address, job title, and date of birth. Non-personal data is
						data that does not contain any information that can be used to identify a natural person. Non-personal data also includes
						aggregated and de-identified data, provided that it is not possible to re-identify a person.
					</S.ParagraphFont>
					<S.ParagraphFont>Subject to the following, we do not collect any personal data from you:</S.ParagraphFont>
					<S.ParagraphFont style={{ fontWeight: 'bold' }}>(a) Information directly provided by you</S.ParagraphFont>
					<S.ParagraphFont>
						1. When you subscribe to our newsletter we collect your email address. This is used to send you our newsletter on a regular
						basis to help you stay updated about our products and services.
					</S.ParagraphFont>
					<S.ParagraphFont>
						2. When you connect through your digital wallet, we will be able to see your digital wallet address and the NFTs owned by you.
					</S.ParagraphFont>
					<S.ParagraphFont>
						3. When you contact us through our social media pages (including following us, or mentioning us in your posts) we see your
						personal information such as your name, user ID, job title, profile photo and any other information you choose to share with us
						in your communication with us. This information is stored with the appropriate social media platform and is subject to their
						applicable privacy policy and terms and conditions.
					</S.ParagraphFont>
					<S.ParagraphFont style={{ fontWeight: 'bold' }}>(b) Information we collect automatically</S.ParagraphFont>
					<S.ParagraphFont>
						1. To make the Services more useful to you, our servers (hosted by a third party service provider) collect certain information
						from you. This includes your browser type, operating system, IP address and a date/time stamp for your visit.
					</S.ParagraphFont>
					<S.ParagraphFont>
						2. When you visit the Website, view content provided by us, use the Services we automatically collect and store certain
						information in log files. This includes your IP address, URL, referral URL, exit URL, browser software, operating system,
						date/time, requests type to our Website and error responses returned by our Website and/or the Services.
					</S.ParagraphFont>
					<S.ParagraphFont>
						3. In limited circumstances, we use cookies and other similar technologies (collectively “cookies”). These cookies are used by
						us for limited purposes. While a cookie may not directly identify you, it, however, could, if combined with other data. Please
						note that we do not combine any data in order to identify you.
					</S.ParagraphFont>
					<Spacer height={25} />
					<S.HeadingFont>2. How is Your Data Used?</S.HeadingFont>
					<Spacer height={25} />
					<S.ParagraphFont>
						When we collect any personal data, it will be processed and stored securely. We will comply with our obligations and safeguard
						your rights under the applicable laws at all times. We process your data for the following purposes.
					</S.ParagraphFont>
					<S.ParagraphFont>
						1. We will process your personal data in order to get back to you in order to answer your questions or feedback which we assume
						is the reason why you contact us using our email address, or social media pages.
					</S.ParagraphFont>
					<S.ParagraphFont>
						2. The reason for collecting non-personal data is necessary for the technical operation of the Services and will not be used in
						any way to personally identify you and will only be used to give you a better user experience when using the Site and/or
						Services.
					</S.ParagraphFont>
					<Spacer height={25} />
					<S.HeadingFont>3. Sharing of Your Data</S.HeadingFont>
					<Spacer height={25} />
					<S.ParagraphFont>
						We will not share any of your personal data with any third parties for any purposes, subject to the following exceptions:
					</S.ParagraphFont>
					<S.ParagraphFont>
						a) We use third party service providers (e.g. for managing emails, hosting our website, website analytics) which have access to
						your data. We try our best to ensure that these service providers do not make use of your data for any secondary purposes other
						than our instructions.
					</S.ParagraphFont>
					<S.ParagraphFont>
						b) If we sell, transfer, or merge parts of our business or assets, your data may be transferred to a third party. Any new owner
						of our business may continue to use your data in the same way that we have used it, as specified in this Privacy Policy (i.e. to
						communicate with you).
					</S.ParagraphFont>
					<S.ParagraphFont>
						c) In some limited circumstances, we may be legally required to share certain data, which may rarely include your personal data,
						if we are involved in legal proceedings or complying with legal obligations, a court order, or the instructions of a government
						authority.
					</S.ParagraphFont>
					<Spacer height={25} />
					<S.HeadingFont>4. Where is Your Data Stored?</S.HeadingFont>
					<Spacer height={25} />
					<S.ParagraphFont>
						We use service providers (e.g. email management, website hosting and website analytics) located outside of Canada (including the
						USA) to carry out certain operations during the normal course of business. Consequently, your personal information may be
						transferred to another country where it would be subject to the laws of that country. In order to ensure the security of your
						personal data we sign binding contracts with these service providers and require them to have certain measures in place to
						ensure the confidentiality and security of your personal information. These contracts also ensure that your information is not
						used for purposes other than those for which it was collected.
					</S.ParagraphFont>
					<Spacer height={25} />
					<S.HeadingFont>5. Your Rights</S.HeadingFont>
					<Spacer height={25} />
					<S.ParagraphFont>
						a) The right to be informed about our collection and use of your personal data. This Privacy Policy should tell you everything
						you need to know, but you can always contact us to find out more.
					</S.ParagraphFont>
					<S.ParagraphFont>b) The right to access the personal data we hold about you.</S.ParagraphFont>
					<S.ParagraphFont>
						c) The right to have your personal data rectified if any of your personal data held by us is inaccurate or incomplete.
					</S.ParagraphFont>
					<S.ParagraphFont>
						d) The right to deletion, i.e. the right to ask us to delete or otherwise dispose of any of your personal data that we hold.
					</S.ParagraphFont>
					<S.ParagraphFont>e) The right to restrict (i.e. prevent) the processing of your personal data.</S.ParagraphFont>
					<S.ParagraphFont>f) The right to object to us using your personal data for a particular purpose or purposes.</S.ParagraphFont>
					<S.ParagraphFont>
						g) The right to withdraw consent. This means that, if we are relying on your consent as the legal basis for using your personal
						data, you are free to withdraw that consent at any time.
					</S.ParagraphFont>
					<S.ParagraphFont>
						h) The right to data portability. This means that, if you have provided personal data to us directly, we are using it with your
						consent or for the performance of a contract, and that data is processed using automated means, you can ask us for a copy of
						that personal data to re-use with another service or business in many cases.
					</S.ParagraphFont>
					<S.ParagraphFont>
						i) Rights relating to automated decision-making and profiling. We do not use your personal data in this way.
					</S.ParagraphFont>
					<S.ParagraphFont>
						For more information about how we use your personal data or how to exercise your rights, please contact us by writing an email
						to{' '}
						<a
							style={{ color: '#1cbf7b' }}
							target="_blank"
							rel="noopener noreferrer"
							href="mailto:support@terrena.io?subject=Questions Regarding Terrena's Privacy Policy"
						>
							support@terrena.io
						</a>
					</S.ParagraphFont>
					<Spacer height={25} />
					<S.HeadingFont>6. Data Retention Period</S.HeadingFont>
					<Spacer height={25} />
					<S.ParagraphFont>
						We will retain your personal information only as long as is reasonably necessary for the purposes described in this Privacy
						Policy, or for a longer period as may be required by applicable laws.
					</S.ParagraphFont>
					<Spacer height={25} />
					<S.HeadingFont>7. Minors</S.HeadingFont>
					<Spacer height={25} />
					<S.ParagraphFont>
						We do not intentionally collect or process any personal information from children under fourteen (14) in Quebec or under the
						relevant age threshold in other jurisdictions where the child is located. We may, however, collect technical information
						required for delivering our services and for supporting our internal operations. If you are a parent or a legal guardian and you
						believe that we have unknowingly collected your children’s personal information you may contact us at{' '}
						<a
							style={{ color: '#1cbf7b' }}
							target="_blank"
							rel="noopener noreferrer"
							href="mailto:support@terrena.io?subject=Questions Regarding Terrena's Privacy Policy"
						>
							support@terrena.io
						</a>
					</S.ParagraphFont>
					<Spacer height={25} />
					<S.HeadingFont>8. Security Measures</S.HeadingFont>
					<Spacer height={25} />
					<S.ParagraphFont>
						We use Secure Sockets Layer (SSL) software to encrypt the information you enter on our Website in order to protect its security
						during transmission to and from our Website. When storing personal information, we protect its security by encryption and
						pseudonymization of critical data.
					</S.ParagraphFont>
					<S.ParagraphFont>
						We maintain physical, electronic and procedural safeguards in connection with the collection, storage and disclosure of personal
						information. We also apply access restrictions to your personal data by our employees. Furthermore, we ensure to educate and
						train our employees about the importance of privacy and data protection on a continuous basis.
					</S.ParagraphFont>
					<S.ParagraphFont>
						Despite the fact that we take all these measures to maintain the safety and security of your personal information, you should
						note that no transmission over the Internet can ever be guaranteed to be secure. Therefore, we cannot fully guarantee the
						security of any personal information that you transfer over the Internet to us, and you do so at your own risk.
					</S.ParagraphFont>
					<Spacer height={25} />
					<S.HeadingFont>9. Changes to Privacy Policy</S.HeadingFont>
					<Spacer height={25} />
					<S.ParagraphFont>
						We may change this Privacy Policy from time to time, at our sole discretion. This may be necessary, for example, in order to
						reflect changes in the laws and regulations we are bound by, or if we change our business in a way that affects personal data
						protection.
					</S.ParagraphFont>
					<S.ParagraphFont>
						Any changes will immediately be posted on our Website and you will be deemed to have accepted the terms of the Privacy Policy on
						your first use of our Website and the Services following the alterations. We recommend that you check our Privacy Policy
						regularly in order to keep up-to-date.
					</S.ParagraphFont>
					<Spacer height={25} />
					<S.HeadingFont>10. Notice to EU/UK residents</S.HeadingFont>
					<Spacer height={25} />
					<S.ParagraphFont>
						The General Data Protection Regulation of the EU and the UK (collectively the “GDPR”) gives the EU/UK residents certain rights
						when their personal data is being collected and processed. At Terrena, we are committed to comply with the GDPR.
					</S.ParagraphFont>
					<S.ParagraphFont style={{ fontWeight: 'bold' }}>Rights of Data Subjects</S.ParagraphFont>
					<S.ParagraphFont>
						As a resident of the EU/UK you have the rights accorded to you under the Chapter 3 of the GDPR, and we always work to uphold
						these rights. For more information about how to exercise your rights under the GDPR, please contact us at{' '}
						<a
							style={{ color: '#1cbf7b' }}
							target="_blank"
							rel="noopener noreferrer"
							href="mailto:support@terrena.io?subject=Questions Regarding Terrena's Privacy Policy"
						>
							support@terrena.io
						</a>
					</S.ParagraphFont>
					<S.ParagraphFont style={{ fontWeight: 'bold' }}>International Transfer of Data</S.ParagraphFont>
					<S.ParagraphFont>
						If you are a resident of the EU/UK, you should know that when you access our Website and use the Services your data, including
						your personal data, as described in this Privacy Policy will be transferred to outside the EU/UK.
					</S.ParagraphFont>
					<Spacer height={25} />
					<S.HeadingFont>11. Notice to California residents</S.HeadingFont>
					<Spacer height={25} />
					<S.ParagraphFont>
						Residents of California have additional rights under the California Consumer Privacy Act (CCPA).
					</S.ParagraphFont>
					<S.ParagraphFont style={{ fontWeight: 'bold' }}>Your CCPA Rights</S.ParagraphFont>
					<S.ParagraphFont>
						If you are a California resident, you may request that we disclose to you the following information covering the 12 months
						preceding your request:
					</S.ParagraphFont>
					<S.ParagraphFont>
						• The categories of personal information that we have collected about you and the categories of sources from which we collected
						such personal information
					</S.ParagraphFont>
					<S.ParagraphFont>• The business or commercial purposes for collecting such personal information</S.ParagraphFont>
					<S.ParagraphFont>
						• The categories of personal information about you that we have disclosed to third parties for a business purpose and the
						categories of third parties to whom we have disclosed such personal information
					</S.ParagraphFont>
					<S.ParagraphFont>
						You, as a resident of California may contact us by writing to
						<a
							style={{ color: '#1cbf7b' }}
							target="_blank"
							rel="noopener noreferrer"
							href="mailto:support@terrena.io?subject=Questions Regarding Terrena's Privacy Policy"
						>
							support@terrena.io
						</a>{' '}
						to obtain copies of any personal information that may have been collected or stored about you, and request its correction or
						deletion as may be required and permitted by law. That said, you should also be aware that there may exist legal or other
						reasons that Spin may have to decline to honor your request. For example, in cases if we cannot verify your identity or confirm
						that the personal information that we maintain relates to you, or if we cannot verify that you have the authority to make a
						request on behalf of another individual.
					</S.ParagraphFont>
					<S.ParagraphFont>
						As a California resident, you have the right to empower an authorized agent to submit requests on your behalf. We will need your
						authorized agent to confirm his/her authority by a written authorization.
					</S.ParagraphFont>
					<S.ParagraphFont style={{ fontWeight: 'bold' }}>Sale of Personal Information</S.ParagraphFont>
					<S.ParagraphFont>
						We do not and will never sell or trade any personal information collected from you for monetary value. Therefore, you do not
						need to opt out in order to prevent the sale or trading of personal data.
					</S.ParagraphFont>
					<S.ParagraphFont style={{ fontWeight: 'bold' }}>“Do Not Track” Requests & Behavioral Tracking</S.ParagraphFont>
					<S.ParagraphFont>
						Please be advised that currently we do not respond to “Do Not Track” requests. You should be aware that we allow third party
						behavioral tracking on our website.
					</S.ParagraphFont>
					<Spacer height={25} />
					<S.HeadingFont>12. Our Contacts</S.HeadingFont>
					<Spacer height={25} />
					<S.ParagraphFont>
						If you have any questions we would be happy to answer them. Do not hesitate to contact us for any inquiries you might have
						through the following contract details: Email:{' '}
						<a
							style={{ color: '#1cbf7b' }}
							target="_blank"
							rel="noopener noreferrer"
							href="mailto:support@terrena.io?subject=Questions Regarding Terrena's Privacy Policy"
						>
							support@terrena.io
						</a>
					</S.ParagraphFont>
					<AS.FlexRow style={{ justifyContent: 'flex-end' }}>
						<S.BackCard
							onClick={() => {
								topRef?.current.scrollIntoView({
									behavior: 'smooth',
									block: 'start',
									inline: 'nearest',
								});
							}}
						>
							<S.ArrowUpIcon />
							<S.BackFont>Back To The Top</S.BackFont>
						</S.BackCard>
					</AS.FlexRow>
				</S.TextCard>
			</S.Card>
		</S.MainContainer>
	);
};

export default Privacy;
