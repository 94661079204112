import ListingsDefaultAvatar from 'images/ListingsDefaultAvatar.webp';

export const findImageUrlInMetadata = metadata => {
	if (metadata.imageUrl) {
		return metadata.imageUrl;
	} else if (metadata.image) {
		return metadata.image;
	}
	return ListingsDefaultAvatar;
};
