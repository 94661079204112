import { RefreshOutline } from '@styled-icons/evaicons-outline/RefreshOutline';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { StyledLoader } from 'components/Loader/StyledLoader';

import { setNotification } from 'state/features/notificationSlice';

import * as AS from 'styles/AppStyles';

import * as S from '../styles';
import OwnedNFTsCard from './OwnedNFTsCard';

const ProfileOwned = ({ gridSelected, platformFilter, searchNFTsValue }) => {
	const dispatch = useDispatch();
	const [filteredNfts, setFilteredNfts] = useState([]);

	// Get Alchemy NFTs from Global Store

	const ownerNfts = useSelector(state => state.NFTs.alchemyNFTs);
	const loadingState = useSelector(state => state.NFTs.alchemyLoading);

	useEffect(() => {
		const newNftArray = (ownerNfts?.ownedNfts || []).filter(nft => {
			const b1 = platformFilter === 'all' ? true : nft.platform === platformFilter;
			const b2 = searchNFTsValue === '' ? true : nft.title?.toLowerCase().includes(searchNFTsValue.toLowerCase());
			return b1 && b2;
		});
		setFilteredNfts(newNftArray);
	}, [platformFilter, ownerNfts, searchNFTsValue]);

	return (
		<>
			<S.ListingsFoundDiv>
				<S.RefreshOutlineDiv
					onClick={() =>
						dispatch(
							setNotification({
								msg: 'We have a queued an update for you! Please check back later...',
								alertType: 'success',
							})
						)
					}
				>
					<RefreshOutline />
				</S.RefreshOutlineDiv>
				<AS.Text color="black" fontSize="18px" fontFamily="Helvetica Neue" userSelectNone>
					<span style={{ fontWeight: 'bold' }}>{filteredNfts.length > 0 ? filteredNfts.length : 0}</span> Found
				</AS.Text>
			</S.ListingsFoundDiv>

			<S.ListingsCardRow gridSelected={gridSelected}>
				{loadingState ? (
					<StyledLoader style={{ position: 'absolute' }} />
				) : (
					<>
						{(filteredNfts || []).map((nft, index) => {
							return <OwnedNFTsCard id={nft.id} key={nft.id} title={nft.title} platform={nft.platform} image={nft.image} noHover={false} />;
						})}
					</>
				)}
			</S.ListingsCardRow>
		</>
	);
};

export default ProfileOwned;
