import { ArrowLeft } from '@styled-icons/fa-solid/ArrowLeft';
import { ArrowUp } from '@styled-icons/fa-solid/ArrowUp';
import { device } from 'device-dimensions';
import styled from 'styled-components';

import * as AS from 'styles/AppStyles';

export const MainContainer = styled(AS.Column)`
	padding: 25px 15px;
	height: 100%;
	align-items: center;
	flex-direction: column;
`;

export const BackCardRow = styled(AS.FlexRow)`
	justify-content: space-between;
	padding: 8px 20px;
	@media ${device.mobileL} {
		padding: 8px 4px;
	}
`;

export const Card = styled.div`
	background-color: white;
	height: 100%;
	border-radius: 5px;
	overflow-y: auto;
	cursor: auto;
	&::-webkit-scrollbar-track {
		background-color: whitesmoke;
		border-radius: 7px;
		margin-right: 2px;

		&:hover {
			background-color: whitesmoke;
		}
	}

	&::-webkit-scrollbar {
		width: 6px;
		background-color: whitesmoke;

		&:hover {
			background-color: whitesmoke;
		}
	}

	&::-webkit-scrollbar-thumb {
		background-color: #1cbf7b;
		border-radius: 7px;
		transition: all 0.3s ease-in-out;
		&:hover {
			background-color: #199e67;
			cursor: pointer !important;
		}
	}
`;

// Logo

export const Logo = styled.img`
	width: 5%;
	@media ${device.mobileL} {
		width: 12%;
	}
`;

// Subheader

export const SubHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 5px 5px 5px;
`;

export const SubHeaderFont = styled.div`
	font-family: Bord;
	font-weight: 900;
	letter-spacing: 0.2px;
	font-size: 30px;
	color: black;
	user-select: none;
	@media ${device.mobileL} {
		margin-top: 15px;
	}
`;

// Back Arrow Card on hover

export const BackCard = styled.div`
	cursor: pointer;
	display: flex;
	flex-direction: row;
	align-items: center;
	transition: box-shadow 0.1s ease-in-out;
	box-shadow: none;
	max-width: 100%;
	padding: 5px 10px;
	&:hover {
		border: none;
		border-radius: 4px;
		box-sizing: border-box;
		-webkit-box-shadow: 0px 0px 6px -1px rgba(0, 0, 0, 0.397);
		box-shadow: 0px 0px 6px -1px rgba(0, 0, 0, 0.397);
	}
`;

export const ArrowLeftIcon = styled(ArrowLeft)`
	color: #1cbf7b;
	width: 22px;
	padding-left: 5px;
`;

export const ArrowUpIcon = styled(ArrowUp)`
	color: #1cbf7b;
	width: 22px;
	padding-left: 5px;
`;

export const BackFont = styled.p`
	font-family: Helvetica Neue, sans-serif;
	font-size: 22px;
	color: black;
	margin-left: 18px;
`;

// Main Text Card

export const TextCard = styled.div`
	padding: 25px 60px;
	@media ${device.mobileL} {
		padding: 25px 15px;
	}
`;

export const ParagraphFont = styled.p`
	text-indent: 40px;
	text-align: justify;
	margin-bottom: 15px;
	line-height: 35px;
	color: black;
	font-family: Helvetica Neue, sans-serif;
	font-weight: 300;
	letter-spacing: 0.3px;
	font-size: 18px;
`;

export const HeadingFont = styled.p`
	margin-bottom: 25px;
	color: black;
	font-family: Helvetica Neue, sans-serif;
	font-weight: 600;
	letter-spacing: 0.3px;
	font-size: 18px;
	text-decoration: underline;
`;
