import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { InView, useInView } from 'react-intersection-observer';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { setInView } from 'state/features/inViewSlice';
import { setNotification } from 'state/features/notificationSlice';

import logoIcon from 'images/logo-icon.webp';

import { communityLinks, navigationLinks, navigationSectionTitle } from '../../constants';
import './style.css';

export const Footer = () => {
	const dispatch = useDispatch();
	const { ref, inView } = useInView();
	const [isLoading, setIsLoading] = useState(false);
	const [email, setEmail] = useState('');

	// TODO: Note sure why we need it here...
	useEffect(() => {
		if (inView) {
			dispatch(setInView(true));
		} else {
			dispatch(setInView(false));
		}
	}, [dispatch, inView]);

	const handleSubmit = useCallback(
		async event => {
			event.preventDefault();
			setIsLoading(true);

			const regex = /^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i;
			if (!email || regex.test(email) === false) {
				dispatch(
					setNotification({
						msg: 'Email is not valid',
						alertType: 'error',
					})
				);
				setIsLoading(false);

				return false;
			}

			try {
				const response = await axios.post(
					'https://launchpad.webisoft.org/api/terrena/mailing',
					{ email },
					{ headers: { 'Content-Type': 'application/json' } }
				);

				if (response.status === 200) {
					dispatch(setNotification({ msg: 'You successfully joined to our mailing list!', alertType: 'success' }));
					setEmail('');
				}
			} catch (error) {
				console.error('🚀 ~ file: index.js ~ line 47 ~ Footer ~ error', error);
			} finally {
				setIsLoading(false);
			}
		},
		[dispatch, email]
	);

	return (
		<InView triggerOnce>
			<footer className="footer" ref={ref}>
				<div className="footer-main">
					<div className="don't-miss-a-thing">
						<div className="email-subscription">
							<strong>DONT MISS A THING</strong>
							<p>Join our mailing list and stay informed on site updates, community announcements, giveaways and more!</p>
							<form onSubmit={handleSubmit}>
								<input className="input" value={email} onChange={e => setEmail(e.target.value)} placeholder="Enter your email" />
								<button className="button" disabled={email === '' || isLoading}>
									{isLoading && (
										<svg width={20} height={20} viewBox="-5 -5 48 48" stroke="currentColor">
											<g transform="translate(1 1)" strokeWidth={4} fill="none" fillRule="evenodd">
												<circle strokeOpacity={0.5} cx={18} cy={18} r={18} />
												<path d="M36 18c0-9.94-8.06-18-18-18">
													<animateTransform
														attributeName="transform"
														type="rotate"
														from="0 18 18"
														to="360 18 18"
														dur="1s"
														repeatCount="indefinite"
													/>
												</path>
											</g>
										</svg>
									)}
									{isLoading ? 'Joining...' : 'Join'}
								</button>
							</form>
						</div>
						<div className="join-the-community">
							<strong>JOIN THE COMMUNITY</strong>
							<ul>
								{communityLinks.map(({ icon, href, title }) => (
									<li key={href}>
										<a href={href} target="_blank" rel="noopener noreferrer" title={title}>
											{icon}
										</a>
									</li>
								))}
							</ul>
						</div>
					</div>
					<nav className="footer-navigation">
						{Object.entries(navigationLinks).map(([name, list]) => (
							<div className="footer-navigation-section" key={name}>
								<strong>{navigationSectionTitle[name]}</strong>
								<ul>
									{list.map(({ title, to }) => (
										<li key={to}>
											<Link to={to}>{title}</Link>
										</li>
									))}
								</ul>
							</div>
						))}
					</nav>
					<div className="footer-disclaimer">
						<strong>Terrena</strong>
						<p>
							We pride ourselves in being part of a movement that supports the expansion of real estate into the Metaverse. We are a
							decentralized augmented marketplace that supports solely LAND plot/parcel NFTs from Virtual Blockchain Worlds IVEs) such as
							Decentraland and The Sandbox. We will be supporting many more Virtual rigs in the future.
						</p>
						<p>Our mission is to become the control hub for all Virtual Real Estate in the years to come.</p>
						<img src={logoIcon} alt="logo" style={{ width: '65px' }} />
					</div>
				</div>
				<div className="footer-bottom">
					<nav>
						<ul>
							<li>
								<Link to="/privacy">PRIVACY POLICY</Link>
							</li>
							<li>
								<Link to="/terms">TERMS OF SERVICE</Link>
							</li>
						</ul>
					</nav>
					<small>&copy; 2022 Development Terrena Inc.</small>
					<em>
						Developed by{' '}
						<a target="_blank" rel="noopener noreferrer" href="https://webisoft.com/">
							Webisoft
						</a>
					</em>
				</div>
			</footer>
		</InView>
	);
};
