import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	inView: false,
};

export const inViewSlice = createSlice({
	name: 'inView',
	initialState,
	reducers: {
		setInView: (state, action) => {
			return {
				...state,
				inView: action.payload,
			};
		},
	},
});

export const { setInView } = inViewSlice.actions;

export default inViewSlice.reducer;
