import { ArrowRight } from '@styled-icons/bootstrap/ArrowRight';
import { CheckboxChecked } from '@styled-icons/icomoon/CheckboxChecked';
import { CheckboxUnchecked } from '@styled-icons/icomoon/CheckboxUnchecked';
import axios from 'axios';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { Button } from 'components/Button/Button';
import { Spacer } from 'components/Spacer/Spacer';

import { setNotification } from 'state/features/notificationSlice';

import * as AS from 'styles/AppStyles';

import MarketplaceLogo from 'images/Marketplace.webp';

import * as S from './styles';

const FiltersSideNav = ({
	platform,
	openSideNav,
	setOpenSideNav,
	setMobileFilters,
	isMobileFiltersButtonVisible,
	NFTStatusFilter,
	setNFTStatusFilter,
	clearAllFilters,
	minNFTPrice,
	maxNFTPrice,
	setMinNFTPrice,
	setMaxNFTPrice,
	setApplyPrice,
	applyPrice,
	minXValue,
	setMinXValue,
	maxXValue,
	setMaxXValue,
	minYValue,
	setMinYValue,
	maxYValue,
	setMaxYValue,
	minZValue,
	setMinZValue,
	maxZValue,
	setMaxZValue,
	applyCoordinates,
	setApplyCoordinates,
	closeToPlazaMin,
	closeToPlazaMax,
	setCloseToPlazaMin,
	setCloseToPlazaMax,
	closeToRoadMin,
	closeToRoadMax,
	setCloseToRoadMin,
	setCloseToRoadMax,
	closeToDistrictMin,
	closeToDistrictMax,
	setCloseToDistrictMin,
	setCloseToDistrictMax,
	applyCloseToRoad,
	setApplyCloseToRoad,
	applyCloseToPlaza,
	setApplyCloseToPlaza,
	applyCloseToDistrict,
	setApplyCloseToDistrict,
	parcelSize,
	setParcelSize,
	waterfrontStatus,
	setWaterfrontStatus,
	roadsideStatus,
	setRoadsideStatus,
	voxelsSize,
	setVoxelsSize,
	island,
	setIsland,
}) => {
	const dispatch = useDispatch();
	// Hide filters button when the footer is in view
	const isInView = useSelector(state => state.inView.inView);

	// Animate the React-Select component

	const animatedComponents = makeAnimated();

	// Media query for React for the mobile SideNav animation

	const isMobile = useMediaQuery({ query: '(max-width: 425px)' });

	const [allIslands, setAllIslands] = useState(null);
	const [allSuburbs, setAllSuburbs] = useState(null);
	const [menuIsOpen, setMenuOpen] = useState(false);
	const [suburbsMenuOpen, setSuburbsMenuOpen] = useState(false);
	const [islandsValue, setIslandsValue] = useState(null);
	const [suburbsValue, setSuburbsValue] = useState(null);

	const getAllIslands = async () => {
		const data = await axios.get('https://www.voxels.com/api/islands.json');
		setAllIslands(data.data.islands);
	};

	const getAllSuburbs = async () => {
		const data = await axios.get('https://www.voxels.com/api/suburbs.json');
		setAllSuburbs(data.data.suburbs);
	};

	useEffect(() => {
		if (allIslands?.length > 0) return;
		getAllIslands();
	}, [allIslands, menuIsOpen]);

	useEffect(() => {
		if (allSuburbs?.length > 0) return;
		getAllSuburbs();
	}, [allSuburbs, suburbsMenuOpen]);

	useEffect(() => {
		if (!island) {
			setIslandsValue(null);
		}
	}, [island]);

	const islandsOptions = useMemo(() => {
		return (allIslands || []).map(island => {
			return {
				value: island.name,
				label: island.name,
			};
		});
	}, [allIslands]);

	const islandsSelectFunc = option => {
		const selectedValue = islandsOptions.find(island => island.value === option.value);
		setIslandsValue(selectedValue);
		setIsland(selectedValue.value);
		setMenuOpen(false);
	};

	const suburbsOptions = useMemo(() => {
		return (allSuburbs || []).map(suburb => {
			return {
				value: suburb.name,
				label: suburb.name,
			};
		});
	}, [allSuburbs]);

	const suburbsSelectFunc = option => {
		const selectedValue = suburbsOptions.find(suburb => suburb.value === option.value);
		setSuburbsValue(selectedValue);
		setMenuOpen(false);
	};

	// React Select Styles for Islands

	const islandsSelectStyles = {
		control: (base, state) => ({
			...base,
			borderRadius: '40px',
			backgroundColor: 'white',
			border: state.isFocused ? '1px solid #bdbdbd' : '1px solid #DBDBDB',
			boxShadow: 'none',
			fontFamily: 'Helvetica Neue, sans-serif',
			fontSize: '18px',
			padding: '5px',
			cursor: 'pointer',
			display: 'flex',
			overflow: 'hidden',
			position: 'relative',
			zIndex: 4,
			lineHeight: 1,
			transition: 'all 0.3s ease-in-out',
			alignItems: 'center',
			userSelect: 'none',
			color: '#DBDBDB',
			width: '145px',
			textAlign: 'center',
			'&:hover': {
				boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
			},
		}),
		menuPortal: base => ({ ...base, zIndex: 9999 }),
		valueContainer: (styles, { data, isDisabled, isFocused, isSelected }) => {
			return {
				...styles,
				color: 'white',
				padding: 0,
			};
		},
		placeholder: base => ({
			...base,
			color: '#8e8e8e',
			marginRight: '10px',
		}),
		indicatorSeparator: () => ({ display: 'none' }),
		dropdownIndicator: (base, state) => ({
			...base,
			color: '#8e8e8e',
			padding: '2px 2px 2px 0',
			transition: 'transform 0.3s ease-in-out',
			fontSize: '16px',
			transform: menuIsOpen ? 'rotate(180deg)' : null,
			'&:hover': {
				color: '#8e8e8e',
			},
		}),
		option: styles => {
			return {
				...styles,
				position: 'relative',
				backgroundColor: '#eceaea',
				color: 'black',
				textAlign: 'center',
				fontFamily: 'Helvetica Neue, sans-serif',
				fontSize: '14px',
				padding: '15px',
				cursor: 'pointer',
				transition: 'all 0.3s ease-in-out',
				maxWidth: '100%',
				'&:last-child': {
					'&::after': {
						display: 'none',
					},
				},
				'&::after': {
					content: '""',
					height: '1px',
					background: '#ddd8d8',
					width: '90%',
					position: 'absolute',
					left: '10px',
					bottom: 0,
					right: 0,
				},
				'&:hover': {
					backgroundColor: '#bdb8b8',
				},
			};
		},
		menuList: base => ({
			...base,
			paddingTop: 0,
			paddingBottom: 0,
			borderRadius: '5px',
			zIndex: 3,
			'  &::-webkit-scrollbar-track': {
				backgroundColor: 'whitesmoke',
				borderRadius: '7px',
				marginRight: '2px',

				'&:hover': {
					backgroundColor: 'whitesmoke',
				},
			},
			'&::-webkit-scrollbar': {
				width: '6px',
				backgroundColor: 'whitesmoke',
				'&:hover': { backgroundColor: 'whitesmoke' },
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: '#8e8e8e',
				borderRadius: '7px',
				transition: 'all 0.3s ease-in-out',
				'&:hover': { backgroundColor: '#757575', cursor: 'pointer !important' },
			},
		}),
	};

	// React Select Styles for Suburbs

	const suburbsSelectStyles = {
		control: (base, state) => ({
			...base,
			borderRadius: '40px',
			backgroundColor: 'white',
			border: state.isFocused ? '1px solid #bdbdbd' : '1px solid #DBDBDB',
			boxShadow: 'none',
			fontFamily: 'Helvetica Neue, sans-serif',
			fontSize: '18px',
			padding: '5px',
			cursor: 'pointer',
			display: 'flex',
			overflow: 'hidden',
			position: 'relative',
			zIndex: 4,
			lineHeight: 1,
			transition: 'all 0.3s ease-in-out',
			alignItems: 'center',
			userSelect: 'none',
			color: '#DBDBDB',
			width: '145px',
			textAlign: 'center',
			'&:hover': {
				boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
			},
		}),

		valueContainer: (styles, { data, isDisabled, isFocused, isSelected }) => {
			return {
				...styles,
				color: 'white',
				padding: 0,
			};
		},
		placeholder: base => ({
			...base,
			color: '#8e8e8e',
			marginRight: '10px',
		}),
		indicatorSeparator: () => ({ display: 'none' }),
		dropdownIndicator: (base, state) => ({
			...base,
			color: '#8e8e8e',
			padding: '2px 2px 2px 0',
			transition: 'transform 0.3s ease-in-out',
			fontSize: '16px',
			transform: suburbsMenuOpen ? 'rotate(180deg)' : null,
			'&:hover': {
				color: '#8e8e8e',
			},
		}),
		option: styles => {
			return {
				...styles,
				position: 'relative',
				backgroundColor: '#eceaea',
				color: 'black',
				textAlign: 'center',
				fontFamily: 'Helvetica Neue, sans-serif',
				fontSize: '14px',
				padding: '15px',
				cursor: 'pointer',
				transition: 'all 0.3s ease-in-out',
				maxWidth: '100%',
				'&:last-child': {
					'&::after': {
						display: 'none',
					},
				},
				'&::after': {
					content: '""',
					height: '1px',
					background: '#ddd8d8',
					width: '90%',
					position: 'absolute',
					left: '10px',
					bottom: 0,
					right: 0,
				},
				'&:hover': {
					backgroundColor: '#bdb8b8',
				},
			};
		},
		menuList: base => ({
			...base,
			paddingTop: 0,
			paddingBottom: 0,
			borderRadius: '5px',
			zIndex: 3,
			'  &::-webkit-scrollbar-track': {
				backgroundColor: 'whitesmoke',
				borderRadius: '7px',
				marginRight: '2px',

				'&:hover': {
					backgroundColor: 'whitesmoke',
				},
			},
			'&::-webkit-scrollbar': {
				width: '6px',
				backgroundColor: 'whitesmoke',
				'&:hover': { backgroundColor: 'whitesmoke' },
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: '#8e8e8e',
				borderRadius: '7px',
				transition: 'all 0.3s ease-in-out',
				'&:hover': { backgroundColor: '#757575', cursor: 'pointer !important' },
			},
		}),
	};

	// SideNav Framer-Motion Variants

	const sideNavVariants = {
		opened: {
			opacity: 1,
			x: 0,
			transition: {
				type: 'tween',
				stiffness: 95,
			},
		},
		closed: {
			opacity: 1,
			x: -100,
			transition: {
				duration: 0.5,
			},
		},
	};

	const sideNavVariantsMobile = {
		opened: {
			y: 0,
			transition: {
				type: 'tween',
				stiffness: 95,
				duration: 0.6,
			},
		},
		closed: {
			opacity: 1,
			y: 1000,
			transition: {
				duration: 0.6,
			},
		},
	};

	return (
		<S.SideNav
			openSideNav={openSideNav}
			onClick={() => {
				if (openSideNav) return;
				setOpenSideNav(true);
			}}
		>
			<S.ArrowRightIcon
				openSideNav={openSideNav}
				onClick={() => {
					setOpenSideNav(prevState => !prevState);
				}}
			>
				<ArrowRight style={{ width: '25px' }} />
			</S.ArrowRightIcon>
			<AnimatePresence>
				{openSideNav && (
					<S.FiltersContainer
						animate={'opened'}
						initial={'closed'}
						exit={'closed'}
						variants={isMobile ? sideNavVariantsMobile : sideNavVariants}
						as={motion.div}
						openSideNav={openSideNav}
					>
						<S.TimesIconDiv onClick={() => setOpenSideNav(false)}>
							<S.TimesIcon />
						</S.TimesIconDiv>
						<AS.FlexRow isCentered>
							<AS.Text fontSize="28px" color="black" userSelectNone>
								filters
							</AS.Text>
						</AS.FlexRow>
						<Spacer height={20} />
						<AS.FlexRow isCentered>
							<S.ClearAllButton text="clear all" onClick={() => clearAllFilters()} />
						</AS.FlexRow>
						<Spacer height={70} />
						<AS.FlexRow isCentered>
							<AS.Text color="black" fontSize="18px" userSelectNone>
								status
							</AS.Text>
						</AS.FlexRow>
						<Spacer height={20} />
						<S.StatusRow>
							<S.StatusColumn alignItems="center">
								<S.StatusButton
									style={{
										backgroundColor: NFTStatusFilter === 'onAuction' && '#1cbf7b',
										color: NFTStatusFilter === 'onAuction' && 'white',
									}}
									text="On Auction"
									onClick={() => setNFTStatusFilter('onAuction')}
								/>
								<S.StatusButton
									style={{
										backgroundColor: NFTStatusFilter === 'hasOffers' && '#1cbf7b',
										color: NFTStatusFilter === 'hasOffers' && 'white',
									}}
									text="Has Offers"
									onClick={() => setNFTStatusFilter('hasOffers')}
								/>
							</S.StatusColumn>
							<AS.Column alignItems="center" style={{ gap: '15px' }}>
								<S.StatusButton
									style={{
										backgroundColor: NFTStatusFilter === 'buyNow' && '#1cbf7b',
										color: NFTStatusFilter === 'buyNow' && 'white',
									}}
									text="Buy Now"
									onClick={() => setNFTStatusFilter('buyNow')}
								/>
								<S.StatusButton
									style={{
										backgroundColor: NFTStatusFilter === 'new' && '#1cbf7b',
										color: NFTStatusFilter === 'new' && 'white',
									}}
									text="New"
									onClick={() => setNFTStatusFilter('new')}
								/>
							</AS.Column>
						</S.StatusRow>
						<Spacer height={60} />
						<AS.FlexRow isCentered>
							<AS.Text color="black" fontSize="18px" userSelectNone>
								price
							</AS.Text>
						</AS.FlexRow>
						<Spacer height={20} />
						<S.PriceRow>
							<AS.Column alignItems="center">
								<S.NumberInput
									placeholder="Min"
									onWheel={e => e.target.blur()}
									min="0"
									type="number"
									value={minNFTPrice}
									onChange={e => {
										setMinNFTPrice(e.target.value);
										setApplyPrice(false);
									}}
								/>
							</AS.Column>
							<AS.Column alignItems="center">
								<AS.Text fontSize="18px" color="black" fontFamily="Helvetica Neue">
									to
								</AS.Text>
							</AS.Column>
							<AS.Column alignItems="center">
								<S.NumberInput
									placeholder="Max"
									onWheel={e => e.target.blur()}
									type="number"
									min="0"
									value={maxNFTPrice}
									onChange={e => {
										setMaxNFTPrice(e.target.value);
										setApplyPrice(false);
									}}
								/>
							</AS.Column>
						</S.PriceRow>
						<Spacer height={30} />
						<AS.FlexRow isCentered>
							<Button
								text="APPLY"
								style={{
									fontFamily: 'Helvetica Neue',
									height: '24px',
									padding: '20px 30px',
									color: applyPrice ? 'white' : '#1cbf7b',
								}}
								backgroundColor={applyPrice ? '#1cbf7b' : 'white'}
								width="164px"
								onClick={() => {
									if (minNFTPrice === '' || maxNFTPrice === '' || minNFTPrice < 0 || maxNFTPrice < 0) {
										dispatch(
											setNotification({
												msg: 'Min Price and Max Price must be set and must be greater than zero!',
												alertType: 'error',
											})
										);
										return;
									}
									setApplyPrice(true);
								}}
							/>
						</AS.FlexRow>
						<Spacer height={60} />
						{platform === 'somnium-space' ? (
							<>
								<AS.FlexRow isCentered>
									<AS.Text color="black" fontSize="18px" userSelectNone>
										size
									</AS.Text>
								</AS.FlexRow>
								<Spacer height={20} />
								<S.TypeRow platform>
									<AS.Column alignItems="center">
										<S.StatusButton
											style={{
												color: parcelSize === 'S' ? 'white' : '#8e8e8e',
												backgroundColor: parcelSize === 'S' ? '#1cbf7b' : 'white',
											}}
											text="S"
											onClick={() => setParcelSize('S')}
										/>
									</AS.Column>
									<AS.Column alignItems="center">
										<S.StatusButton
											style={{
												color: parcelSize === 'M' ? 'white' : '#8e8e8e',
												backgroundColor: parcelSize === 'M' ? '#1cbf7b' : 'white',
											}}
											text="M"
											onClick={() => setParcelSize('M')}
										/>
									</AS.Column>
									<AS.Column alignItems="center">
										<S.StatusButton
											style={{
												color: parcelSize === 'XL' ? 'white' : '#8e8e8e',
												backgroundColor: parcelSize === 'XL' ? '#1cbf7b' : 'white',
											}}
											text="XL"
											onClick={() => setParcelSize('XL')}
										/>
									</AS.Column>
								</S.TypeRow>
							</>
						) : platform === 'voxels' ? (
							<>
								<AS.FlexRow isCentered>
									<AS.Text color="black" fontSize="18px" userSelectNone>
										size
									</AS.Text>
								</AS.FlexRow>
								<Spacer height={20} />
								<AS.Column alignItems="center" style={{ gap: '15px' }}>
									<S.StatusButton
										style={{
											color: voxelsSize === 'plot' ? 'white' : '#8e8e8e',
											backgroundColor: voxelsSize === 'plot' ? '#1cbf7b' : 'white',
										}}
										text="Plot"
										onClick={() => setVoxelsSize('plot')}
									/>
									<S.StatusButton
										style={{
											color: voxelsSize === 'unit' ? 'white' : '#8e8e8e',
											backgroundColor: voxelsSize === 'unit' ? '#1cbf7b' : 'white',
										}}
										text="Unit"
										onClick={() => setVoxelsSize('unit')}
									/>
									<S.StatusButton
										style={{
											color: voxelsSize === 'inner' ? 'white' : '#8e8e8e',
											backgroundColor: voxelsSize === 'inner' ? '#1cbf7b' : 'white',
										}}
										text="Inner"
										onClick={() => setVoxelsSize('inner')}
									/>
								</AS.Column>
							</>
						) : null}
						<Spacer height={60} />
						<AS.FlexRow isCentered>
							<AS.Text color="black" fontSize="18px" userSelectNone>
								coordinates
							</AS.Text>
						</AS.FlexRow>
						<Spacer height={20} />
						<S.CoordinatesRow>
							<AS.Column alignItems="center">
								<AS.FlexRow style={{ gap: '10px' }}>
									<AS.Text color="black" fontSize="18px">
										x
									</AS.Text>
									<S.NumberInputMobile
										placeholder="Min"
										type="number"
										value={minXValue}
										onChange={e => {
											setMinXValue(e.target.value);
											setApplyCoordinates(false);
										}}
									/>
								</AS.FlexRow>
							</AS.Column>
							<AS.Column alignItems="center">
								<S.NumberInput
									placeholder="Max"
									type="number"
									value={maxXValue}
									onChange={e => {
										setMaxXValue(e.target.value);
										setApplyCoordinates(false);
									}}
								/>
							</AS.Column>
						</S.CoordinatesRow>
						<Spacer height={20} />
						<S.CoordinatesRow>
							<AS.Column alignItems="center">
								<AS.FlexRow style={{ gap: '10px' }}>
									<AS.Text color="black" fontSize="18px">
										y
									</AS.Text>
									<S.NumberInputMobile
										placeholder="Min"
										type="number"
										value={minYValue}
										onChange={e => {
											setMinYValue(e.target.value);
											setApplyCoordinates(false);
										}}
									/>
								</AS.FlexRow>
							</AS.Column>
							<AS.Column alignItems="center">
								<S.NumberInput
									placeholder="Max"
									type="number"
									value={maxYValue}
									onChange={e => {
										setMaxYValue(e.target.value);
										setApplyCoordinates(false);
									}}
								/>
							</AS.Column>
						</S.CoordinatesRow>

						{platform === 'somnium-space' && (
							<>
								<Spacer height={20} />
								<S.CoordinatesRow>
									<AS.Column alignItems="center">
										<AS.FlexRow style={{ gap: '10px' }}>
											<AS.Text color="black" fontSize="18px">
												z
											</AS.Text>
											<S.NumberInputMobile
												placeholder="Min"
												type="number"
												value={minZValue}
												onChange={e => {
													setMinZValue(e.target.value);
													setApplyCoordinates(false);
												}}
											/>
										</AS.FlexRow>
									</AS.Column>
									<AS.Column alignItems="center">
										<S.NumberInput
											placeholder="Max"
											value={maxZValue}
											onChange={e => {
												setMaxZValue(e.target.value);
												setApplyCoordinates(false);
											}}
										/>
									</AS.Column>
								</S.CoordinatesRow>
							</>
						)}
						<Spacer height={30} />
						<AS.FlexRow isCentered>
							<Button
								text="APPLY"
								style={{
									fontFamily: 'Helvetica Neue',
									height: '24px',
									padding: '20px 30px',
									color: applyCoordinates ? 'white' : '#1cbf7b',
								}}
								backgroundColor={applyCoordinates ? '#1cbf7b' : 'white'}
								width="164px"
								onClick={() => {
									if (
										(platform === 'decentraland' || platform === 'voxels' || platform === 'the-sandbox') &&
										((minXValue === '' && maxXValue !== '') ||
											(minXValue !== '' && maxXValue === '') ||
											(minYValue === '' && maxYValue !== '') ||
											(minYValue !== '' && maxYValue === ''))
									) {
										dispatch(
											setNotification({
												msg: 'Both key value pairs are required if one is entered!',
												alertType: 'error',
											})
										);
										return;
									} else if (
										platform === 'somnium-space' &&
										((minXValue === '' && maxXValue !== '') ||
											(minXValue !== '' && maxXValue === '') ||
											(minYValue === '' && maxYValue !== '') ||
											(minYValue !== '' && maxYValue === '') ||
											(minZValue === '' && maxZValue !== '') ||
											(minZValue !== '' && maxZValue === ''))
									) {
										dispatch(
											setNotification({
												msg: 'Both key value pairs are required if one is entered!',
												alertType: 'error',
											})
										);
										return;
									}
									setApplyCoordinates(true);
								}}
							/>
						</AS.FlexRow>
						<Spacer height={60} />
						{platform === 'voxels' ? (
							<>
								<AS.FlexRow isCentered>
									<AS.Text color="black" fontSize="18px" userSelectNone>
										location
									</AS.Text>
								</AS.FlexRow>
								<Spacer height={20} />
								<AS.Column style={{ gap: '20px' }}>
									<S.SelectRow>
										<Select
											styles={islandsSelectStyles}
											placeholder="Island"
											components={animatedComponents}
											options={islandsOptions}
											onChange={islandsSelectFunc}
											closeMenuOnSelect={true}
											value={islandsValue}
											onMenuOpen={() => setMenuOpen(true)}
											onMenuClose={() => setMenuOpen(false)}
											classNamePrefix="select"
											className="react-select"
											menuPortalTarget={document.body}
										/>
									</S.SelectRow>
									<S.SelectRow>
										<Select
											styles={suburbsSelectStyles}
											placeholder="Suburb"
											components={animatedComponents}
											options={suburbsOptions}
											onChange={suburbsSelectFunc}
											closeMenuOnSelect={true}
											value={suburbsValue}
											onMenuOpen={() => setSuburbsMenuOpen(true)}
											onMenuClose={() => setSuburbsMenuOpen(false)}
											classNamePrefix="select"
											className="react-select"
										/>{' '}
									</S.SelectRow>
								</AS.Column>
							</>
						) : platform === 'decentraland' ? (
							<>
								<AS.FlexRow isCentered>
									<AS.Text color="black" fontSize="18px" userSelectNone>
										highlights
									</AS.Text>
								</AS.FlexRow>
								<Spacer height={20} />
								<S.CloseToRow>
									<S.CloseToRowHeader isCentered>
										<AS.Text color="black" fontSize="18px" fontFamily="Helvetica Neue">
											Close To District
										</AS.Text>
									</S.CloseToRowHeader>
									<S.CloseToInputRow>
										<S.CloseToInput
											placeholder="Min"
											min="0"
											type="number"
											value={closeToDistrictMin}
											onChange={e => {
												setCloseToDistrictMin(e.target.value);
												setApplyCloseToDistrict(false);
											}}
										/>
										<AS.Text fontFamily="Helvetica Neue" color="black" fontSize="18px">
											to
										</AS.Text>
										<S.CloseToInput
											placeholder="Max"
											max="9"
											type="number"
											value={closeToDistrictMax}
											onChange={e => {
												setCloseToDistrictMax(e.target.value);
												setApplyCloseToDistrict(false);
											}}
										/>
									</S.CloseToInputRow>
								</S.CloseToRow>
								<Spacer height={20} />
								<AS.FlexRow isCentered>
									<Button
										text="APPLY"
										style={{
											fontFamily: 'Helvetica Neue',
											height: '24px',
											padding: '20px 30px',
											color: applyCloseToDistrict ? 'white' : '#1cbf7b',
										}}
										backgroundColor={applyCloseToDistrict ? '#1cbf7b' : 'white'}
										width="164px"
										onClick={() => {
											if (
												(closeToDistrictMin === '' && closeToDistrictMax !== '') ||
												(closeToDistrictMin !== '' && closeToDistrictMax === '')
											) {
												dispatch(
													setNotification({
														msg: 'Both key value pairs are required if one is entered!',
														alertType: 'error',
													})
												);
												return;
											}
											setApplyCloseToDistrict(true);
										}}
									/>
								</AS.FlexRow>
								<Spacer height={20} />
								<S.CloseToRow>
									<S.CloseToRowHeader isCentered>
										<AS.Text color="black" fontSize="18px" fontFamily="Helvetica Neue">
											Close To Road
										</AS.Text>
									</S.CloseToRowHeader>
									<S.CloseToInputRow>
										<S.CloseToInput
											placeholder="Min"
											min="0"
											type="number"
											value={closeToRoadMin}
											onChange={e => {
												setCloseToRoadMin(e.target.value);
												setApplyCloseToRoad(false);
											}}
										/>
										<AS.Text fontFamily="Helvetica Neue" color="black" fontSize="18px">
											to
										</AS.Text>
										<S.CloseToInput
											placeholder="Max"
											max="9"
											type="number"
											value={closeToRoadMax}
											onChange={e => {
												setCloseToRoadMax(e.target.value);
												setApplyCloseToRoad(false);
											}}
										/>
									</S.CloseToInputRow>
								</S.CloseToRow>
								<Spacer height={20} />
								<AS.FlexRow isCentered>
									<Button
										text="APPLY"
										style={{
											fontFamily: 'Helvetica Neue',
											height: '24px',
											padding: '20px 30px',
											color: applyCloseToRoad ? 'white' : '#1cbf7b',
										}}
										backgroundColor={applyCloseToRoad ? '#1cbf7b' : 'white'}
										width="164px"
										onClick={() => {
											if ((closeToRoadMin === '' && closeToRoadMax !== '') || (closeToRoadMin !== '' && closeToRoadMax === '')) {
												dispatch(
													setNotification({
														msg: 'Both key value pairs are required if one is entered!',
														alertType: 'error',
													})
												);
												return;
											}
											setApplyCloseToRoad(true);
										}}
									/>
								</AS.FlexRow>
								<Spacer height={20} />
								<S.CloseToRow>
									<S.CloseToRowHeader isCentered>
										<AS.Text color="black" fontSize="18px" fontFamily="Helvetica Neue">
											Close To Plaza
										</AS.Text>
									</S.CloseToRowHeader>
									<S.CloseToInputRow>
										<S.CloseToInput
											placeholder="Min"
											min="0"
											type="number"
											value={closeToPlazaMin}
											onChange={e => {
												setCloseToPlazaMin(e.target.value);
												setApplyCloseToPlaza(false);
											}}
										/>
										<AS.Text fontFamily="Helvetica Neue" color="black" fontSize="18px">
											to
										</AS.Text>
										<S.CloseToInput
											placeholder="Max"
											max="9"
											type="number"
											value={closeToPlazaMax}
											onChange={e => {
												setCloseToPlazaMax(e.target.value);
												setApplyCloseToPlaza(false);
											}}
										/>
									</S.CloseToInputRow>
									<AS.FlexRow isCentered>
										<Button
											text="APPLY"
											style={{
												fontFamily: 'Helvetica Neue',
												height: '24px',
												padding: '20px 30px',
												color: applyCloseToPlaza ? 'white' : '#1cbf7b',
											}}
											backgroundColor={applyCloseToPlaza ? '#1cbf7b' : 'white'}
											width="164px"
											onClick={() => {
												if ((closeToPlazaMin === '' && closeToPlazaMax !== '') || (closeToPlazaMin !== '' && closeToPlazaMax === '')) {
													dispatch(
														setNotification({
															msg: 'Both key value pairs are required if one is entered!',
															alertType: 'error',
														})
													);
													return;
												}
												setApplyCloseToPlaza(true);
											}}
										/>
									</AS.FlexRow>
								</S.CloseToRow>
							</>
						) : null}
						{platform === 'somnium-space' && (
							<>
								<Spacer height={60} />
								<AS.Column style={{ gap: '15px' }} alignItems="center">
									<S.CheckboxRow>
										{roadsideStatus === 'roadside' ? (
											<S.CheckboxCheckedDiv onClick={() => setRoadsideStatus('')}>
												<CheckboxChecked />
											</S.CheckboxCheckedDiv>
										) : (
											<S.CheckboxDiv onClick={() => setRoadsideStatus('roadside')}>
												<CheckboxUnchecked />
											</S.CheckboxDiv>
										)}
										<AS.Text fontSize="18px" color="black" userSelectNone>
											roadside
										</AS.Text>
									</S.CheckboxRow>
									<S.CheckboxRow>
										{waterfrontStatus === 'waterfront' ? (
											<S.CheckboxCheckedDiv onClick={() => setWaterfrontStatus('')}>
												<CheckboxChecked />
											</S.CheckboxCheckedDiv>
										) : (
											<S.CheckboxDiv onClick={() => setWaterfrontStatus('waterfront')}>
												<CheckboxUnchecked />
											</S.CheckboxDiv>
										)}
										<AS.Text fontSize="18px" color="black" userSelectNone>
											waterfront
										</AS.Text>
									</S.CheckboxRow>
								</AS.Column>
							</>
						)}
						<Spacer height={60} />
						<AS.FlexRow isCentered>
							<S.FiltersLogo src={MarketplaceLogo} />
						</AS.FlexRow>
					</S.FiltersContainer>
				)}
			</AnimatePresence>
			{!isInView && isMobileFiltersButtonVisible ? (
				<S.MobileFiltersButton
					openSideNav={openSideNav}
					text="Filter"
					onClick={() => {
						setOpenSideNav(true);
						setMobileFilters(true);
					}}
				/>
			) : null}
		</S.SideNav>
	);
};

export default FiltersSideNav;
