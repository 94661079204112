import styled from 'styled-components/macro';

export const ButtonSpinner = styled.div`
	display: inline-block;
	position: relative;
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 100%;
	div {
		border: 4px solid #fff;
		opacity: 1;
		border-radius: 50%;
		animation: ButtonSpinner 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
	}
	div:nth-child(2) {
		animation-delay: -0.5s;
	}
	@keyframes ButtonSpinner {
		0% {
			top: 36px;
			left: 36px;
			width: 0;
			height: 0;
			opacity: 1;
		}
		100% {
			top: 0px;
			left: 0px;
			width: 72px;
			height: 72px;
			opacity: 0;
		}
	}
`;
