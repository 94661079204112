import { useCallback, useEffect, useState } from 'react';

export const useIsInBreakpoint = (breakpoint = '991px') => {
	const [isInBreakpoint, setIsInBreakpoint] = useState(false);

	const updateTarget = useCallback(event => {
		if (event.matches) {
			setIsInBreakpoint(true);
		} else {
			setIsInBreakpoint(false);
		}
	}, []);

	useEffect(() => {
		const media = window.matchMedia(`(max-width: ${breakpoint})`);
		media.addEventListener('change', event => updateTarget(event));

		if (media.matches) {
			setIsInBreakpoint(true);
		}

		return () => media.removeEventListener('change', event => updateTarget(event));
	}, [breakpoint, updateTarget]);

	return isInBreakpoint;
};
