import ListingsDefaultAvatar from 'images/ListingsDefaultAvatar.webp';

import { createTokenId } from './createTokenId';
import { parsePlatformMetadata } from './parsePlatformMetadata';
import { platformAddrToName } from './validatedPlatforms';

const calculateUniqueSales = marketAuctions => {
	const hashmap = new Map();
	marketAuctions.forEach(auction => {
		const id = createTokenId(auction.token.nft, 0, auction.token.tokenId);
		let count = hashmap.get(id) ?? 0;
		if (!auction.isActive && auction.details.highestBid > 0) {
			count++;
		}
		hashmap.set(id, count);
	});
	return marketAuctions.map(auction => {
		const id = createTokenId(auction.token.nft, 0, auction.token.tokenId);
		auction = { ...auction, uniqueSales: hashmap.get(id) };
		return auction;
	});
};

export const parseMarketAuctions = async (marketAuctions, newTokensArray) => {
	marketAuctions = calculateUniqueSales(marketAuctions);
	const promisedTokens = marketAuctions.map(async auction => {
		const id = createTokenId(auction.token.nft, auction.token.salt, auction.token.tokenId);
		auction = { ...auction, id: id };
		const platformName = platformAddrToName(auction.token.nft);
		auction = { ...auction, platform: platformName };
		try {
			const metadataObj = newTokensArray.find(
				metadata => metadata.id === auction.token.tokenId.toString() && metadata.address === auction.token.nft.toLowerCase()
			);
			const metadata = metadataObj.metadata;
			const props = parsePlatformMetadata(metadata, platformName);
			auction = Object.assign({}, auction, props);
		} catch (error) {
			console.error(error);
			auction = {
				...auction,
				title: 'No Title Found',
				image: ListingsDefaultAvatar,
				description: 'No Description Found',
				coordinates: '?, ?',
			};
		}
		return auction;
	});
	return await Promise.all(promisedTokens);
};
