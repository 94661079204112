import { RefreshOutline } from '@styled-icons/evaicons-outline/RefreshOutline';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setNotification } from 'state/features/notificationSlice';

import { convertToETH } from 'utils/convertCurrencies';

import * as AS from 'styles/AppStyles';

import * as S from '../styles';
import TransactionCard from './TransactionCard';

const ProfileTransactions = ({ gridSelected, platformFilter, searchNFTsValue }) => {
	const dispatch = useDispatch();

	const [filteredNfts, setFilteredNfts] = useState([]);

	const allNFTs = useSelector(state => state.NFTs.value);

	// Wallet Pub Key

	const walletPubKey = useSelector(state => state.wallet.address);

	useEffect(() => {
		const newNftArray = (allNFTs || []).filter(nft => {
			const b1 = nft.details.isActive;
			const b2 = nft.details.endDate.toNumber() * 1000 < Date.now();
			const b3 = platformFilter === 'all' ? true : nft.platform === platformFilter;
			const b4 = searchNFTsValue === '' ? true : nft.title?.toLowerCase().includes(searchNFTsValue.toLowerCase());
			const b5 = nft.details.highestBid > 0;
			const p1 = nft.details.seller === walletPubKey;
			const p2 = nft.details.highestBidder === walletPubKey;
			return b1 && b2 && b3 && b4 && b5 && (p1 || p2);
		});
		setFilteredNfts(newNftArray);
	}, [platformFilter, allNFTs, searchNFTsValue]);

	return (
		<>
			<S.ListingsFoundDiv>
				<S.RefreshOutlineDiv
					onClick={() =>
						dispatch(
							setNotification({
								msg: 'We have a queued an update for you! Please check back later...',
								alertType: 'success',
							})
						)
					}
				>
					<RefreshOutline />
				</S.RefreshOutlineDiv>
				<AS.Text color="black" fontSize="18px" fontFamily="Helvetica Neue" userSelectNone>
					<span style={{ fontWeight: 'bold' }}>{filteredNfts.length}</span> Listings Found
				</AS.Text>
			</S.ListingsFoundDiv>
			<S.ListingsCardRow gridSelected={gridSelected}>
				{filteredNfts?.map((nft, index) => {
					return (
						<TransactionCard
							key={nft.id}
							title={nft.title}
							platform={nft.platform}
							image={nft.image}
							NFTData={nft}
							finalPrice={convertToETH(nft.details.highestBid.toString())}
							noHover={false}
							isSeller={nft.details.seller === walletPubKey}
							isBuyer={nft.details.highestBidder === walletPubKey}
						/>
					);
				})}
			</S.ListingsCardRow>
		</>
	);
};

export default ProfileTransactions;
