import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, Zoom, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { removeNotification } from 'state/features/notificationSlice';

const Notification = () => {
	const dispatch = useDispatch();

	const { alertTypes } = useSelector(state => state.notifications);

	useEffect(() => {
		if (alertTypes.alertError !== '' || alertTypes.alertSuccess !== '') {
			dispatch(removeNotification());
		}
	}, [alertTypes, dispatch]);

	if (alertTypes.alertError) {
		toast.error(`❌ ${alertTypes?.alertError}`, {
			position: 'bottom-right',
			autoClose: 4000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			toastId: 'error',
		});
	}
	if (alertTypes.alertSuccess) {
		toast.success(`✔️ ${alertTypes?.alertSuccess}`, {
			position: 'bottom-right',
			autoClose: 4000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			toastId: 'success',
		});
	}

	return (
		<ToastContainer
			transition={Zoom}
			position="bottom-right"
			autoClose={false}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnVisibilityChange={false}
			draggable
			pauseOnHover
		/>
	);
};

export default Notification;
