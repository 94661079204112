import { Share } from '@styled-icons/bootstrap/Share';
import { Reload } from '@styled-icons/ionicons-outline/Reload';
import { sendTokenMetadata } from 'actions/launchpad';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { EthereumIcon as EthereumIconDiv, Overlay } from 'pages/ListItem/styles';

import { Button } from 'components/Button/Button';
import { Input } from 'components/Input/Input';
import { StyledLoader } from 'components/Loader/StyledLoader';
import { Spacer } from 'components/Spacer/Spacer';

import { bidNFT, buyNowNFT } from 'state/features/NFTSlice';
import { setNotification } from 'state/features/notificationSlice';

import { convertToETH, convertToWei } from 'utils/convertCurrencies';

import * as AS from 'styles/AppStyles';

import EthereumIcon from 'images/Ethereum-icon.svg';

import Metadata from './Metadata';
import MoreFrom from './MoreFrom';
import NFTNotFound from './NFTNotFound';
import ShareModal from './ShareModal';
import * as S from './styles';

const Storefront = () => {
	let params = useParams();

	const dispatch = useDispatch();

	const allNFTs = useSelector(state => state.NFTs.value);

	const [bid, setBid] = useState('0');
	const [daysRemaining, setDaysRemaining] = useState(0);
	const [hoursRemaining, setHoursRemaining] = useState(0);
	const [minRemaining, setMinRemaining] = useState(0);
	const [secRemaining, setSecRemaining] = useState(0);
	const [isOpenModal, setIsOpenModal] = useState(false);

	// Find the NFT we need inside all NFTs

	const storefrontNFT = (allNFTs || []).find(nft => nft.id === params.id);

	useEffect(() => {
		const interval = setInterval(() => {
			const endDateSec = storefrontNFT?.details.endDate.toNumber();
			const nowSec = Date.now() / 1000;
			let seconds = Math.floor(endDateSec - nowSec);
			const daysLeft = Math.floor(seconds / (24 * 3600));
			setDaysRemaining(daysLeft < 0 ? 0 : daysLeft);
			seconds = Math.floor(seconds % (24 * 3600));
			const hoursLeft = Math.floor(seconds / 3600);
			setHoursRemaining(hoursLeft < 0 ? 0 : hoursLeft);
			seconds = Math.floor(seconds % 3600);
			const minutesLeft = Math.floor(seconds / 60);
			setMinRemaining(minutesLeft < 0 ? 0 : minutesLeft);
			const secondsLeft = Math.floor(seconds % 60);
			setSecRemaining(secondsLeft < 0 ? 0 : secondsLeft);
		}, 1000);
		return () => clearInterval(interval);
	}, [storefrontNFT?.details.endDate, secRemaining, minRemaining, hoursRemaining, daysRemaining]);

	// Pub key

	const walletPubKey = useSelector(state => state.wallet.address);

	// Get Loading State during Buy Now NFT

	const loadingState = useSelector(state => state.loading.loading);

	// useEffect to hide the body when the global spinner is active

	useEffect(() => {
		if (loadingState) {
			document.body.style.cssText = 'overflow-y: hidden !important;';
		} else {
			document.body.style.overflowY = '';
		}
	}, [loadingState]);

	// List NFT

	const buyNowNFTFunc = () => {
		if (!walletPubKey) {
			dispatch(
				setNotification({
					msg: 'A Wallet Must Be Connected!',
					alertType: 'error',
				})
			);
			return;
		}
		if (!storefrontNFT) {
			dispatch(
				setNotification({
					msg: 'An NFT Must Be Selected!',
					alertType: 'error',
				})
			);
			return;
		}
		dispatch(buyNowNFT({ storefrontNFT }));
	};

	const bidNFTFunc = () => {
		if (!walletPubKey) {
			dispatch(
				setNotification({
					msg: 'A Wallet Must Be Connected!',
					alertType: 'error',
				})
			);
			return;
		}
		if (!storefrontNFT) {
			dispatch(
				setNotification({
					msg: 'An NFT Must Be Selected!',
					alertType: 'error',
				})
			);
			return;
		}
		const bidWei = convertToWei(bid);
		dispatch(
			bidNFT({
				storefrontNFT,
				bidWei,
			})
		);
	};

	const reloadMetadataFunc = () => {
		sendTokenMetadata(storefrontNFT.token.nft, storefrontNFT.token.tokenId.toString());
		dispatch(
			setNotification({
				msg: "We've queued this item for an update! Check back in a minute...",
				alertType: 'success',
			})
		);
	};

	if (!(allNFTs || []).find(nft => nft.id === params.id)) {
		return <NFTNotFound />;
	}

	return (
		<>
			<S.Container>
				{loadingState && (
					<div style={{ overflowY: 'hidden' }}>
						<Overlay />
						<StyledLoader style={{ zIndex: 6 }} />
					</div>
				)}
				<S.MainRow>
					<S.NFTImage src={storefrontNFT.image} alt="NFT-example" />
					<S.MainColumn>
						<S.ShareNFTDiv>
							<S.ShareIcon onClick={() => setIsOpenModal(true)}>
								<Share style={{ width: '38%' }} />
							</S.ShareIcon>
							<S.ReloadIcon onClick={reloadMetadataFunc}>
								<Reload style={{ width: '38%' }} />
							</S.ReloadIcon>
						</S.ShareNFTDiv>
						<AS.FlexRow>
							<AS.Text fontSize="16px" userSelectNone>
								{/* Todo */}
								{storefrontNFT.platform}
							</AS.Text>
						</AS.FlexRow>
						<Spacer height={10} />
						<AS.FlexRow>
							<S.ParcelText>{storefrontNFT.title}</S.ParcelText>
						</AS.FlexRow>
						<Spacer height={10} />
						<AS.FlexRow>
							<AS.Text color="#8E8E8E" fontSize="18px" userSelectNone>
								owner:&nbsp;
							</AS.Text>
							<AS.Text color="black" fontSize="18px" fontFamily="Helvetica Neue">
								{storefrontNFT.details.seller}
							</AS.Text>
						</AS.FlexRow>
						<Spacer height={20} />
						<AS.FlexRow>
							<AS.Text color="black" fontSize="18px" userSelectNone>
								coordinates
							</AS.Text>
						</AS.FlexRow>
						<Spacer height={10} />
						<AS.FlexRow>
							<AS.Text color="black" fontSize="18px" fontFamily="Helvetica Neue">
								{storefrontNFT.coordinates}
							</AS.Text>
						</AS.FlexRow>
						<Spacer height={20} />
						<AS.FlexRow>
							<AS.Text color="black" fontSize="18px" userSelectNone>
								description
							</AS.Text>
						</AS.FlexRow>
						<Spacer height={10} />
						<S.DescriptionRow>
							<AS.Text color="black" fontSize="18px" fontFamily="Helvetica Neue">
								{storefrontNFT.description}
							</AS.Text>
						</S.DescriptionRow>
						<Spacer height={20} />
						<AS.FlexRow>
							<AS.Text color="black" fontSize="18px" userSelectNone>
								sale
							</AS.Text>
						</AS.FlexRow>
						<Spacer height={10} />
						<AS.FlexRow>
							<S.SaleContainer>
								<AS.FlexRow isCentered>
									<S.SaleText>
										{storefrontNFT.details.endDate.toNumber() < new Date().getTime() / 1000 ? (
											`Sale Ended: ${dayjs(storefrontNFT.details.endDate.toNumber() * 1000).format('ddd, MMM D, YYYY h:mm A')}`
										) : (
											<>
												Sale Ends: <br />
												{dayjs(storefrontNFT.details.endDate.toNumber() * 1000).format('ddd, MMM D, YYYY h:mm A')}
											</>
										)}
									</S.SaleText>
								</AS.FlexRow>
								<Spacer height={15} />
								<AS.FlexRow isCentered>
									<S.TimerCard>
										<S.TimerRow isCentered>
											<AS.Column alignItems="center">
												<AS.Text fontSize="24px">{daysRemaining}</AS.Text>
												<AS.Text fontSize="16px" color="black">
													days
												</AS.Text>
											</AS.Column>
											<AS.Column alignItems="center">
												{' '}
												<AS.Text fontSize="24px">{hoursRemaining}</AS.Text>
												<AS.Text fontSize="16px" color="black">
													hours
												</AS.Text>
											</AS.Column>
											<AS.Column alignItems="center">
												{' '}
												<AS.Text fontSize="24px">{minRemaining}</AS.Text>
												<AS.Text fontSize="16px" color="black">
													minutes
												</AS.Text>
											</AS.Column>
											<AS.Column alignItems="center">
												{' '}
												<AS.Text fontSize="24px">{secRemaining}</AS.Text>
												<AS.Text fontSize="16px" color="black">
													seconds
												</AS.Text>
											</AS.Column>
										</S.TimerRow>
									</S.TimerCard>
								</AS.FlexRow>
								<Spacer height={25} />
								{storefrontNFT.details.isAuctionAllowed && (
									<S.PriceRow>
										<AS.Column alignItems="center">
											<AS.Text color="black" fontSize="16px" userSelectNone>
												starting price
											</AS.Text>
											<Spacer height={10} />
											<AS.FlexRow isCentered style={{ gap: '10px' }}>
												<EthereumIconDiv src={EthereumIcon} alt="Ethereum Icon" />
												<AS.Text color="black" fontSize="18px" fontFamily="Helvetica Neue" userSelectNone bold>
													{convertToETH(storefrontNFT.details.startingPrice.toString())}
												</AS.Text>
											</AS.FlexRow>
										</AS.Column>
										<AS.Column alignItems="center">
											<div>
												<AS.Text color="black" fontSize="16px" userSelectNone>
													highest bid
												</AS.Text>
												<Spacer height={10} />
												<AS.FlexRow isCentered style={{ gap: '10px' }}>
													<EthereumIconDiv src={EthereumIcon} alt="Ethereum Icon" />
													<AS.Text color="black" fontSize="18px" fontFamily="Helvetica Neue" userSelectNone bold>
														{convertToETH(storefrontNFT.details.highestBid.toString())}
													</AS.Text>
												</AS.FlexRow>
											</div>
											<Spacer height={15} />
											<AS.FlexRow isCentered style={{ gap: '20px' }}>
												<Input value={bid} onChange={e => setBid(e.target.value)} type="number" min="0" inputStyle={{ width: '100px' }} />
												<Button text="bid" style={{ height: '40px' }} onClick={bidNFTFunc} />
											</AS.FlexRow>
										</AS.Column>
									</S.PriceRow>
								)}

								<Spacer height={30} />
								{storefrontNFT.details.isBuyNowAllowed && (
									<S.BuyNowRow>
										<AS.Column alignItems="center" style={{ justifyContent: 'space-between', gap: '10px' }}>
											<AS.Text color="black" fontSize="16px" userSelectNone>
												buy now
											</AS.Text>
											<AS.FlexRow isCentered style={{ gap: '10px' }}>
												<EthereumIconDiv src={EthereumIcon} alt="Ethereum-icon" />
												<AS.Text color="black" fontSize="18px" fontFamily="Helvetica Neue" userSelectNone bold>
													{convertToETH(storefrontNFT.details.buyNowPrice.toString())}
												</AS.Text>
											</AS.FlexRow>
											<Button style={{ height: '40px' }} text="buy now" width="170px" onClick={buyNowNFTFunc} />
										</AS.Column>
									</S.BuyNowRow>
								)}
							</S.SaleContainer>
						</AS.FlexRow>
					</S.MainColumn>
				</S.MainRow>
				<Spacer height={70} />
				<Metadata storefrontNFT={storefrontNFT} />
				<Spacer height={60} />
				<S.MoreFromHeader>
					<AS.Text color="black" fontSize="36px" userSelectNone>
						more from
					</AS.Text>
					<AS.Text fontSize="36px" userSelectNone>
						{storefrontNFT.platform}
					</AS.Text>
				</S.MoreFromHeader>
				<Spacer height={30} />
				{/* Memoize More From to prevent rerender on every second of the countdown */}
				<MoreFrom allNFTs={allNFTs} storefrontNFT={storefrontNFT} />
				<Spacer height={40} />
				<S.ViewMoreButtonRow>
					<Button text="view more" backgroundColor="white" style={{ color: '#1cbf7b', height: '50px' }} width="184px" />
				</S.ViewMoreButtonRow>
			</S.Container>
			<ShareModal isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} />
		</>
	);
};

export default Storefront;
