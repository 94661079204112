export const chooseRandomElement = (data, n) => {
	n = data.length < n ? data.length : n;
	let s = new Set();
	while (s.size < n) {
		const randIndex = Math.floor(Math.random() * data.length);
		s.add(data[randIndex]);
	}
	let dataRand = [];
	s.forEach(value => {
		dataRand.push(value);
	});
	return dataRand;
};
