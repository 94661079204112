import styled, { css } from 'styled-components/macro';

export const Card = styled.div`
	min-width: 100%;
	background: #fcfcfc;
	border: 1px solid #dbdbdb;
	box-shadow: none;
	border-radius: 10px;
	padding: 15px;
	transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
	${({ noHover }) =>
		noHover
			? css`
					&:hover {
						cursor: auto;
						box-shadow: none;
					}
			  `
			: css`
					&:hover {
						cursor: pointer;
						box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
					}
			  `}
`;
