export const createTokenMetadataBody = tokens => {
	const tokenDetails = tokens.map(token => {
		return token.token;
	});

	try {
		let newTokensArray = [];

		tokenDetails.forEach(token => {
			if (newTokensArray.find(item => item.address === token.nft)) {
				const newTokenFound = newTokensArray.find(item => item.address === token.nft);
				newTokenFound.ids.push(token.tokenId.toString());
			} else {
				newTokensArray.push({
					address: token.nft,
					ids: [token.tokenId.toString()],
				});
			}
		});
		return newTokensArray;
	} catch (error) {
		console.error(error);
	}
};
