import logoIcon from 'images/logo-icon.webp';

const userSafety = [
	{
		title: 'Only Use TERRENA’s Official Links.',
		content: (
			<>
				<p>
					Use only the links on our official website, trust only emails that are sent from our official email addresses, and never connect
					with or follow fraudulent social media accounts purporting to be from TERRENA. Our official website contains links to our only
					social media accounts.
				</p>

				<p>
					We will only offer assistance and information to our users via our official customer support on social media channels like
					Discord, Twitter, and so on.
				</p>
			</>
		),
	},
	{
		title: `Utilize Your Wallet's Credentials Cautiously.`,
		content: (
			<>
				<p>
					Never divulge your private wallet information to anybody, especially online, where a lot of scammers are constantly looking for
					new methods to take advantage of unsuspecting victims. Even with people you trust, you should never disclose your secret wallet
					recovery phrase.
				</p>

				<p>
					The secret recovery phrase for your wallet will never, ever, be requested by TERRENA or its customer care representatives. Never
					trust anybody who claims to be from TERRENA.
				</p>
			</>
		),
	},
	{
		title: 'Avoid Fraudulent Offers at All Costs.',
		content: (
			<>
				<p>
					Some offers in the crypto and NFT space are too good to be true. Don't make it easy for fraudsters to take advantage of you by
					enticing you with a variety of attractive offers. Always do thorough research before attempting to carry out any request made by
					an unknown user.
				</p>
				<p>
					There are people that want to take advantage of those who are fresh to the NFT industry. If you receive an offer that appears to
					be too good to be true, implementing simple security precautions will keep you one step ahead in your ventures.
				</p>
			</>
		),
	},
	{
		title: 'Be Cautious Of Email Phishing Scams.',
		content: (
			<>
				<p>
					Be wary of phishing emails coming from addresses pretending to be TERRENA. We will only send you emails with addresses ending in
					our official domain. Please do not respond to any email pretending to be from TERRENA that does not originate from our official
					email address. Avoid being tricked by an attacker who might send a fake email in an attempt to get you to give private information
					like your web3 wallet.
				</p>
				<p>Similarly, under no circumstances should you download any files or click on any links from sources you are unfamiliar with.</p>
			</>
		),
	},
	{
		title: 'Avoid Installing Unknown Software or Apps on Your Device',
		content: (
			<>
				<p>
					There are tons of counterfeit web3 apps on the internet. As appropriate, be sure to download your wallets directly from the
					provider's website, Playstore, or App store.
				</p>
				<p>
					Ensure you're downloading the correct app and not a fake by reading the reviews and developer information before you download. It
					won't hurt to ask the provider for clarification if you're confused.
				</p>
			</>
		),
	},
	{
		title: 'Use Only Reputable Web3 Wallet Applications and Browser Extensions',
		content: (
			<>
				<p>
					Make sure you conduct thorough research on any web3 wallet you want to use to store, transmit, or receive your NFT and crypto
					tokens. Almost every day, there are several new alleged wallets that are debugged. So, you need to pick your wallets carefully and
					wisely.
				</p>
				<p>
					In Web3, several types of wallets exist within the space. Each has advantageous use cases and might be helpful depending on how
					you plan to manage your records and funds. It's advisable to experiment and determine which sort of wallet suits you the most.
				</p>
			</>
		),
	},
	{
		title: 'Never Click on Any Unidentified Link.',
		content: (
			<>
				<p>
					Be cautious while visiting websites and chatting with people on Discord or social media. At all costs, avoid clicking on
					advertising, pictures, or links sent to you by unknown people. Users can be tricked into downloading malicious software by
					suspicious links, which fraudsters can then exploit to control the device, steal sensitive data from the web3 wallet, or spy on
					the user.
				</p>
				<p>
					Cybercriminals get access to their victims' computers using ransomware, encrypt their data and personal files, and then ask for
					payment.
				</p>
			</>
		),
	},
	{
		title: 'Pay Maximum Attention While Approving Smart Contracts With Your Web3 Wallet',
		content: (
			<p>
				Smart contracts enable irreversible transactions. Any contact that asks you to validate sensitive information, especially from your
				web3 crypto wallet, should raise your suspicions. When purchasing or selling NFTs, double-check the URL of the website you are
				linking your web3 wallets to. Numerous unscrupulous websites have the ability to drain users' cryptocurrency wallets from all of
				their assets.
			</p>
		),
	},
	{
		title: 'Additional Safety Measures Should Be Taken',
		content: (
			<>
				<p>
					You may also take additional security precautions such as employing 2-factor authentication or Google authenticator, as well as a
					hardware wallet like Trezor or Ledger instead of the regular web-based web3 wallets.
				</p>
				<p>
					Cryptocurrency scams and hacks happen in various forms. Some hackers steal money straight from wallets by breaching in, while
					others make likable offers to defraud you of your coins, and yet others are so cunning that you hardly ever even detect yourdevice
					has been compromised. Be cautious at all times.
				</p>
				<p>Our customer support representatives are always accessible to help with any queries, further questions, or assistance.</p>
			</>
		),
	},
];

export const UserSafety = () => {
	return (
		<section className="user-safety">
			<div className="banner">
				<img src={logoIcon} alt="Logo" />
				<h1>User Safety</h1>
			</div>
			<div className="main-content">
				<p>
					With NFT scams getting more challenging, it is now easier than ever for anybody to fall victim to them. As a result, it is
					critical that you exercise extreme caution in knowing how to conduct yourself and protect yourself from phishing and dangerous
					links anytime you are participating in an NFT transaction and utilizing your web3 wallets.
				</p>
				<p>The following are the simple methods of safeguarding yourself and your assets from getting lost;</p>
				<hr />
				<ol className="content-list">
					{userSafety.map(({ title, content }, index) => (
						<li key={title}>
							<strong>
								{index + 1}. {title}
							</strong>
							<div className="content">{content}</div>
						</li>
					))}
				</ol>
			</div>
		</section>
	);
};
