import { useState } from 'react';
import { useSelector } from 'react-redux';

import Marketplace from 'pages/Marketplace/Marketplace';

import { Spacer } from 'components/Spacer/Spacer';
import WalletConnect from 'components/WalletConnect/WalletConnect';

import { cropAddress } from 'utils/cropAddress';

import * as AS from 'styles/AppStyles';

import * as S from './styles';

const Profile = () => {
	const [showEditAvatarIcon, setShowEditAvatarIcon] = useState(false);
	const [filtersText, setFiltersText] = useState('owned');

	// Wallet Pub Key

	const walletPubKey = useSelector(state => state.wallet.address);

	if (!walletPubKey) {
		return <WalletConnect />;
	}

	// Calculate image for the avatar

	const indexFromAddress = (address, range) => {
		if (range > 255) throw Error('range > 255');
		return parseInt(address.slice(0, 4)) % range;
	};

	// Calculate random color for the banner

	const colorFromAddress = address => {
		let startIndex = 2;
		let endIndex = 4;
		let offset = 0;
		let color = [];
		for (let c = 0; c < 3; c++) {
			let s = address.slice(startIndex + offset, endIndex + offset);
			color.push(parseInt('0x' + s));
			offset += 2;
		}
		return `rgb(${color[0]}, ${color[1]}, ${color[2]}, 0.7)`;
	};

	return (
		<>
			<S.Banner style={{ backgroundColor: colorFromAddress(walletPubKey) }}>
				<S.AvatarDiv>
					<img
						src={require(`../../images/creative-commons/${indexFromAddress(walletPubKey, 5)}.jpg`)}
						alt="avatar"
						style={{ width: '100%', height: '100%', borderRadius: '50%' }}
					/>
				</S.AvatarDiv>
			</S.Banner>
			<S.ProfileSpacer />
			<S.ProfileContainer>
				<S.NameRow>
					<AS.Text color="black" fontSize="23px">
						profile
					</AS.Text>
				</S.NameRow>
				<S.ProfileSpacer2 />
				<S.WalletRow>
					<S.WalletDiv>{cropAddress(walletPubKey.toString())}</S.WalletDiv>
				</S.WalletRow>
				<Spacer height={70} />
				<AS.FlexRow isCentered>
					<S.NFTFiltersDiv>
						<S.NFTFiltersText color="black" fontSize="18px" onClick={() => setFiltersText('owned')} filtered={filtersText === 'owned'}>
							owned
						</S.NFTFiltersText>
						<S.NFTFiltersText color="black" fontSize="18px" onClick={() => setFiltersText('forSale')} filtered={filtersText === 'forSale'}>
							for sale
						</S.NFTFiltersText>
						<S.NFTFiltersText
							color="black"
							fontSize="18px"
							onClick={() => setFiltersText('watchlist')}
							filtered={filtersText === 'watchlist'}
						>
							watchlist
						</S.NFTFiltersText>
						<S.NFTFiltersText
							color="black"
							fontSize="18px"
							onClick={() => setFiltersText('transactions')}
							filtered={filtersText === 'transactions'}
						>
							transactions
						</S.NFTFiltersText>
					</S.NFTFiltersDiv>
				</AS.FlexRow>
			</S.ProfileContainer>
			<Spacer height={35} />
			<S.Divider />
			<Marketplace profileSection={filtersText} />
		</>
	);
};

export default Profile;
