import { Discord, LinkedinSquare, Twitter } from '@styled-icons/boxicons-logos';

export const navigationSectionTitle = {
	marketplace: 'Marketplace',
	myAccount: 'My Account',
	resourceCenter: 'Resource Center',
};

export const navigationLinks = {
	marketplace: [
		{ title: 'All', to: '/marketplace/all' },
		{ title: 'Decentraland', to: '/marketplace/decentraland' },
		{ title: 'The Sandbox', to: '/marketplace/the-sandbox' },
		{ title: 'Voxels', to: '/marketplace/voxels' },
		{ title: 'Somnium Space', to: '/marketplace/somnium-space' },
	],
	myAccount: [
		{ title: 'Connect Wallet', to: '/wallet-connect' },
		{ title: 'List My Land', to: '/list-item' },
		{ title: 'Unsold NFT', to: '/unsold-nft' },
		{ title: 'My Profile', to: '/profile' },
	],
	resourceCenter: [
		// { title: 'Getting Started', to: '/getting-started' },
		{ title: 'FAQ', to: '/faq' },
		// { title: 'About Us', to: '/about-us' },
		{ title: 'User Safety', to: '/user-safety' },
		{ title: 'Support', to: '/support' },
	],
};

export const communityLinks = [
	{ title: 'Twitter', icon: <Twitter />, href: 'https://twitter.com/terrenaio?s=11&t=BLLO2Ylg9GhygjL6ThvVSQ' },
	{ title: 'Discord', icon: <Discord />, href: 'https://discord.gg/D2MYtfa6Uh' },
	{ title: 'LinkedIn', icon: <LinkedinSquare />, href: 'https://www.linkedin.com/company/terrenaio/' },
];
