import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ListingsCard from 'pages/Marketplace/ListingsCard';

import { Button } from 'components/Button/Button';

import * as AS from 'styles/AppStyles';

import * as S from './styles';

const FeaturedListings = () => {
	const navigate = useNavigate();
	const getAllTokens = useSelector(state => state.NFTs.value);
	const filteredTokens = getAllTokens.filter(({ details }) => details.isActive && details.endDate.toNumber() * 1000 > Date.now());

	return (
		<S.ListingsContainer>
			<S.FeaturedListingHeader>
				<AS.Text fontSize="36px" userSelectNone>
					featured&nbsp;
				</AS.Text>
				<AS.Text fontSize="36px" color="black" userSelectNone>
					listings
				</AS.Text>
			</S.FeaturedListingHeader>
			<S.FeaturedListingSpacer />
			<S.FeaturedListingColumn>
				{filteredTokens.map(nft => (
					<ListingsCard key={nft.id} noHover={false} nft={nft} />
				))}
			</S.FeaturedListingColumn>
			<S.FeaturedListingSpacer />
			<S.FeaturedListingButtonRow>
				<Button
					style={{ color: '#1cbf7b', width: '185px' }}
					backgroundColor="white"
					text="view more"
					onClick={() => navigate(`/marketplace/all`)}
				/>
			</S.FeaturedListingButtonRow>
		</S.ListingsContainer>
	);
};

export default FeaturedListings;
