import { Xmark } from '@styled-icons/fa-solid/Xmark';
import { device } from 'device-dimensions';
import styled, { css } from 'styled-components';

import { Button } from 'components/Button/Button';
import { Input } from 'components/Input/styles';

import * as AS from 'styles/AppStyles';

export const ArrowRightIcon = styled.div`
	position: absolute;
	top: 70px;
	right: -25px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
	border-radius: 50%;
	border: 1px solid #dbdbdb;
	width: 50px;
	height: 50px;
	color: #1cbf7b;
	transition: color 0.3s ease-in-out, border 0.3s ease-in-out, background-color 0.3s ease-in-out, transform 0.1s ease-in-out;
	background-color: #ffffff;
	&:hover {
		cursor: pointer;
		color: white;
		border: 1px solid #1cbf7b;
		background-color: #1cbf7b;
	}
	${({ openSideNav }) =>
		openSideNav &&
		css`
			transform: rotateZ(180deg);
		`}
	@media ${device.mobileL} {
		display: none;
	}
`;

export const FiltersContainer = styled.div`
	display: block;
	@media ${device.mobileL} {
		display: block;
		align-items: center;
		justify-content: center;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		padding: 15px 15px 35px 15px;
		background-color: white;
		overflow-y: scroll;
		overflow-x: hidden;
		z-index: 2;
	}
`;

export const MobileFiltersButton = styled(Button)`
	display: none;
	@media ${device.mobileL} {
		display: flex;
		width: 75%;
		height: 48px;
		background-color: #1cbf7b;
		color: white;
		font-size: 16px;
		box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
		position: fixed;
		bottom: 30px;
		z-index: 5;
		${({ openSideNav }) =>
			openSideNav &&
			css`
				display: none;
			`}
	}
`;

export const SideNav = styled.div`
	position: relative;
	padding: 50px 30px;
	background-color: #ffffff;
	border-right: 1px solid #dbdbdb;
	transition: all 0.4s cubic-bezier(0, 0.55, 0.45, 1);
	${({ openSideNav }) =>
		openSideNav
			? css`
					width: 30%;
					&:hover {
						cursor: auto;
					}
			  `
			: css`
					width: 54px;
					&:hover {
						cursor: pointer;
						background-color: #1cbf7b;
						border-right: 1px solid #1cbf7b;
						${ArrowRightIcon} {
							color: white;
							border: 1px solid #1cbf7b;
							background-color: #1cbf7b;
						}
					}
			  `}
	@media ${device.mobileL} {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		bottom: 20px;
		width: 100%;
		padding: 0;
		background-color: transparent !important;
		border: none;
		${({ openSideNav }) =>
			openSideNav &&
			css`
				top: 0;
				bottom: 0;
			`}
	}
`;

export const ClearAllButton = styled(Button)`
	background-color: white;
	color: rgb(28, 191, 123);
	height: 20px;
	width: 165px;
	padding: 20px;
`;

export const StatusButton = styled(Button)`
	background-color: white;
	color: #8e8e8e;
	border: 1px solid #8e8e8e;
	font-size: 16px;
	font-family: Helvetica Neue, sans-serif;
	height: 24px;
	width: 145px;
	padding: 20px 30px;
`;

export const NumberInput = styled(Input)`
	background-color: white;
	color: #8e8e8e;
	font-size: 16px;
	font-family: Helvetica Neue, sans-serif;
	height: 24px;
	border-radius: 40px;
	padding: 20px 30px;
	border: 1px solid #8e8e8e;
	transition: all 0.3s ease-in-out;
	width: 180px;
	text-align: center;
	&::placeholder {
		color: #8e8e8e;
	}
`;

export const StatusRow = styled(AS.FlexRow)`
	@media (max-width: 1280px) {
		flex-direction: column;
	}
`;

export const StatusColumn = styled(AS.Column)`
	gap: 15px;
	@media (max-width: 1280px) {
		margin-bottom: 15px;
	}
`;

export const PriceRow = styled(AS.FlexRow)`
	@media (max-width: 1493px) {
		flex-direction: column;
		gap: 10px;
	}
`;

export const TypeRow = styled(AS.FlexRow)`
	${({ platform }) =>
		platform &&
		css`
			${StatusButton} {
				width: 50px;
			}
		`}
`;

export const CoordinatesRow = styled(AS.FlexRow)`
	@media (max-width: 1493px) {
		flex-direction: column;
		gap: 20px;
	}
`;

export const CloseToRow = styled(AS.FlexRow)`
	flex-direction: column;
	gap: 20px;
`;

export const CloseToRowHeader = styled(AS.FlexRow)``;

export const CloseToInputRow = styled(AS.FlexRow)`
	justify-content: space-around;
	@media (max-width: 1493px) {
		flex-direction: column;
		gap: 10px;
	}
`;

export const CloseToInput = styled(NumberInput)``;

export const NumberInputMobile = styled(NumberInput)`
	@media (max-width: 1493px) {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
	}
`;

export const FiltersLogo = styled.img`
	width: 15%;
	user-select: none;
`;

export const CheckboxDiv = styled.div`
	color: #dbdbdb;
	width: 22px;
	transition: all 0.5s ease-in-out;
	&:hover {
		cursor: pointer;
		color: #b4b4b4;
	}
`;

export const CheckboxCheckedDiv = styled.div`
	color: #1cbf7b;
	width: 22px;
	transition: all 0.5s ease-in-out;
	&:hover {
		cursor: pointer;
		color: #1aad70;
	}
`;

export const CheckboxRow = styled.div`
	display: grid;
	grid-template-columns: 40px calc(100% - 40px);
	width: 200px;
`;

export const TimesIconDiv = styled.div`
	display: none;
	@media ${device.mobileL} {
		display: flex;
		justify-content: flex-end;
		z-index: 55;
		width: 100%;
		align-items: center;
		justify-content: flex-end;
		padding-right: 5px;
	}
`;

export const TimesIcon = styled(Xmark)`
	width: 21px;
`;

export const SelectRow = styled(AS.FlexRow)`
	justify-content: center;
	& > .react-select {
		& .select__input {
			color: transparent !important;
		}
	}
`;
