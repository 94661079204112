import * as AS from 'styles/AppStyles';

export const Error = () => {
	return (
		<AS.FlexRow isCentered>
			<AS.Text color="black" fontSize="23px">
				oops! an error has occured! please refresh the page!
			</AS.Text>
		</AS.FlexRow>
	);
};
