import { useNavigate } from 'react-router-dom';

import { Container } from 'pages/Storefront/styles';

import { Button } from 'components/Button/Button';
import { Spacer } from 'components/Spacer/Spacer';

import * as AS from 'styles/AppStyles';

export const NotFound = () => {
	const navigate = useNavigate();

	return (
		<Container>
			<AS.Text color="black" fontSize="25px">
				404! page not found!
			</AS.Text>
			<Spacer height={30} />
			<Button width="350px" text="return to the homepage" onClick={() => navigate('/')} />
		</Container>
	);
};
