/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable no-unused-vars */
import bigInt from 'big-integer';
import Erc721Contract from 'contractABIs/erc721.json';
import * as dayjs from 'dayjs';
import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { Divider } from 'pages/Marketplace/styles';

import { Button } from 'components/Button/Button';
import { Card } from 'components/Card/Card';
import { DropdownRow } from 'components/Header/styles';
import { StyledLoader } from 'components/Loader/StyledLoader';
import { Spacer } from 'components/Spacer/styles';
import WalletConnect from 'components/WalletConnect/WalletConnect';

import { clearSellTrx, clearUnfoundNFT, getRoyaltyFees, sellNFT } from 'state/features/NFTSlice';
import { setNotification } from 'state/features/notificationSlice';

import { ETHProviderWithWallet } from 'utils/ETHProvider';
import { convertToWei } from 'utils/convertCurrencies';
import { findImageUrlInMetadata } from 'utils/findImageUrlInMetadata';
import { nowPlus } from 'utils/nowPlus';
import { safeIpfsUrl } from 'utils/safeIpfsUrl';

import * as AS from 'styles/AppStyles';

import EthereumIcon from 'images/Ethereum-icon.svg';
import ListingsDefaultAvatar from 'images/ListingsDefaultAvatar.webp';

import CantFindNFTModal from './CantFindNFTModal';
import * as S from './styles';

const time = new Date();
time.setHours(0, 0, 0, 0);

const ListItem = () => {
	let params = useParams();
	const dispatch = useDispatch();
	const animatedComponents = makeAnimated();
	const navigate = useNavigate();

	const [openCustomDatePicker, setOpenCustomDatePicker] = useState(false);
	// Used for the calendar
	const [startingDate, setStartingDate] = useState(time);
	const [endingDate, setEndingDate] = useState(time);
	const [startDateHours, setStartDateHours] = useState('0');
	const [startDateMinutes, setStartDateMinutes] = useState('0');
	const [endDateHours, setEndDateHours] = useState('0');
	const [endDateMinutes, setEndDateMinutes] = useState('0');
	const [startDateSelectedClock, setStartDateSelectedClock] = useState({
		value: 'am',
		label: 'AM',
	});
	const [endDateSelectedClock, setEndDateSelectedClock] = useState({
		value: 'am',
		label: 'AM',
	});
	const [startClockMenuOpen, setStartClockMenuOpen] = useState(false);
	const [endClockMenuOpen, setEndClockMenuOpen] = useState(false);
	const [NFTMenuOpen, setNFTMenuOpen] = useState(false);
	const [selectedNFT, setSelectedNFT] = useState(null);
	const [loading, setLoading] = useState(false);
	const [NFTStartDate, setNFTStartDate] = useState(time);
	const [NFTEndDate, setNFTEndDate] = useState(time);
	const [selectedDurationButton, setSelectedDurationButton] = useState('');
	const [buyNowPrice, setBuyNowPrice] = useState();
	const [startingPrice, setStartingPrice] = useState();
	const [erc721Contract, setErc721Contract] = useState(null);
	const [royaltyFee, setRoyaltyFee] = useState(null);
	const [isOpenModal, setIsOpenModal] = useState(false);

	// Media query for React for the mobile SideNav animation
	const isMobile = useMediaQuery({ query: '(max-width: 425px)' });
	// Get User's Wallet
	const walletPubKey = useSelector(state => state.wallet.address);
	// Get Loading State during List NFT
	const loadingState = useSelector(state => state.loading.loading);
	// Get Fee Percent from Global Store
	const feePercent = useSelector(state => state.NFTs.feePercent);
	// Get Royalty Fee From Global Store
	const royaltyPercents = useSelector(state => state.NFTs.platformRoyaltyFees);

	useEffect(() => {
		if (selectedNFT) {
			if (unfoundNFT) {
				const { fees } = royaltyPercents.find(fee => fee.platform === unfoundNFT.platform);
				setRoyaltyFee(fees);
			} else {
				const { fees } = royaltyPercents.find(fee => fee.platform === userTokens?.ownedNfts[selectedNFT?.value].platform);
				setRoyaltyFee(fees);
			}
		}
	}, [selectedNFT]);

	// Get Alchemy NFTs from Global Store
	const userTokens = useSelector(state => state.NFTs.alchemyNFTs);
	// Get Unfound NFT from Global Store
	const unfoundNFT = useSelector(state => state.NFTs.unfoundNFT);
	// Get All NFTs
	const allNFTs = useSelector(state => state.NFTs.value);
	// Get Sold NFT Transaction
	const soldNFTTrx = useSelector(state => state.NFTs.sellTrx);

	useEffect(() => {
		if (soldNFTTrx) {
			dispatch(clearSellTrx());

			navigate(`/NFT/${allNFTs[allNFTs.length - 1].id}`); // fix this
		}
	}, [soldNFTTrx]);

	// useEffect to set the unfound NFT into the local state of the list item

	useEffect(() => {
		if (unfoundNFT) {
			setSelectedNFT({
				value: 0,
				label: (
					<div>
						<AS.FlexRow isCentered>
							<S.SelectFont>
								NFT Title: <span style={{ fontWeight: 'bold' }}>{unfoundNFT.title}</span>
							</S.SelectFont>
						</AS.FlexRow>
						<Spacer height={5} />
						<AS.FlexRow>
							<S.SelectFont>
								Platform: <span style={{ fontStyle: 'italic' }}>{unfoundNFT.platform}</span>
							</S.SelectFont>
						</AS.FlexRow>
						<Spacer height={5} />
						<AS.FlexRow>
							<S.SelectFont>Token ID: {unfoundNFT.tokenId}</S.SelectFont>
						</AS.FlexRow>
					</div>
				),
			});
			setIsOpenModal(false);
		}
	}, [unfoundNFT]);

	// useEffect to hide the body when the global spinner is active

	useEffect(() => {
		if (loadingState) {
			document.body.style.cssText = 'overflow-y: hidden !important;';
		} else {
			document.body.style.overflowY = '';
		}
	}, [loadingState]);

	// Loading ERC721 contract

	useEffect(() => {
		if (walletPubKey && selectedNFT) {
			const provider = ETHProviderWithWallet();
			if (unfoundNFT) {
				const erc721Contract = new ethers.Contract(unfoundNFT.address, Erc721Contract.abi, provider);
				const signer = provider.getSigner();
				const erc721ContractWithSigner = erc721Contract.connect(signer);
				setErc721Contract(erc721ContractWithSigner);
			} else {
				const erc721Contract = new ethers.Contract(userTokens.ownedNfts[selectedNFT?.value].contract.address, Erc721Contract.abi, provider);
				const signer = provider.getSigner();
				const erc721ContractWithSigner = erc721Contract.connect(signer);
				setErc721Contract(erc721ContractWithSigner);
			}
		}
	}, [selectedNFT, walletPubKey]);

	// Handle change of start date

	const handleStartDateChange = date => {
		setStartingDate(date);
	};

	// Handle change of end date

	const handleEndDateChange = date => {
		setEndingDate(date);
	};

	// React Select Styles for NFT Dropdown

	const NFTSelectStyles = {
		control: base => ({
			...base,
			backgroundColor: 'transparent',
			boxShadow: 'none',
			fontSize: '18px',
			padding: '5px',
			cursor: 'pointer',
			display: 'flex',
			overflow: 'hidden',
			position: 'relative',
			zIndex: 4,
			lineHeight: 1,
			transition: 'all 0.3s ease-in-out',
			alignItems: 'center',
			userSelect: 'none',
			border: 'none',
		}),
		menuPortal: base => ({ ...base, zIndex: 9999 }),
		valueContainer: (styles, { data, isDisabled, isFocused, isSelected }) => {
			return {
				...styles,
				color: 'white',
				padding: 0,
			};
		},
		placeholder: base => ({
			...base,
			color: 'black',
			marginRight: '10px',
			fontFamily: 'Helvetica Neue',
			fontSize: '18px',
			textAlign: 'center',
			zIndex: 5,
			transition: 'all 0.3s ease-in-out',
		}),
		indicatorSeparator: () => ({ display: 'none' }),
		dropdownIndicator: base => ({
			...base,
			color: 'black',
			padding: '2px 2px 2px 0',
			transition: 'all 0.3s ease-in-out',
			fontSize: '16px',
			transform: NFTMenuOpen ? 'rotate(180deg)' : null,
		}),
		option: styles => {
			return {
				...styles,
				position: 'relative',
				backgroundColor: 'white',
				color: 'black',
				padding: '20px 15px',
				cursor: 'pointer',
				transition: 'all 0.3s ease-in-out',
				height: '100%',
				'&:hover': {
					backgroundColor: '#E8F9F2',
				},
			};
		},
		menu: base => ({
			...base,
			width: isMobile ? '300px' : '400px',
		}),
		menuList: base => ({
			...base,
			width: '100%',
			height: '100%',
			maxHeight: '300px',
			paddingTop: 0,
			paddingBottom: 0,
			zIndex: 3,
			boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.12)',
			borderRadius: '8px',
			overflowX: 'hidden',
			'  &::-webkit-scrollbar-track': {
				backgroundColor: 'whitesmoke',
				borderRadius: '7px',
				marginRight: '2px',

				'&:hover': {
					backgroundColor: 'whitesmoke',
				},
			},
			'&::-webkit-scrollbar': {
				width: '6px',
				backgroundColor: 'whitesmoke',
				'&:hover': { backgroundColor: 'whitesmoke' },
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: '#8e8e8e',
				borderRadius: '7px',
				transition: 'all 0.3s ease-in-out',
				'&:hover': { backgroundColor: '#757575', cursor: 'pointer !important' },
			},
		}),
	};

	const NFTOptions = userTokens?.ownedNfts?.map((token, index) => {
		return {
			value: index,
			label: (
				<div>
					<AS.FlexRow isCentered>
						<S.SelectFont>
							NFT Title: <span style={{ fontWeight: 'bold' }}>{token?.title}</span>
						</S.SelectFont>
					</AS.FlexRow>
					<Spacer height={5} />
					<AS.FlexRow>
						<S.SelectFont>
							Platform: <span style={{ fontStyle: 'italic' }}>{token?.platform}</span>
						</S.SelectFont>
					</AS.FlexRow>
					<Spacer height={5} />
					<AS.FlexRow>
						<S.SelectFont>Token ID: {token?.tokenId}</S.SelectFont>
					</AS.FlexRow>
				</div>
			),
		};
	});

	// Find the NFT we need inside all NFTs

	useEffect(() => {
		if (params.id && userTokens && NFTOptions) {
			const index = (userTokens.ownedNfts || []).findIndex(nft => nft.id === params.id);
			setSelectedNFT(NFTOptions[index]);
		}
	}, [params.id, userTokens]);

	useEffect(() => {
		dispatch(getRoyaltyFees());
	}, [dispatch]);

	const NFTSelectFunc = option => {
		const selectedValue = NFTOptions.find(marketplace => marketplace.value === option.value);
		setSelectedNFT(selectedValue);
		dispatch(clearUnfoundNFT());
	};

	// List NFT

	const listNFTFunc = () => {
		if (!walletPubKey) {
			dispatch(setNotification({ msg: 'A Wallet is Required!', alertType: 'error' }));
			return;
		}
		if (!startingPrice && !buyNowPrice) {
			dispatch(
				setNotification({
					msg: 'Sale type must be auction or buy now or both!',
					alertType: 'error',
				})
			);
			return;
		}
		if (((startingPrice ?? -1) <= 0 && (buyNowPrice ?? -1) <= 0) || NFTEndDate < NFTStartDate) {
			dispatch(
				setNotification({
					msg: 'The Starting Price and Buy Now Price Must be Greater than 0, and the Start Date Must Come Before the End Date',
					alertType: 'error',
				})
			);
			return;
		}
		const contractAddressNFT = userTokens.ownedNfts[selectedNFT?.value].contract.address;
		const tokenId = bigInt(userTokens.ownedNfts[selectedNFT?.value].tokenId).toString();
		const startingPriceWei = convertToWei(startingPrice ?? "0.000000000000000001");
		const buyNowPriceWei = convertToWei(buyNowPrice ?? "1000000000000000000");
		let startDate = bigInt(NFTStartDate).toString();
		let endDate = bigInt(NFTEndDate).toString();
		const isAuctionAllowed = !!startingPrice;
		const isBuyNowAllowed = !!buyNowPrice;
		if (!selectedDurationButton) {
			const { clockOperationStart, clockOperationEnd } = calculateTimeStamp();
			startDate = bigInt(clockOperationStart).toString();
			endDate = bigInt(clockOperationEnd).toString();
		}
		dispatch(
			sellNFT({
				erc721Contract,
				contractAddressNFT,
				tokenId,
				startingPriceWei,
				buyNowPriceWei,
				startDate,
				endDate,
				isAuctionAllowed,
				isBuyNowAllowed,
			})
		);
	};

	// List Unfound NFT

	const listUnfoundNFTFunc = () => {
		if (!walletPubKey) {
			dispatch(setNotification({ msg: 'A Wallet is Required!', alertType: 'error' }));
			return;
		}
		if (!startingPrice && !buyNowPrice) {
			dispatch(
				setNotification({
					msg: 'Sale type must be auction or buy now or both!',
					alertType: 'error',
				})
			);
			return;
		}
		if (((startingPrice ?? -1) <= 0 && (buyNowPrice ?? -1) <= 0) || NFTEndDate < NFTStartDate) {
			dispatch(
				setNotification({
					msg: 'The Starting Price and Buy Now Price Must be Greater than 0, and the Start Date Must Come Before the End Date',
					alertType: 'error',
				})
			);
			return;
		}
		const contractAddressNFT = unfoundNFT.address;
		const tokenId = unfoundNFT.tokenId;
		const startingPriceWei = convertToWei(startingPrice ?? "0.000000000000000001");
		const buyNowPriceWei = convertToWei(buyNowPrice ?? "1000000000000000000");
		let startDate = bigInt(NFTStartDate).toString();
		let endDate = bigInt(NFTEndDate).toString();
		const isAuctionAllowed = !!startingPrice;
		const isBuyNowAllowed = !!buyNowPrice;
		if (!selectedDurationButton) {
			const { clockOperationStart, clockOperationEnd } = calculateTimeStamp();
			startDate = bigInt(clockOperationStart).toString();
			endDate = bigInt(clockOperationEnd).toString();
		}
		dispatch(
			sellNFT({
				erc721Contract,
				contractAddressNFT,
				tokenId,
				startingPriceWei,
				buyNowPriceWei,
				startDate,
				endDate,
				isAuctionAllowed,
				isBuyNowAllowed,
			})
		);
	};

	// Clock Options and styling for Dropdown

	const startClockSelectStyles = {
		control: base => ({
			...base,
			backgroundColor: 'transparent',
			boxShadow: 'none',
			fontSize: '18px',
			padding: '5px',
			cursor: 'pointer',
			display: 'flex',
			overflow: 'hidden',
			position: 'relative',
			zIndex: 4,
			lineHeight: 1,
			transition: 'all 0.3s ease-in-out',
			alignItems: 'center',
			userSelect: 'none',
			border: 'none',
		}),
		menuPortal: base => ({ ...base, zIndex: 9999 }),
		valueContainer: (styles, { data, isDisabled, isFocused, isSelected }) => {
			return {
				...styles,
				color: 'white',
				padding: 0,
			};
		},
		placeholder: base => ({
			...base,
			color: 'black',
			marginRight: '10px',
			fontFamily: 'Helvetica Neue',
			fontSize: '18px',
			textAlign: 'center',
			zIndex: 5,
			transition: 'all 0.3s ease-in-out',
		}),
		indicatorSeparator: () => ({ display: 'none' }),
		dropdownIndicator: base => ({
			...base,
			color: 'black',
			padding: '2px 2px 2px 0',
			transition: 'all 0.3s ease-in-out',
			fontSize: '16px',
			transform: startClockMenuOpen ? 'rotate(180deg)' : null,
		}),
		option: styles => {
			return {
				...styles,
				position: 'relative',
				backgroundColor: 'white',
				color: 'black',
				padding: '20px 15px',
				cursor: 'pointer',
				transition: 'all 0.3s ease-in-out',
				height: '100%',
				'&:hover': {
					backgroundColor: '#E8F9F2',
				},
			};
		},
		menu: base => ({
			...base,
			width: '100px',
		}),
		menuList: base => ({
			...base,
			width: '100%',
			height: '100%',
			maxHeight: '100%',
			paddingTop: 0,
			paddingBottom: 0,
			zIndex: 3,
			boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.12)',
			borderRadius: '8px',
			overflowX: 'hidden',
			'  &::-webkit-scrollbar-track': {
				backgroundColor: 'whitesmoke',
				borderRadius: '7px',
				marginRight: '2px',

				'&:hover': {
					backgroundColor: 'whitesmoke',
				},
			},
			'&::-webkit-scrollbar': {
				width: '6px',
				backgroundColor: 'whitesmoke',
				'&:hover': { backgroundColor: 'whitesmoke' },
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: '#8e8e8e',
				borderRadius: '7px',
				transition: 'all 0.3s ease-in-out',
				'&:hover': { backgroundColor: '#757575', cursor: 'pointer !important' },
			},
		}),
	};

	const endClockSelectStyles = {
		control: base => ({
			...base,
			backgroundColor: 'transparent',
			boxShadow: 'none',
			fontSize: '18px',
			padding: '5px',
			cursor: 'pointer',
			display: 'flex',
			overflow: 'hidden',
			position: 'relative',
			zIndex: 4,
			lineHeight: 1,
			transition: 'all 0.3s ease-in-out',
			alignItems: 'center',
			userSelect: 'none',
			border: 'none',
		}),
		menuPortal: base => ({ ...base, zIndex: 9999 }),
		valueContainer: (styles, { data, isDisabled, isFocused, isSelected }) => {
			return {
				...styles,
				color: 'white',
				padding: 0,
			};
		},
		placeholder: base => ({
			...base,
			color: 'black',
			marginRight: '10px',
			fontFamily: 'Helvetica Neue',
			fontSize: '18px',
			textAlign: 'center',
			zIndex: 5,
			transition: 'all 0.3s ease-in-out',
		}),
		indicatorSeparator: () => ({ display: 'none' }),
		dropdownIndicator: base => ({
			...base,
			color: 'black',
			padding: '2px 2px 2px 0',
			transition: 'all 0.3s ease-in-out',
			fontSize: '16px',
			transform: endClockMenuOpen ? 'rotate(180deg)' : null,
		}),
		option: styles => {
			return {
				...styles,
				position: 'relative',
				backgroundColor: 'white',
				color: 'black',
				padding: '20px 15px',
				cursor: 'pointer',
				transition: 'all 0.3s ease-in-out',
				height: '100%',
				'&:hover': {
					backgroundColor: '#E8F9F2',
				},
			};
		},
		menu: base => ({
			...base,
			width: '100px',
		}),
		menuList: base => ({
			...base,
			width: '100%',
			height: '100%',
			maxHeight: '100%',
			paddingTop: 0,
			paddingBottom: 0,
			zIndex: 3,
			boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.12)',
			borderRadius: '8px',
			overflowX: 'hidden',
			'  &::-webkit-scrollbar-track': {
				backgroundColor: 'whitesmoke',
				borderRadius: '7px',
				marginRight: '2px',

				'&:hover': {
					backgroundColor: 'whitesmoke',
				},
			},
			'&::-webkit-scrollbar': {
				width: '6px',
				backgroundColor: 'whitesmoke',
				'&:hover': { backgroundColor: 'whitesmoke' },
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: '#8e8e8e',
				borderRadius: '7px',
				transition: 'all 0.3s ease-in-out',
				'&:hover': { backgroundColor: '#757575', cursor: 'pointer !important' },
			},
		}),
	};

	const ClockOptions = [
		{
			value: 'am',
			label: 'AM',
		},
		{
			value: 'pm',
			label: 'PM',
		},
	];

	// Calculate NFT Time Stamp in seconds

	const calculateTimeStamp = () => {
		const safeStartDateHours = startDateHours === '12' ? '0' : startDateHours;
		const safeEndDateHours = endDateHours === '12' ? '0' : endDateHours;
		let clockOperationStart = Math.floor(
			startingDate.getTime() / 1000 + parseInt(safeStartDateHours) * 60 * 60 + parseInt(startDateMinutes) * 60
		);
		if (startDateSelectedClock.value === 'pm') {
			clockOperationStart += 12 * 60 * 60;
		}
		let clockOperationEnd = Math.floor(endingDate.getTime() / 1000 + parseInt(safeEndDateHours) * 60 * 60 + parseInt(endDateMinutes) * 60);
		if (endDateSelectedClock.value === 'pm') {
			clockOperationEnd += 12 * 60 * 60;
		}
		return { clockOperationStart, clockOperationEnd };
	};

	// Select Start Hours Input Field

	const changeStartDateHours = e => {
		if (e.target.value > 12 || e.target.value < 0) {
			dispatch(
				setNotification({
					msg: 'Hours must be greater than or equal to 0 and less than or equal to 12',
					alertType: 'error',
				})
			);
			return;
		}
		setStartDateHours(e.target.value);
		// calculateTimeStamp();
	};

	// Select Start Minutes Input Field

	const changeStartDateMinutes = e => {
		if (e.target.value > 59 || e.target.value < 0) {
			dispatch(
				setNotification({
					msg: 'Minutes must be greater than or equal to 0 and less than 60',
					alertType: 'error',
				})
			);
			return;
		}
		setStartDateMinutes(e.target.value);
		// calculateTimeStamp();
	};

	// Select Start AM or PM

	const startDateClockSelectFunc = option => {
		const selectedValue = ClockOptions.find(clock => clock.value === option.value);
		setStartDateSelectedClock(selectedValue);
		// calculateTimeStamp();
	};

	// Select End Hours Input Field

	const changeEndDateHours = e => {
		if (e.target.value > 12 || e.target.value < 0) {
			dispatch(
				setNotification({
					msg: 'Hours must be greater than or equal to 0 and less than or equal to 12',
					alertType: 'error',
				})
			);
			return;
		}
		setEndDateHours(e.target.value);
		// calculateTimeStamp();
	};

	// Select End Minutes Input Field

	const changeEndDateMinutes = e => {
		if (e.target.value > 59 || e.target.value < 0) {
			dispatch(
				setNotification({
					msg: 'Minutes must be greater than or equal to 0 and less than 60',
					alertType: 'error',
				})
			);
			return;
		}
		setEndDateMinutes(e.target.value);
		// calculateTimeStamp();
	};

	// Select End AM or PM

	const endDateClockSelectFunc = option => {
		const selectedValue = ClockOptions.find(clock => clock.value === option.value);
		setEndDateSelectedClock(selectedValue);
		// calculateTimeStamp();
	};

	// Reset Date Pickers

	const resetDatePickers = () => {
		setOpenCustomDatePicker(false);
		setStartingDate(time);
		setEndingDate(time);
		setStartDateHours('0');
		setStartDateMinutes('0');
		setEndDateHours('0');
		setEndDateMinutes('0');
		setStartDateSelectedClock({
			value: 'am',
			label: 'AM',
		});
		setEndDateSelectedClock({
			value: 'am',
			label: 'AM',
		});
	};

	// Handle One Day Duration Button

	const handleOneDayDuration = () => {
		setNFTStartDate(nowPlus(0));
		setNFTEndDate(nowPlus(24 * 3600));
		setSelectedDurationButton('1');
		resetDatePickers();
	};

	// Handle One Week Duration Button

	const handleOneWeekDuration = () => {
		setNFTStartDate(nowPlus(0));
		setNFTEndDate(nowPlus(24 * 3600 * 7));
		setSelectedDurationButton('7');
		resetDatePickers();
	};

	// Handle One Month Duration Button

	const handleOneMonthDuration = () => {
		setNFTStartDate(nowPlus(0));
		setNFTEndDate(nowPlus(24 * 3600 * 30));
		setSelectedDurationButton('30');
		resetDatePickers();
	};

	if (!walletPubKey) {
		return <WalletConnect />;
	}

	return (
		<>
			<S.Container>
				{loadingState && (
					<div style={{ overflowY: 'hidden' }}>
						<S.Overlay />
						<StyledLoader style={{ zIndex: 6 }} />
					</div>
				)}
				<S.MainRow>
					<S.PreviewColumn>
						<AS.FlexRow>
							<S.PreviewText>preview</S.PreviewText>
						</AS.FlexRow>
						<Spacer height={20} />
						<Card noHover={true}>
							<img
								src={
									selectedNFT && unfoundNFT
										? unfoundNFT.image
										: selectedNFT && !unfoundNFT
										? safeIpfsUrl(findImageUrlInMetadata(userTokens.ownedNfts[selectedNFT?.value].rawMetadata))
										: ListingsDefaultAvatar
								}
								alt="previewImage"
							/>
							<Spacer height={10} />
							<Divider />
							<Spacer height={10} />
							<AS.FlexRow isCentered>
								<AS.Text fontFamily="Helvetica Neue" color="black" fontSize="18px">
									{unfoundNFT
										? `(${unfoundNFT?.coordinates})`
										: selectedNFT
										? `(${userTokens?.ownedNfts[selectedNFT?.value]?.coordinates})`
										: '(X, Y)'}
								</AS.Text>
							</AS.FlexRow>
						</Card>
						<Spacer height={30} />
						{loading ? (
							<StyledLoader />
						) : (
							<AS.FlexRow style={{ gap: '10px' }}>
								<DropdownRow style={{ width: '100%', justifyContent: 'flex-start' }}>
									<Select
										styles={NFTSelectStyles}
										placeholder="NFTs Available"
										components={animatedComponents}
										options={NFTOptions}
										onChange={NFTSelectFunc}
										closeMenuOnSelect={true}
										value={selectedNFT}
										onMenuOpen={() => setNFTMenuOpen(true)}
										onMenuClose={() => setNFTMenuOpen(false)}
										classNamePrefix="select"
										className="react-select"
										menuPortalTarget={document.body}
									/>
								</DropdownRow>
								<S.PlusIconDiv className="Tooltip" data-text="Can't see your NFT? Click here!" onClick={() => setIsOpenModal(true)}>
									<S.PlusIcon />
								</S.PlusIconDiv>
							</AS.FlexRow>
						)}
					</S.PreviewColumn>
					<AS.Column>
						<AS.FlexRow>
							<S.ListItemText fontSize="36px" userSelectNone>
								list item for sale
							</S.ListItemText>
						</AS.FlexRow>
						<S.MainSpacer />
						{selectedNFT && (
							<AS.FlexRow>
								<AS.Column>
									<AS.Text color="black" fontSize="18px" userSelectNone>
										title
									</AS.Text>
									<Spacer height={20} />
									<AS.Text color="black" fontSize="20px" fontFamily="Helvetica Neue">
										{unfoundNFT ? unfoundNFT.title : userTokens.ownedNfts[selectedNFT?.value].title}
									</AS.Text>
								</AS.Column>
							</AS.FlexRow>
						)}
						<Spacer height={20} />
						<AS.FlexRow>
							<AS.Column>
								<AS.Text color="black" fontSize="18px" userSelectNone>
									buy now price
								</AS.Text>
								<Spacer height={20} />
								<AS.FlexRow style={{ gap: '15px' }}>
									<S.BuyNowInput
										placeholder="Buy Now Price"
										min="0"
										onWheel={e => e.target.blur()}
										value={buyNowPrice}
										onChange={e => setBuyNowPrice(e.target.value)}
										type="number"
									/>
									<S.EthereumIcon src={EthereumIcon} alt="Ethereum Icon" />
								</AS.FlexRow>
							</AS.Column>
						</AS.FlexRow>
						<Spacer height={25} />
						<AS.FlexRow>
							<AS.Column>
								<AS.Text color="black" fontSize="18px" userSelectNone>
									starting price
								</AS.Text>
								<Spacer height={20} />
								<AS.FlexRow style={{ gap: '15px' }}>
									<S.StartingPriceInput
										placeholder="Starting Price"
										min="0"
										onWheel={e => e.target.blur()}
										value={startingPrice}
										onChange={e => setStartingPrice(e.target.value)}
										type="number"
									/>
									<S.EthereumIcon src={EthereumIcon} alt="Ethereum Icon" />
								</AS.FlexRow>
							</AS.Column>
						</AS.FlexRow>
						<Spacer height={25} />
						{selectedNFT && (
							<AS.FlexRow>
								<AS.Column>
									<AS.Text color="black" fontSize="18px" userSelectNone>
										description
									</AS.Text>
									<Spacer height={20} />
									<S.DescriptionTextArea>
										{unfoundNFT ? unfoundNFT.description : userTokens.ownedNfts[selectedNFT?.value].description}
									</S.DescriptionTextArea>
								</AS.Column>
							</AS.FlexRow>
						)}
						<Spacer height={25} />
						<AS.FlexRow>
							<AS.Column>
								<AS.Text color="black" fontSize="18px">
									duration
								</AS.Text>
								<Spacer height={20} />
								<S.DurationRow>
									<S.DurationButton
										text="1 day"
										onClick={handleOneDayDuration}
										style={{
											backgroundColor: selectedDurationButton === '1' && '#1cbf7b',
											color: selectedDurationButton === '1' && 'white',
										}}
									/>
									<S.DurationButton
										text="1 week"
										onClick={handleOneWeekDuration}
										style={{
											backgroundColor: selectedDurationButton === '7' && '#1cbf7b',
											color: selectedDurationButton === '7' && 'white',
										}}
									/>
									<S.DurationButton
										text="1 month"
										onClick={handleOneMonthDuration}
										style={{
											backgroundColor: selectedDurationButton === '30' && '#1cbf7b',
											color: selectedDurationButton === '30' && 'white',
										}}
									/>
								</S.DurationRow>
							</AS.Column>
						</AS.FlexRow>

						<Spacer height={25} />
						<AS.FlexRow>
							<Button
								onClick={() => {
									setOpenCustomDatePicker(prevState => !prevState);
									setSelectedDurationButton('');
								}}
								text="custom"
								style={{
									backgroundColor: openCustomDatePicker ? '#1cbf7b' : 'white',
									color: openCustomDatePicker ? 'white' : '#1cbf7b',
									width: '100%',
								}}
							/>
						</AS.FlexRow>
						<Spacer height={25} />
						{openCustomDatePicker && (
							<AS.FlexRow>
								<S.CalendarCard>
									<S.CalendarRow>
										<AS.Column alignItems="center">
											<AS.Text color="black" fontSize="16px">
												start date
											</AS.Text>
											<Spacer height={20} />
											<S.DateButton text={dayjs(startingDate.toString()).format('DD-MM-YYYY')} />
											<Spacer height={25} />

											<Calendar onChange={handleStartDateChange} value={startingDate} />
											<Spacer height={25} />
											<AS.Column alignItems="center">
												<S.TimeInputRow>
													<AS.Column alignItems="center">
														<S.TimeInput value={startDateHours} onChange={e => changeStartDateHours(e)} type="number" min="0" max="12" />
														<AS.Text fontFamily="Helvetica Neue" fontSize="16px" color="black">
															Hours
														</AS.Text>
													</AS.Column>
													<AS.Column alignItems="center">
														<S.TimeInput
															value={startDateMinutes}
															onChange={e => changeStartDateMinutes(e)}
															type="number"
															min="0"
															max="60"
														/>
														<AS.Text fontFamily="Helvetica Neue" fontSize="16px" color="black">
															Minutes
														</AS.Text>
													</AS.Column>
													<AS.Column alignItems="center">
														<Select
															styles={startClockSelectStyles}
															placeholder="AM/PM"
															components={animatedComponents}
															options={ClockOptions}
															onChange={startDateClockSelectFunc}
															closeMenuOnSelect={true}
															value={startDateSelectedClock}
															onMenuOpen={() => setStartClockMenuOpen(true)}
															onMenuClose={() => setStartClockMenuOpen(false)}
															classNamePrefix="select"
															className="react-select"
															menuPortalTarget={document.body}
														/>
													</AS.Column>
												</S.TimeInputRow>
											</AS.Column>
										</AS.Column>
										<AS.Column alignItems="center">
											<AS.Text color="black" fontSize="16px">
												end date
											</AS.Text>
											<Spacer height={20} />
											<S.DateButton text={dayjs(endingDate.toString()).format('DD-MM-YYYY')} />
											<Spacer height={25} />

											<Calendar onChange={handleEndDateChange} value={endingDate} />
											<Spacer height={25} />
											<AS.Column>
												<S.TimeInputRow>
													<AS.Column alignItems="center">
														<S.TimeInput value={endDateHours} onChange={e => changeEndDateHours(e)} type="number" min="0" max="12" />
														<AS.Text fontFamily="Helvetica Neue" fontSize="18px" color="black">
															Hours
														</AS.Text>
													</AS.Column>
													<AS.Column alignItems="center">
														<S.TimeInput value={endDateMinutes} onChange={e => changeEndDateMinutes(e)} type="number" min="0" max="60" />
														<AS.Text fontFamily="Helvetica Neue" fontSize="18px" color="black">
															Minutes
														</AS.Text>
													</AS.Column>
													<AS.Column alignItems="center">
														<Select
															styles={endClockSelectStyles}
															placeholder="AM/PM"
															components={animatedComponents}
															options={ClockOptions}
															onChange={endDateClockSelectFunc}
															closeMenuOnSelect={true}
															value={endDateSelectedClock}
															onMenuOpen={() => setEndClockMenuOpen(true)}
															onMenuClose={() => setEndClockMenuOpen(false)}
															classNamePrefix="select"
															className="react-select"
															menuPortalTarget={document.body}
														/>
													</AS.Column>
												</S.TimeInputRow>
											</AS.Column>
										</AS.Column>
									</S.CalendarRow>
								</S.CalendarCard>
							</AS.FlexRow>
						)}
						<Spacer height={25} />
						<AS.FlexRow>
							<AS.Text fontSize="18px" color="black">
								fees
							</AS.Text>
						</AS.FlexRow>
						<Spacer height={25} />
						<AS.FlexRow style={{ justifyContent: 'space-between' }}>
							<AS.Text fontSize="16px" color="black" fontFamily="Helvetica Neue">
								Marketplace Fee
							</AS.Text>
							<AS.Text fontSize="16px" color="black" fontFamily="Helvetica Neue">
								{feePercent}%
							</AS.Text>
						</AS.FlexRow>
						<Spacer height={25} />
						{royaltyFee?.toString() && (
							<AS.FlexRow style={{ justifyContent: 'space-between' }}>
								<AS.Text fontSize="16px" color="black" fontFamily="Helvetica Neue">
									Royalty Fee
								</AS.Text>
								<AS.Text fontSize="16px" color="black" fontFamily="Helvetica Neue">
									{royaltyFee / 100}%
								</AS.Text>
							</AS.FlexRow>
						)}
						<Spacer height={50} />
						<AS.FlexRow isCentered>
							<Button text="list for sale" width="100%" onClick={unfoundNFT ? listUnfoundNFTFunc : listNFTFunc} />
						</AS.FlexRow>
					</AS.Column>
				</S.MainRow>
			</S.Container>
			<CantFindNFTModal isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} />
		</>
	);
};

export default ListItem;
