import { Outlet } from 'react-router-dom';

import { Footer } from 'components/Footer';
import { Header } from 'components/Header';

export const Layout = () => (
	<div style={{ overflowX: 'hidden' }}>
		<Header />
		<Outlet />
		<Footer />
	</div>
);
