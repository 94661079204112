import Torus from '@toruslabs/torus-embed';
import { useWeb3React } from '@web3-react/core';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Web3 from 'web3';

import { StyledLoader } from 'components/Loader/StyledLoader';
import { Spacer } from 'components/Spacer/Spacer';

import { setNotification } from 'state/features/notificationSlice';
import { setWallet } from 'state/features/walletSlice';

// import { Web3Auth } from "@web3auth/web3auth";
import { convertToETH } from 'utils/convertCurrencies';

import * as AS from 'styles/AppStyles';

import CoinbaseLogo from 'images/wallet-logos/Coinbase-logo.svg';
import MetamaskLogo from 'images/wallet-logos/Metamask-logo.svg';

import { activateInjectedProvider, coinbase, fortmatic, metamask, resetWalletConnector, walletConnect } from '../../connectors';
import * as S from './styles';

const clientId = 'BKxSOGdOy0anFSJw19Gjne8FUbtU5qVP8Xz2YiHzMNpQXJQinqSSEOQpP1mDj8aNghXrR3r2zpHhyY2lBvyG2IA';

const WalletConnect = () => {
	const dispatch = useDispatch();
	const libraryRef = useRef(null);
	const web3AuthRef = useRef(null);
	const setIntervalRef = useRef(null);
	const walletBalanceRef = useRef(null);
	const web3authWalletBalanceRef = useRef(null);

	const { account, activate, library } = useWeb3React();

	const [walletBalance, setWalletBalance] = useState(null);
	const [torusInstance, setTorusInstance] = useState(null);
	const [walletLoading, setWalletLoading] = useState(false);

	// Get name of wallet currently in the store for validation
	const walletName = useSelector(state => state.wallet.walletName);

	// Get Web3 Wallet Balance Logic

	useEffect(() => {
		if (library) {
			library.getBalance(account).then(balance => {
				setWalletBalance(balance);
			});
		}
	}, [library]);

	useEffect(() => {
		if (walletBalance && account) {
			try {
				clearInterval(setIntervalRef.current);
				libraryRef.current();
				walletBalanceRef.current = { account, walletBalance };
			} catch (error) {
				console.error(error);
			}
		}
	}, [walletBalance, account, libraryRef]);

	// Get Web3Auth Wallet Balance Logic

	// useEffect(() => {
	//   if (provider) {
	//     const getWeb3AuthWallet = async () => {
	//       const rpc = new RPC(provider);
	//       const balance = await rpc.getBalance();
	//       const ethersProvider = new ethers.providers.Web3Provider(provider);
	//       const signer = ethersProvider.getSigner();
	//       // Get user's Ethereum public address
	//       const address = await signer.getAddress();
	//       setWeb3AuthUser(address);
	//       setWeb3AuthBalance(balance);
	//     };
	//     getWeb3AuthWallet();
	//   }
	// }, [provider]);

	// useEffect(() => {
	//   if (web3auth && provider && web3AuthBalance) {
	//     try {
	//       clearInterval(setIntervalRef.current);
	//       web3AuthRef.current();
	//       web3authWalletBalanceRef.current = {
	//         account: web3AuthUser,
	//         walletBalance: web3AuthBalance,
	//       };
	//     } catch (error) {
	//       console.error(error);
	//     }
	//   }
	// }, [web3auth, provider, web3AuthBalance, web3AuthRef]);

	// Torus Balance Logic
	useEffect(() => {
		if (torusInstance) {
			try {
				clearInterval(setIntervalRef.current);
				web3AuthRef.current();
				const address = torusInstance.eth.getAccounts()[0];
				const balance = torusInstance.eth.getBalance(address);
				web3authWalletBalanceRef.current = {
					account: address,
					walletBalance: balance,
				};
			} catch (error) {
				console.error(error);
			}
		}
	}, [torusInstance, web3AuthRef]);

	// useEffect(() => {
	//   async function init() {
	//     // Web3Auth
	//     try {
	//       const web3authInstance = new Web3Auth({
	//         clientId,
	//         chainConfig: {
	//           chainNamespace: CHAIN_NAMESPACES.EIP155,
	//           chainId: "0x5",
	//           rpcTarget:
	//             "https://goerli.infura.io/v3/API-KEY-HERE", // This is the public RPC we have added, please pass on your own endpoint while creating an app
	//         },
	//       });
	//       await web3authInstance.initModal();
	//       setWeb3auth(web3authInstance);
	//     } catch (error) {
	//       console.error(error);
	//     }
	//   }
	//   init();
	// }, []);

	const pendingWalletBalanceFunc = () => {
		return new Promise((resolve, reject) => {
			libraryRef.current = resolve;
			setIntervalRef.current = setInterval(() => {}, 300);
		});
	};

	const pendingWeb3AuthFunc = () => {
		return new Promise((resolve, reject) => {
			web3AuthRef.current = resolve;
			setIntervalRef.current = setInterval(() => {}, 300);
		});
	};

	const handleMetamaskClick = async () => {
		if (walletName === 'Metamask') return;
		try {
			setWalletLoading(true);
			activateInjectedProvider('metamask');
			activate(metamask);
			await pendingWalletBalanceFunc();
			dispatch(
				setWallet({
					active: true,
					address: walletBalanceRef.current.account,
					balance: convertToETH(walletBalanceRef.current.walletBalance.toString()),
					walletName: 'Metamask',
				})
			);
			dispatch(
				setNotification({
					msg: 'Wallet Connected!',
					alertType: 'success',
				})
			);
			setWalletLoading(false);
		} catch (error) {
			setWalletLoading(false);
			console.error(error);
			dispatch(
				setNotification({
					msg: error,
					alertType: 'error',
				})
			);
		}
	};

	const handleCoinbaseClick = async () => {
		if (walletName === 'Coinbase') return;
		try {
			setWalletLoading(true);
			activateInjectedProvider('coinbase');
			await activate(coinbase);
			await pendingWalletBalanceFunc();
			dispatch(
				setWallet({
					active: true,
					address: walletBalanceRef.current.account,
					balance: convertToETH(walletBalanceRef.current.walletBalance.toString()),
					walletName: 'Coinbase',
				})
			);
			dispatch(
				setNotification({
					msg: 'Wallet Connected!',
					alertType: 'success',
				})
			);
			setWalletLoading(false);
		} catch (error) {
			setWalletLoading(false);
			console.error(error);
			dispatch(
				setNotification({
					msg: error,
					alertType: 'error',
				})
			);
		}
	};

	return (
		<S.WalletConnectContainer>
			<AS.FlexRow isCentered>
				<S.WalletText>wallet connect</S.WalletText>
			</AS.FlexRow>
			<Spacer height={30} />
			<AS.FlexRow isCentered>
				<AS.Text fontSize="18px" fontFamily="Helvetica Neue" color="black" style={{ textAlign: 'center' }}>
					Connect to your crypto wallet to get started. Note that this is a decentralized <br /> marketplace that runs on the Ethereum
					blockchain.
				</AS.Text>
			</AS.FlexRow>
			<Spacer height={12} />
			<div>
				<S.WalletsDiv
					style={{
						filter: walletLoading && 'blur(4px)',
						pointerEvents: walletLoading && 'none',
					}}
				>
					<Spacer height={20} />
					<S.WalletCard onClick={handleMetamaskClick}>
						<S.WalletLogoDiv>
							<S.WalletLogo src={MetamaskLogo} alt="Metamask-logo" />
						</S.WalletLogoDiv>
						metamask
					</S.WalletCard>
					<Spacer height={20} />
					{/* <S.WalletCard onClick={handleFortmaticClick}>
            <S.WalletLogoDiv>
              <S.WalletLogo src={FortmaticLogo} alt="Fortmatic-logo" />
            </S.WalletLogoDiv>
            fortmatic
          </S.WalletCard>
          <S.WalletCard onClick={handleWalletConnectClick}>
            <S.WalletLogoDiv>
              <S.WalletLogo
                style={{ width: "30px" }}
                src={WalletConnectLogo}
                alt="wallet-connect-logo"
              />
            </S.WalletLogoDiv>
            wallet connect
          </S.WalletCard> */}
					<S.WalletCard onClick={handleCoinbaseClick}>
						<S.WalletLogoDiv>
							<S.WalletLogo style={{ width: '30px' }} src={CoinbaseLogo} alt="CoinbaseLogo-logo" />
						</S.WalletLogoDiv>
						coinbase wallet
					</S.WalletCard>
					{/* <S.WalletCard onClick={(e) => handleTorusClick(e)}>
            <S.WalletLogoDiv>
              <S.WalletLogo
                style={{ width: "30px" }}
                src={Web3AuthLogo}
                alt="Web3Auth-logo"
              />
            </S.WalletLogoDiv>
            web3auth
          </S.WalletCard> */}
				</S.WalletsDiv>
				{walletLoading && <StyledLoader />}
			</div>
		</S.WalletConnectContainer>
	);
};

export default WalletConnect;
