import { Xmark } from '@styled-icons/fa-solid/Xmark';
import { device } from 'device-dimensions';
import styled from 'styled-components/macro';

import { Button } from 'components/Button/Button';
import { Card } from 'components/Card/Card';
import { StatusButton } from 'components/Common/Filters/styles';
import { Input } from 'components/Input/styles';
import { Spacer } from 'components/Spacer/styles';

import * as AS from 'styles/AppStyles';

export const Container = styled(AS.Column)`
	overflow-x: auto;
	height: 100%;
	min-height: 100vh;
	position: relative;
	padding: 5% 8%;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const MainRow = styled(AS.FlexRow)`
	display: grid;
	grid-template-columns: 0.5fr 0.5fr;
	grid-gap: 15px;
	align-items: flex-start;
	@media ${device.mobileL} {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}
`;

export const PreviewColumn = styled(AS.Column)`
	padding: 0 45% 0 0;
	@media ${device.mobileL} {
		width: fit-content;
		padding: 15px;
	}
`;

export const PreviewText = styled(AS.Text)`
	font-size: 28px;
	color: black;
	user-select: none;
	@media ${device.mobileL} {
		display: none;
	}
`;

export const ListItemText = styled(AS.Text)`
	font-size: 40px;
	@media ${device.mobileL} {
		font-size: 28px;
		text-align: center;
	}
`;

export const MainSpacer = styled(Spacer)`
	height: 50px;
	@media ${device.mobileL} {
		height: 25px;
	}
`;

export const EthereumIcon = styled.img`
	width: 25px;
`;

export const DescriptionTextArea = styled.div`
	max-width: 95%;
	resize: none;
	width: 100%;
	padding: 15px;
	border: 1px solid #dbdbdb;
	border-radius: 10px;
	transition: all 0.3s ease-in-out;
	font-size: 16px;
	font-family: 'Helvetica Neue';
	height: auto;
	max-height: 500px;
	cursor: auto;
`;

export const DurationRow = styled(AS.FlexRow)`
	gap: 15px;
	flex-wrap: wrap;
	@media ${device.mobileL} {
		/* flex-direction: column; */
	}
`;

export const DurationButton = styled(StatusButton)`
	font-family: Bord;
`;

export const CalendarCard = styled(Card)`
	background-color: white;
	min-height: 150px;
	height: 550px;
	padding: 25px;
	&:hover {
		cursor: auto;
		box-shadow: none;
	}
	@media ${device.mobileL} {
		height: 100%;
	}
`;

export const TimesIcon = styled(Xmark)`
	position: absolute;
	top: -5px;
	width: 10px;
	right: 0;
	transition: all 0.3s ease-in-out;
	&:hover {
		cursor: pointer;
		transform: scale(1.1);
	}
`;

export const CalendarRow = styled.div`
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	grid-gap: 10px;
	max-width: 100%;
	margin-bottom: 50px;
	@media ${device.mobileL} {
		grid-template-columns: none;
		justify-content: center;
		grid-gap: 25px;
	}
`;

export const DateButton = styled(StatusButton)`
	width: 90%;
	@media ${device.mobileL} {
		width: 250px;
	}
`;

export const CalendarSpacer = styled(Spacer)`
	display: none;
	@media ${device.mobileL} {
		height: 20px;
	}
`;

export const BuyNowInput = styled(Input)`
	border: 1px solid #dbdbdb;
	width: 50%;
	height: 48px;
	@media ${device.mobileL} {
		width: 100%;
	}
`;

export const StartingPriceInput = styled(Input)`
	border: 1px solid #dbdbdb;
	width: 50%;
	height: 48px;
	@media ${device.mobileL} {
		width: 100%;
	}
`;

export const SelectFont = styled(AS.Text)`
	color: black;
	font-family: Helvetica Neue;
	font-size: 18px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
	transition: all 0.3s ease-in-out;
`;

export const RedeemItemButton = styled(Button)`
	border: 1px solid #222222;
	background-color: #222222;
	border-radius: 10px;
	font-size: 26px;
	color: white;
	width: 100%;
	transition: all 0.3s ease-in-out;
	&:hover {
		box-shadow: #1cbf7b -1px 2px 7px 0px;
		cursor: pointer;
	}
`;
