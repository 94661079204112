import { ArrowRight } from '@styled-icons/bootstrap/ArrowRight';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Card } from 'components/Card/Card';
import { Spacer } from 'components/Spacer/Spacer';

import { convertToETH } from 'utils/convertCurrencies';

import * as AS from 'styles/AppStyles';

import * as S from './styles';

const ListingsCard = ({ nft, noHover }) => {
	const navigate = useNavigate();
	const [toggleBid, setToggleBid] = useState(false);
	// Destructure the nft
	const { title, platform, image, coordinates, details } = nft || {};

	const { isAuctionAllowed, isBuyNowAllowed } = details;

	// Get Top Bid and Starting Price
	const topBid = convertToETH(nft?.details?.highestBid?.toString());
	const buyNowPrice = convertToETH(nft?.details?.buyNowPrice?.toString());
	const startingPrice = convertToETH(nft?.details?.startingPrice?.toString());
	// Toggle bid on card hover
	let timeout = null;

	const onMouseEnterFunc = () => {
		timeout = setTimeout(() => setToggleBid(true), 100);
	};

	const onMouseOutFunc = () => {
		if (timeout) {
			clearTimeout(timeout);
		}
		setToggleBid(false);
	};

	return (
		<Card
			noHover={noHover}
			onMouseEnter={!noHover ? onMouseEnterFunc : undefined}
			onMouseLeave={!noHover ? onMouseOutFunc : undefined}
			onClick={() => {
				if (noHover) return;
				navigate(`/NFT/${nft.id}`);
			}}
		>
			<img src={image} alt="ListingsDefaultAvatar" style={{ width: '100%' }} />
			<Spacer height={5} />
			<AS.FlexRow isCentered>
				<AS.Text
					fontFamily="Helvetica"
					fontSize="16px"
					color="black"
					bold
					style={{
						textAlign: 'center',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						width: '55%',
					}}
				>
					{title}
				</AS.Text>
			</AS.FlexRow>
			<Spacer height={5} />
			<AS.FlexRow isCentered>
				<AS.Text fontFamily="Helvetica" fontSize="16px" color="#8E8E8E">
					{platform?.toUpperCase()}
				</AS.Text>
			</AS.FlexRow>
			<Spacer height={5} />
			<AS.FlexRow isCentered>
				<AS.Text fontFamily="Helvetica" fontSize="16px" color="#8E8E8E">
					{`(${coordinates})`}
				</AS.Text>
			</AS.FlexRow>
			{(isAuctionAllowed || isBuyNowAllowed) && (
				<div style={{ minHeight: '56px' }}>
					<Spacer height={10} />
					<S.Divider />
					<Spacer height={toggleBid ? 20 : 10} />
					<AS.FlexRow style={{ justifyContent: 'space-evenly' }}>
						{!toggleBid ? (
							<>
								{isAuctionAllowed && (
									<AS.Column alignItems="center">
										<AS.Text fontFamily="Helvetica" fontSize="14px" color="#8E8E8E">
											Top Bid
										</AS.Text>
										<AS.Text fontFamily="Helvetica" fontSize="16px" color="black" bold>
											{topBid}
										</AS.Text>
									</AS.Column>
								)}
								<AS.Column alignItems="center">
									<AS.Text fontFamily="Helvetica" fontSize="14px" color="#8E8E8E">
										{isAuctionAllowed ? 'Starting Price' : 'Buy Now Price'}
									</AS.Text>
									<AS.Text fontFamily="Helvetica" fontSize="16px" color="black" bold>
										{isAuctionAllowed ? startingPrice : buyNowPrice}
									</AS.Text>
								</AS.Column>
							</>
						) : (
							<AS.Column alignItems="center" justifyContent="center" style={{ cursor: 'pointer' }}>
								<AS.FlexRow isCentered>
									<AS.Text fontFamily="Helvetica" fontSize="14px" bold style={{ marginRight: '5px' }}>
										{isAuctionAllowed ? 'PLACE BID' : 'BUY NOW'}
									</AS.Text>
									<ArrowRight style={{ color: '#1cbf7b', width: '18px' }} />
								</AS.FlexRow>
							</AS.Column>
						)}
					</AS.FlexRow>
				</div>
			)}
		</Card>
	);
};

export default ListingsCard;
