import styled, { css } from 'styled-components';

export const primaryColor = css`
	color: #1cbf7b;
`;

export const FlexRow = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	${({ isCentered }) =>
		isCentered &&
		css`
			justify-content: center;
		`}
`;

export const Text = styled.p`
	color: ${({ color }) => color || '#1cbf7b'};
	font-family: ${({ fontFamily }) => fontFamily || 'Bord, sans-serif'};
	font-size: ${({ fontSize }) => fontSize || '16px'};
	font-weight: 400;
	${({ userSelectNone }) =>
		userSelectNone &&
		css`
			user-select: none;
		`};
	${({ whiteSpaceNoWrap }) =>
		whiteSpaceNoWrap &&
		css`
			white-space: nowrap;
		`};
	${({ bold }) =>
		bold &&
		css`
			font-weight: bold;
		`}
`;

export const Spacer = styled.div`
	margin: 10px 0;
`;

export const EllipsisText = styled.p`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const centerCSS = css`
	justify-content: center;
	align-items: center;
`;

export const horizontalFlex = css`
	justify-content: center;
	display: flex;
	flex-direction: row;
`;

export const Column = styled.div`
	cursor: ${({ cursor }) => (cursor ? 'pointer' : 'default')};
	display: flex;
	flex-direction: column;
	align-items: ${({ alignItems }) => alignItems || 'flex-start'};
	justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
	width: ${({ width }) => width || '100%'};
	${({ flex }) => flex && `flex: 1`};
	flex-shrink: ${({ flexShrink }) => flexShrink ?? 'initial'};
	${({ flexBasis }) => flexBasis && `flex-basis: ${flexBasis}`};
	${({ padding }) => padding && `padding: ${padding}`};
	${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius}`};
	background: ${({ background }) => background || ''};
	height: ${({ height }) => height || ''};
	${({ center }) => center && centerCSS}
`;

export const Row = styled.div`
	cursor: ${({ cursor }) => (cursor ? 'pointer' : 'default')};
	display: flex;
	flex-direction: row;
	align-items: ${({ alignItems }) => alignItems || 'flex-start'};
	justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
	width: ${({ width }) => width || '100%'};
	height: ${({ height }) => height || 'auto'};
	${({ gap }) => gap && `gap: ${gap}`};
	${({ position }) => position && `position: ${position}`};
	${({ flex }) => flex && `flex: ${flex}`};
	${({ padding }) => padding && `padding: ${padding}`};
	${({ background }) => background && `background: ${background}`};
	${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius}`};
	${({ center }) => center && centerCSS}
`;
