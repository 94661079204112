import { Layout } from 'layout';
import { BrowserRouter, HashRouter, Routes as ReactRouterRoutes, Route } from 'react-router-dom';

import { NotFound } from 'pages/404';
import { FAQ } from 'pages/FAQ';
import Home from 'pages/Home/Home';
import ListItem from 'pages/ListItem/ListItem';
import Marketplace from 'pages/Marketplace/Marketplace';
import Privacy from 'pages/Privacy/Privacy';
import Profile from 'pages/Profile/Profile';
import Storefront from 'pages/Storefront/Storefront';
import Support from 'pages/Support/Support';
import Terms from 'pages/Terms/Terms';
import UnsoldNFT from 'pages/UnsoldNFT/UnsoldNFT';
import { UserSafety } from 'pages/UserSafety';
import WalletConnectPage from 'pages/WalletConnect/WalletConnect';
import Welcome from 'pages/Welcome/Welcome';

export const Routes = ({ children }) => (
	<HashRouter>
		{children}
		<ReactRouterRoutes>
			<Route element={<Layout />}>
				<Route path="/" element={<Home />} />
				<Route path="marketplace/:platform" element={<Marketplace />} />
				<Route path="marketplace" element={<Marketplace />} />
				<Route path="list-item" element={<ListItem />} />
				<Route path="/list-item/:id" element={<ListItem />} />
				<Route path="unsold-nft" element={<UnsoldNFT />} />
				<Route path="profile" element={<Profile />} />
				<Route path="support" element={<Support />} />
				<Route path="terms" element={<Terms />} />
				<Route path="faq" element={<FAQ />} />
				<Route path="user-safety" element={<UserSafety />} />
				<Route path="privacy" element={<Privacy />} />
				<Route path="wallet-connect" element={<WalletConnectPage />} />
				<Route path="/NFT/:id" element={<Storefront />} />
			</Route>
			<Route path="welcome" element={<Welcome />} />
			<Route path="*" element={<NotFound />} />
		</ReactRouterRoutes>
	</HashRouter>
);
