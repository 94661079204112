import { Twitter } from '@styled-icons/bootstrap/Twitter';
import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { TwitterShareButton } from 'react-share';

import Modal from 'components/Modal/Modal';
import { Spacer } from 'components/Spacer/Spacer';

import { setNotification } from 'state/features/notificationSlice';

import * as AS from 'styles/AppStyles';

import TerrenaLogo from 'images/Marketplace.webp';

import * as S from './styles';

const ShareModal = memo(({ isOpenModal, setIsOpenModal }) => {
	const dispatch = useDispatch();

	const title = 'Check out this listing on TERRENA!';
	const twitterAccount = 'Terrena';

	return (
		<Modal animate isOpen={isOpenModal} onChange={props => setIsOpenModal(props)}>
			<AS.Column alignItems="center" style={{ padding: '40px 20px' }}>
				<S.ModalCard>
					<AS.FlexRow>
						<AS.Column alignItems="center">
							<AS.FlexRow
								style={{ gap: '15px', cursor: 'pointer' }}
								onClick={() => {
									navigator.clipboard.writeText(window.location.href);
									dispatch(
										setNotification({
											msg: 'NFT URL Copied!',
											alertType: 'success',
										})
									);
								}}
							>
								<img src={TerrenaLogo} style={{ width: '10%' }} alt="terrena-logo" />
								<AS.Text color="grey" fontSize="21px">
									copy link
								</AS.Text>
							</AS.FlexRow>
						</AS.Column>
					</AS.FlexRow>
				</S.ModalCard>
				<Spacer height={10} />
				<S.ModalCard>
					<AS.FlexRow>
						<AS.Column>
							<TwitterShareButton url={window.location.href} title={title} via={twitterAccount}>
								<AS.FlexRow style={{ gap: '15px', cursor: 'pointer' }}>
									<Twitter style={{ color: '#1DA1F2', width: '10%' }} />
									<AS.Text color="grey" fontSize="21px">
										share on twitter
									</AS.Text>
								</AS.FlexRow>
							</TwitterShareButton>
						</AS.Column>
					</AS.FlexRow>
				</S.ModalCard>
			</AS.Column>
		</Modal>
	);
});

export default ShareModal;
