import { memo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button } from 'components/Button/Button';
import { Input } from 'components/Input/Input';
import ButtonLoader from 'components/Loader/ButtonLoader';
import Modal from 'components/Modal/Modal';
import { Spacer } from 'components/Spacer/Spacer';

import { getUnfoundNFT } from 'state/features/NFTSlice';
import { setNotification } from 'state/features/notificationSlice';

import { platforms } from 'utils/validatedPlatforms';

import * as AS from 'styles/AppStyles';

import Decentraland from 'images/Decentraland.webp';
import SomniumSpace from 'images/SomniumSpaceLogo.webp';
import TheSandbox from 'images/TheSandboxLogo.webp';
import Voxels from 'images/Voxels.webp';

import * as S from './styles';

const CantFindNFTModal = memo(({ isOpenModal, setIsOpenModal }) => {
	const dispatch = useDispatch();
	const [selectedPlatform, setSelectedPlatform] = useState('');
	const [NFTID, setNFTID] = useState('');

	const findNFTFunc = () => {
		if (!NFTID) {
			dispatch(setNotification({ msg: 'A Token ID is required', alertType: 'error' }));
			return;
		}
		if (!selectedPlatform) {
			dispatch(setNotification({ msg: 'A platform is required', alertType: 'error' }));
			return;
		}
		const NFTPlatform = platforms.find(platform => platform.name === selectedPlatform);
		const contractAddressNFT = NFTPlatform.address;
		const tokenId = NFTID;
		dispatch(getUnfoundNFT({ contractAddressNFT, tokenId }));
	};

	return (
		<Modal animate isOpen={isOpenModal} onChange={props => setIsOpenModal(props)}>
			<AS.Column alignItems="center" style={{ padding: '10px 15px' }}>
				<AS.FlexRow isCentered>
					<AS.Text fontSize="20px"> search for your nft</AS.Text>
				</AS.FlexRow>
				<Spacer height={10} />
				<AS.FlexRow isCentered>
					<AS.Text fontFamily="Helvetica Neue" color="black" fontSize="18px">
						Select the NFT's Platform
					</AS.Text>
				</AS.FlexRow>
				<Spacer height={20} />
				<S.PlatformRow>
					<AS.FlexRow isCentered>
						<S.PlatFormDiv
							style={{
								backgroundColor: selectedPlatform === 'the-sandbox' && '#1cbf7b',
								color: selectedPlatform === 'the-sandbox' && 'white',
							}}
							onClick={() => setSelectedPlatform('the-sandbox')}
						>
							<S.PlatformImages src={TheSandbox} alt="TheSandbox" />
						</S.PlatFormDiv>
					</AS.FlexRow>
					<AS.FlexRow isCentered>
						<S.PlatFormDiv
							style={{
								backgroundColor: selectedPlatform === 'voxels' && '#1cbf7b',
								color: selectedPlatform === 'voxels' && 'white',
							}}
							onClick={() => setSelectedPlatform('voxels')}
						>
							<S.PlatformImages src={Voxels} alt="Voxels" style={{ width: '15%' }} />
						</S.PlatFormDiv>
					</AS.FlexRow>
					<AS.FlexRow isCentered>
						<S.PlatFormDiv
							style={{
								backgroundColor: selectedPlatform === 'decentraland' && '#1cbf7b',
								color: selectedPlatform === 'decentraland' && 'white',
							}}
							onClick={() => setSelectedPlatform('decentraland')}
						>
							<S.PlatformImages src={Decentraland} alt="Decentraland" style={{ width: '60%' }} />
						</S.PlatFormDiv>
					</AS.FlexRow>
					<AS.FlexRow isCentered>
						<S.PlatFormDiv
							style={{
								backgroundColor: selectedPlatform === 'somnium-space' && '#1cbf7b',
								color: selectedPlatform === 'somnium-space' && 'white',
							}}
							onClick={() => setSelectedPlatform('somnium-space')}
						>
							<S.PlatformImages src={SomniumSpace} alt="SomniumSpace" style={{ width: '30%' }} />
						</S.PlatFormDiv>
					</AS.FlexRow>
				</S.PlatformRow>
				<Spacer height={40} />
				<AS.FlexRow isCentered>
					<AS.Text fontFamily="Helvetica Neue" color="black" fontSize="18px">
						Input the NFT's Token ID
					</AS.Text>
				</AS.FlexRow>
				<Spacer height={15} />
				<Input
					inputStyle={{ width: '60%' }}
					placeholder="NFT ID"
					min="0"
					type="number"
					value={NFTID}
					onChange={e => {
						setNFTID(e.target.value);
					}}
				/>
				<Spacer height={20} />
				<ButtonLoader apiCall={async () => await findNFTFunc()}>
					<Button
						text="Search"
						style={{
							borderRadius: '0',
							fontFamily: 'Helvetica Neue',
							height: '24px',
							padding: '20px 30px',
							color: 'white',
						}}
						backgroundColor="#1cbf7b"
						width="164px"
					/>
				</ButtonLoader>
			</AS.Column>
		</Modal>
	);
});

export default CantFindNFTModal;
