import axios from 'axios';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';

import { setNotification } from 'state/features/notificationSlice';

import './styles.css';

const getSupportSelectStyles = supportMenuOpen => ({
	control: base => ({
		...base,
		backgroundColor: 'transparent',
		padding: '9px 16px',
		borderRadius: '40px',
		boxShadow: 'none',
		fontSize: '18px',
		cursor: 'pointer',
		display: 'flex',
		overflow: 'hidden',
		position: 'relative',
		zIndex: 4,
		lineHeight: 1,
		transition: 'all 0.3s ease-in-out',
		alignItems: 'center',
		userSelect: 'none',
		border: '1px solid #dbdbdb',
	}),
	menuPortal: base => ({ ...base, zIndex: 9999 }),
	valueContainer: styles => {
		return {
			...styles,
			color: 'white',
			padding: 0,
		};
	},
	singleValue: styles => {
		return {
			...styles,
			color: '#111',
			fontFamily: 'Helvetica Neue',
			fontSize: '16px',
			padding: '2px',
		};
	},
	placeholder: base => ({
		...base,
		color: '#8e8e8e',
		marginRight: '10px',
		fontFamily: 'Helvetica Neue',
		fontSize: '16px',
		zIndex: 5,
		transition: 'all 0.3s ease-in-out',
	}),
	indicatorSeparator: () => ({ display: 'none' }),
	dropdownIndicator: base => ({
		...base,
		color: 'black',
		padding: '2px 2px 2px 0',
		transition: 'all 0.3s ease-in-out',
		fontSize: '16px',
		transform: supportMenuOpen ? 'rotate(180deg)' : null,
	}),
	option: styles => {
		return {
			...styles,
			position: 'relative',
			backgroundColor: 'white',
			color: '#8e8e8e',
			fontFamily: 'Helvetica Neue',
			fontSize: '16px',
			padding: '8px 18px',
			cursor: 'pointer',
			transition: 'all 0.3s ease-in-out',
			maxWidth: '100%',
			'&:hover': {
				backgroundColor: '#E8F9F2',
			},
		};
	},
	menuList: base => ({
		...base,
		height: '100%',
		maxHeight: '300px',
		paddingTop: 0,
		paddingBottom: 0,
		zIndex: 3,
		boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.12)',
		borderRadius: '8px',
		'&::-webkit-scrollbar-track': {
			backgroundColor: 'whitesmoke',
			borderRadius: '7px',
			marginRight: '2px',
			'&:hover': {
				backgroundColor: 'whitesmoke',
			},
		},
		'&::-webkit-scrollbar': {
			width: '6px',
			backgroundColor: 'whitesmoke',
			'&:hover': { backgroundColor: 'whitesmoke' },
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: '#8e8e8e',
			borderRadius: '7px',
			transition: 'all 0.3s ease-in-out',
			'&:hover': { backgroundColor: '#757575', cursor: 'pointer !important' },
		},
	}),
});

const categories = [
	{ value: 'myProfile', label: 'My Profile' },
	{ value: 'generalInformation', label: 'General Information' },
	{ value: 'buying', label: 'Buying' },
	{ value: 'selling', label: 'Selling' },
	{ value: 'transactionError', label: 'Transaction Error' },
	{ value: 'reportABug', label: 'Report a Bug' },
	{ value: 'verificationProcess', label: 'Verification Process' },
	{ value: 'partnerships', label: 'Partnerships' },
	{ value: 'userSafety', label: 'User Safety' },
	{ value: 'requestForTakedown', label: 'Request for Takedown' },
	{ value: 'emailResubscription', label: 'Email Resubscription' },
	{ value: 'other', label: 'Other' },
];

const defaultFormValues = {
	category: '',
	email: '',
	wallet: '',
	subject: '',
	description: '',
};

const Support = () => {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const [supportMenuOpen, setSupportMenuOpen] = useState(false);
	const [formValues, setFormValues] = useState(defaultFormValues);

	const supportSelectStyles = useMemo(() => getSupportSelectStyles(supportMenuOpen), [supportMenuOpen]);

	const handleCategoryChange = useCallback(option => {
		setFormValues(formValues => ({ ...formValues, category: option.value }));
	}, []);

	const handleChange = useCallback(event => {
		setFormValues(formValues => ({ ...formValues, [event.target.name]: event.target.value }));
	}, []);

	const handleSubmit = useCallback(
		async event => {
			event.preventDefault();
			setIsLoading(true);

			if (!formValues.category) {
				dispatch(setNotification({ msg: 'Category is required', alertType: 'error' }));

				setIsLoading(false);
				return;
			}

			const regex = /^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i;
			if (!formValues.email || regex.test(formValues.email) === false) {
				dispatch(setNotification({ msg: 'Email is not valid', alertType: 'error' }));
				setIsLoading(false);

				return false;
			}

			const response = await axios.post('https://launchpad.webisoft.org/api/terrena/support', formValues, {
				headers: { 'Content-Type': 'application/json' },
			});

			if (response.status === 200) {
				dispatch(setNotification({ msg: 'Support message sent successfully!', alertType: 'success' }));
				setFormValues(defaultFormValues);
			}

			setIsLoading(false);
		},
		[dispatch, formValues]
	);

	return (
		<form className="support" onSubmit={handleSubmit}>
			<h1>support</h1>
			<div className="field-group">
				<label htmlFor="category">what can we help with?*</label>
				<Select
					id="category"
					required
					styles={supportSelectStyles}
					placeholder="Select category"
					options={categories}
					onChange={handleCategoryChange}
					closeMenuOnSelect={true}
					value={categories[formValues.category]}
					onMenuOpen={() => setSupportMenuOpen(true)}
					onMenuClose={() => setSupportMenuOpen(false)}
					classNamePrefix="select"
					className="react-select"
					menuPortalTarget={document.body}
				/>
			</div>
			<div className="field-group">
				<label htmlFor="email">what is your email address?*</label>
				<input
					id="email"
					name="email"
					type="email"
					required
					value={formValues.email}
					onChange={handleChange}
					placeholder="Enter email address here"
				/>
			</div>
			<div className="field-group">
				<label htmlFor="wallet">what is your crypto wallet address?</label>
				<input
					id="wallet"
					name="wallet"
					type="text"
					value={formValues.text}
					onChange={handleChange}
					placeholder="Enter wallet address here"
				/>
			</div>
			<div className="field-group">
				<label htmlFor="subject">subject*</label>
				<input
					id="subject"
					name="subject"
					type="text"
					required
					value={formValues.text}
					onChange={handleChange}
					placeholder="Enter subject here"
				/>
			</div>
			<div className="field-group">
				<label htmlFor="description">description</label>
				<textarea
					id="description"
					name="description"
					value={formValues.description}
					onChange={handleChange}
					placeholder="Enter description here"
				/>
			</div>
			<button className="btn" disabled={isLoading}>
				{isLoading && (
					<svg width={20} height={20} viewBox="-5 -5 48 48" stroke="currentColor">
						<g transform="translate(1 1)" strokeWidth={4} fill="none" fillRule="evenodd">
							<circle strokeOpacity={0.5} cx={18} cy={18} r={18} />
							<path d="M36 18c0-9.94-8.06-18-18-18">
								<animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite" />
							</path>
						</g>
					</svg>
				)}
				{isLoading ? 'Sending...' : 'Send'}
			</button>
		</form>
	);
};

export default Support;
