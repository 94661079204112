import { findImageUrlInMetadata } from './findImageUrlInMetadata';
import { findTitleInMetadata } from './findTitleInMetadata';
import { safeIpfsUrl } from './safeIpfsUrl';

const findCoordinateVoxels = (name, image) => {
	const firstIndex = image.indexOf(`${name}=`);
	let lastIndex = image.indexOf('&', firstIndex);
	lastIndex = lastIndex === -1 ? image.length : lastIndex;
	const strValue = image.slice(firstIndex + 2, lastIndex);
	return parseFloat(strValue);
};

const findCoordinateSomnium = (name, description) => {
	const firstIndex = description.indexOf(`${name} = `);
	let lastIndex = description.indexOf(',', firstIndex);
	lastIndex = lastIndex === -1 ? description.length : lastIndex;
	const strValue = description.slice(firstIndex + 4, lastIndex);
	return parseFloat(strValue);
};

const findCoordinatesInSandbox = title => {
	const firstIndex = title.indexOf('(');
	let lastIndex = title.indexOf(')');
	lastIndex = lastIndex === -1 ? title.length : lastIndex;
	const strValue = title.slice(firstIndex + 1, lastIndex);
	const newStringArray = strValue.split(', ');
	const newNumberArray = newStringArray.map(string => {
		return parseFloat(string);
	});
	return {
		x: newNumberArray[0] + 204,
		y: newNumberArray[1] + 204,
	};
};

export const parsePlatformMetadata = (metadata, platform) => {
	let props = {};
	try {
		props = {
			...props,
			title: findTitleInMetadata(metadata),
			image: safeIpfsUrl(findImageUrlInMetadata(metadata)),
			description: metadata?.description ?? 'No Description',
		};
		if (platform === 'decentraland') {
			const xValue = metadata.attributes[0]?.value;
			const yValue = metadata.attributes[1]?.value;
			const distanceToDistrict = metadata.attributes.find(attribute => attribute?.trait_type === 'Distance to District');
			const distanceToPlaza = metadata.attributes.find(attribute => attribute?.trait_type === 'Distance to Plaza');
			const distanceToRoad = metadata.attributes.find(attribute => attribute?.trait_type === 'Distance to Road');
			props = {
				...props,
				coordinates: `${xValue}, ${yValue}`,
				xValue,
				yValue,
				distanceToDistrict: distanceToDistrict?.value,
				distanceToPlaza: distanceToPlaza?.value,
				distanceToRoad: distanceToRoad?.value,
			};
		} else if (platform === 'somnium-space') {
			const xValue = findCoordinateSomnium('X', metadata.description);
			const yValue = findCoordinateSomnium('Y', metadata.description);
			const zValue = findCoordinateSomnium('Z', metadata.description);
			const parcelSize = metadata.attributes.find(attribute => attribute?.trait_type === 'Parcel size');
			const waterfrontStatus = metadata.attributes.find(attribute => attribute?.trait_type === 'waterfront_status');
			const roadSideStatus = metadata.attributes.find(attribute => attribute?.trait_type === 'roadside_status');
			props = {
				...props,
				coordinates: `${xValue}, ${yValue}, ${zValue}`,
				xValue,
				yValue,
				zValue,
				parcelSize: parcelSize?.value,
				waterfrontStatus: waterfrontStatus?.value,
				roadSideStatus: roadSideStatus?.value,
			};
		} else if (platform === 'the-sandbox') {
			const { x, y } = findCoordinatesInSandbox(props.title);
			props = { ...props, coordinates: `${x}, ${y}`, xValue: x, yValue: y };
		} else if (platform === 'voxels') {
			const xValue = findCoordinateVoxels('x', props.image);
			const yValue = findCoordinateVoxels('y', props.image);
			const size = metadata.attributes.title;
			const island = metadata.attributes.island;
			const suburb = metadata.attributes.suburb;
			props = {
				...props,
				coordinates: `${xValue}, ${yValue}`,
				xValue,
				yValue,
				size,
				island,
				suburb,
			};
		} else {
			throw new Error('Platform not recognized!');
		}
	} catch (error) {
		console.error(error);
	}
	return props;
};
