import { ethers } from 'ethers';

import { testMode } from './testMode';

const PROVIDER_KEY = '196be4c193ba4136a127df592871bb86';

export const RPC_URLS = {
	1: `https://mainnet.infura.io/v3/${PROVIDER_KEY}`,
	4: `https://goerli.infura.io/v3/${PROVIDER_KEY}`,
};

export const ETHProvider = () => {
	return new ethers.providers.InfuraProvider(testMode ? 'goerli' : 'mainnet', PROVIDER_KEY);
};

export const ETHProviderWithWallet = () => {
	return new ethers.providers.Web3Provider(window.ethereum, 'any');
};
