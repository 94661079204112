import { device } from 'device-dimensions';
import styled, { css } from 'styled-components';

import { MainContainer } from 'pages/Home/styles';

import { Input } from 'components/Input/styles';

import * as AS from 'styles/AppStyles';

import SearchIcon from 'images/Search-icon.svg';

export const MarketplaceBody = styled.div`
	height: 100%;
	@media ${device.mobileL} {
		${({ openSideNav }) =>
			openSideNav &&
			css`
				overflow-y: hidden !important;
			`}
	}
`;

export const MarketplaceContainer = styled(MainContainer)`
	padding: 4%;

	@media ${device.mobileL} {
		padding: 12% 4% 12% 4%;
	}
`;

export const Divider = styled.div`
	height: 1px;
	background-color: #8e8e8e;
	text-align: center;
`;

export const LogoMain = styled.img`
	width: 10%;
	@media ${device.mobileL} {
		width: 30%;
		margin-bottom: 5px;
	}
`;

export const Header = styled(AS.Text)`
	font-size: 40px;
	color: black;
	user-select: none;
	@media ${device.mobileL} {
		font-size: 30px;
	}
`;

export const SubHeader = styled(AS.Text)`
	font-size: 18px;
	color: black;
	font-family: Helvetica Neue;
	user-select: none;
	@media ${device.mobileL} {
		text-align: center;
	}
`;

export const ListingsContainer = styled.div`
	display: flex;
	background-color: #ffffff;
	border-top: 1px solid #ffffff;
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 2;
	outline: red;
`;

export const SubContainer = styled(AS.Column)`
	@media ${device.mobileL} {
		width: 100%;
	}
	${({ openSideNav }) =>
		openSideNav
			? css`
					width: calc(70%);
			  `
			: css`
					width: calc(100% - 54px);
			  `}
`;

export const SortByRow = styled.div`
	position: relative;
	display: grid;
	grid-template-columns: 0.4fr 0.2fr 0.4fr;
	grid-gap: 10px;
	width: 100%;
	min-width: 100%;
	padding: 35px 70px 10px 70px;
	height: auto;
	@media ${device.mobileL} {
		grid-template-columns: none;
		padding: 40px 15px 0px 15px;
		grid-gap: 20px;
		${({ openSideNav }) =>
			openSideNav &&
			css`
				display: none;
			`}
	}
`;

export const ColumnSpacer = styled.div`
	@media ${device.mobileL} {
		display: none;
	}
`;

export const SearchInputDiv = styled.div`
	position: relative;
`;

export const SearchInputIcon = styled.div`
	position: absolute;
	background: url(${SearchIcon}) no-repeat;
	background-size: 21px 21px;
	z-index: 4;
	left: 13px;
	top: 11px;
	width: 25px;
	height: 25px;
`;

export const SearchInput = styled(Input)`
	background-color: white;
	color: #8e8e8e;
	font-size: 16px;
	font-family: Helvetica Neue, sans-serif;
	height: 24px;
	border-radius: 40px;
	padding: 20px 30px 20px 40px;
	border: 1px solid #8e8e8e;
	transition: all 0.3s ease-in-out;
	width: 100%;
	position: relative;
	&::placeholder {
		color: #8e8e8e;
	}
`;

export const DropDownRow = styled(AS.FlexRow)`
	justify-content: space-evenly;
	align-items: flex-start;
	gap: 10px;
	@media ${device.mobileL} {
		flex-direction: column;
		gap: 20px;
	}
`;

export const SelectRow = styled(AS.FlexRow)`
	justify-content: center;
	align-items: flex-start;
	& > .react-select {
		& .select__input {
			color: transparent !important;
		}
	}
	@media ${device.mobileL} {
		width: 100%;
	}
`;

export const ReorderIconsRow = styled(AS.FlexRow)`
	height: 45px;
	justify-content: space-between;
	border-radius: 40px;
	border: 1px solid #dbdbdb;
	width: 120px;
	position: relative;
	&::before {
		content: '';
		width: 1px;
		height: 100%;
		background-color: #8e8e8e;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
	}
	@media ${device.mobileL} {
		display: none;
	}
`;

export const GridIcon = styled.div`
	color: #8e8e8e;
	width: 60px;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	${({ gridSelected }) =>
		gridSelected
			? css`
					background-color: #f4fcf8;
					color: #1cbf7b;
					border-top-right-radius: 40px;
					border-bottom-right-radius: 40px;
					&:hover {
						cursor: pointer;
					}
			  `
			: css`
					&:hover {
						cursor: pointer;
						color: #1cbf7b;
					}
			  `}
`;

export const GridAltIcon = styled.div`
	color: #8e8e8e;
	width: 60px;
	-webkit-transition: all 0.3s ease-in-out;
	transition: color 0.3s ease-in-out, background-color 0.1s ease-in-out;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	${({ gridAltSelected }) =>
		gridAltSelected
			? css`
					background-color: #f4fcf8;
					color: #1cbf7b;
					border-top-left-radius: 40px;
					border-bottom-left-radius: 40px;
					&:hover {
						cursor: pointer;
					}
			  `
			: css`
					&:hover {
						cursor: pointer;
						color: #1cbf7b;
					}
			  `}
`;

export const ArrowheadUpOutlineDiv = styled.div`
	position: absolute;
	top: 0;
	right: 25px;
	padding: 5px;
	color: black;
	border-radius: 50%;
	transition: all 0.3s ease-in-out;
	&:hover {
		cursor: pointer;
		background-color: #1cbf7b;
	}
`;

export const ListingsFoundDiv = styled(AS.FlexRow)`
	gap: 15px;
	padding-left: 55px;
	@media ${device.mobileL} {
		justify-content: center;
		padding-left: 0;
	}
`;

export const RefreshOutlineDiv = styled.div`
	width: 25px;
	color: black;
	transition: all 0.1s ease-in-out;
	&:hover {
		cursor: pointer;
		transform: scale(1.1);
	}
`;

export const ListingsCardRow = styled.div`
	display: grid;
	grid-gap: 15px;
	padding: 20px 25px 40px 50px;
	align-items: flex-start;
	${({ gridSelected }) =>
		gridSelected
			? css`
					grid-template-columns: repeat(6, minmax(0, 1fr));
			  `
			: css`
					grid-template-columns: repeat(3, minmax(0, 1fr));
			  `}
	@media ${device.mobileL} {
		padding: 15px;
		grid-template-columns: none;
		grid-template-rows: 1fr;
	}
`;
