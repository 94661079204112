import { StyledDiscord, StyledLinkedIn, StyledTwitter } from 'components/Icons/Icons.Styles';

const IconsRow = ({ whiteBG }) => {
	return (
		<>
			<StyledTwitter
				data-white-bg={whiteBG}
				onClick={() => {
					window.open('https://twitter.com/terrenaio?s=11&t=BLLO2Ylg9GhygjL6ThvVSQ');
				}}
			/>
			<StyledDiscord
				data-white-bg={whiteBG}
				onClick={() => {
					window.open('https://discord.gg/D2MYtfa6Uh');
				}}
			/>
			<StyledLinkedIn
				data-white-bg={whiteBG}
				onClick={() => {
					window.open('https://www.linkedin.com/company/terrenaio/');
				}}
			/>
		</>
	);
};

export default IconsRow;
