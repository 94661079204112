import styled from 'styled-components/macro';

import * as AS from 'styles/AppStyles';

export const Input = styled.input`
	background: white;
	border-radius: 40px;
	transition: all 0.3s;
	outline: none;
	border: 1px solid #1cbf7b;
	width: 400px;
	padding: 16px;
	color: #8e8e8e;
	font-size: 16px;
	font-family: 'Helvetica Neue';
	&::placeholder {
		color: #8e8e8e;
		font-weight: 400;
		font-size: 16px;
		font-family: 'Helvetica Neue';
	}
	&:focus {
		border: 1px solid #17a067;
	}
	&:focus-visible {
		border: 1px solid #137c50;
	}
`;
export const InputWrapper = styled(AS.Row)`
	transition: all 0.3s;
	border-bottom: 1px solid rgba(255, 255, 255, 0.33);
	align-items: center;
`;
