import { configureStore } from '@reduxjs/toolkit';

import NFTsReducer from './features/NFTSlice';
import inViewReducer from './features/inViewSlice';
import loadingReducer from './features/loadingSlice';
import marketplaceContractReducer from './features/marketplaceContractSlice';
import notificationsReducer from './features/notificationSlice';
import walletReducer from './features/walletSlice';

export const store = configureStore({
	reducer: {
		notifications: notificationsReducer,
		inView: inViewReducer,
		NFTs: NFTsReducer,
		wallet: walletReducer,
		marketplaceContract: marketplaceContractReducer,
		loading: loadingReducer,
	},
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
	devTools: process.env.NODE_ENV !== 'production',
});
