import axios from 'axios';

export const getAllMetadata = async content => {
	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	};

	const res = await axios.post(`https://launchpad.webisoft.org/api/terrena/metadata`, content, config);
	return res.data;
};

export const sendTokenMetadata = async (address, tokenId) => {
	const res = await axios.get(`https://launchpad.webisoft.org/api/terrena/metadata/${address}/${tokenId}`);
	return res.data;
};
