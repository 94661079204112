import { useNavigate } from 'react-router-dom';

import { Button } from 'components/Button/Button';
import { Spacer } from 'components/Spacer/Spacer';

import * as AS from 'styles/AppStyles';

import * as S from './styles';

const NFTNotFound = () => {
	let navigate = useNavigate();

	return (
		<S.Container>
			<AS.Text color="black" fontSize="25px">
				NFT Not Found!
			</AS.Text>
			<Spacer height={30} />
			<Button width="350px" text="return to the homepage" onClick={() => navigate('/')} />
		</S.Container>
	);
};

export default NFTNotFound;
