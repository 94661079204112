import { Spacer } from 'components/Spacer/Spacer';

import * as AS from 'styles/AppStyles';

import VirtualCity from 'images/VirtualCity.webp';

import * as S from './styles';

const FutureRealEstate = () => {
	return (
		<S.FutureRealEstateContainer>
			<S.FutureRealEstateColumn>
				<AS.Text userSelectNone fontSize="36px" color="black">
					welcome to the future of&nbsp;
				</AS.Text>
				<AS.Text fontSize="36px">virtual real estate</AS.Text>
			</S.FutureRealEstateColumn>
			<Spacer height={25} />
			<AS.FlexRow>
				<AS.Text userSelectNone fontSize="18px" color="black" fontFamily="Helvetica Neue">
					At Terrena, we believe that the Real Estate landscape is evolving before our eyes and is beginning to include Digital Assets.
				</AS.Text>
			</AS.FlexRow>
			<Spacer height={25} />
			<AS.FlexRow>
				<AS.Text userSelectNone fontSize="18px" color="black" fontFamily="Helvetica Neue">
					The possibility of ownership over digital LAND brings with it unprecedented opportunity. As the technology and space evolves, the
					use cases and experiences harboured in these unique assets will be endless and only limited to the owners imagination.
				</AS.Text>
			</AS.FlexRow>
			<Spacer height={25} />
			<AS.FlexRow>
				<AS.Text userSelectNone fontSize="18px" color="black" fontFamily="Helvetica Neue">
					Only one question remains;
				</AS.Text>
			</AS.FlexRow>
			<AS.FlexRow>
				<AS.Text userSelectNone fontSize="18px" color="black" fontFamily="Helvetica Neue">
					What will you build?
				</AS.Text>
			</AS.FlexRow>
			<Spacer height={60} />
			<AS.FlexRow isCentered>
				<img src={VirtualCity} alt="VirtualCity" />
			</AS.FlexRow>
		</S.FutureRealEstateContainer>
	);
};

export default FutureRealEstate;
