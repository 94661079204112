import Collapsible from 'react-collapsible';
import { Link } from 'react-router-dom';

import logoIcon from 'images/logo-icon.webp';

const faqs = [
	{
		title: 'What is TERRENA?',
		content: (
			<>
				<p>
					TERRENA is an NFT Marketplace catered specifically for LAND plots in various metaverses. Our initial version of TERRENA will allow
					users to buy, sell and auction LAND NFTs on a few virtual blockchain worlds.
				</p>
				<p>
					Our mission is to grow the marketplace into a hub for virtual real estate; a platform where investors, virtual architects and
					developers can connect and provide value for one another, as well as build an ecosystem that users can benefit from.
				</p>
			</>
		),
	},
	{
		title: 'What makes TERRENA different from other marketplaces?',
		content: (
			<>
				<p>
					Our platform will only support the listing of contract addresses for the supported virtual worlds. This will reduce the number of
					scam/fake listings that would be possible on other open marketplaces.
				</p>
				<p>
					The platform will also introduce an authentic auctioning system that will allow users to place their NFTs for sale and auction
					simultaneously.
				</p>
				<p>TERRENA may start off as an NFT marketplace, but we are working to expand it into much more than that.</p>
			</>
		),
	},
	{
		title: 'How much are the marketplace fees?',
		content: (
			<p>
				During the open beta period, the marketplace will be free, meaning that we will not charge a marketplace fee for any listings or
				transactions. This however does not apply to any creator fees or gas fees required by the blockchain.
			</p>
		),
	},
	{
		title: 'What’s in it for me?',
		content: (
			<p>
				Aside from benefiting from a free marketplace, you will be a part of our early community. As soon as our team is in a position to
				give back to the community, especially those that supported us from the beginning, we will. You will also have the opportunity to be
				a part of our initial feedback group, which will come with its own sets of benefits as well.
			</p>
		),
	},
	{
		title: 'What blockchain will/does TERRENA support?',
		content: (
			<p>
				The marketplace will initially be built on the Ethereum blockchain (Layer 1). We will soon be working on cross-chain compatibility
				as well as integrating Layer 2 solutions such as Polygon.
			</p>
		),
	},
	{ title: 'Which virtual worlds does TERRENA support?', content: `For now; Decentraland, Sandbox, Crypto Voxels, Somnium Space` },
	{
		title: 'Which wallets are supported?',
		content: <p>We are currently supporting Metamask and Coinbase Wallet. We will be supporting additional wallets in the future.</p>,
	},
	{
		title: 'If I list on TERRENA, does it mean I can’t list on other marketplaces?',
		content: <p>Not at all. You can list your NFT simultaneously on various marketplaces.</p>,
	},
	{
		title: 'How does your auction system work?',
		content: (
			<p>
				You can list your NFT for a fixed price, as well as list it for auction with a starting price. You may do one or the other, or both
				simultaneously; as you please.
			</p>
		),
	},
	{
		title: 'How can I contact TERRENA?',
		content: (
			<p>
				TERRENA will have a support page that will allow you to contact our support staff via email. We also have support on Discord. Check
				#terrena-support and #discord-support for help.
			</p>
		),
	},
];

export const FAQ = () => {
	return (
		<section className="faq">
			<div className="banner">
				<img src={logoIcon} alt="Logo" />
				<h1>Frequently asked questions</h1>
			</div>
			<div className="main-content">
				<ul className="content-list">
					{faqs.map(({ title, content }, index) => (
						<li key={title}>
							<Collapsible
								open={index === 0}
								trigger={
									<button className="title">
										<strong>{title}</strong>
										<svg width={14} height={9}>
											<path
												d="M13.57 7.21 7.734 1.374S6.999.44 6.1 1.47L.681 6.973c-.229.232-.35.561-.288.882.042.215.162.43.45.548.721.297 1.154-.177 1.154-.177l4.976-4.992 5.01 4.992s.593.555 1.17.177c.576-.376.49-1.122.416-1.194"
												fill="#111"
											/>
										</svg>
									</button>
								}
							>
								<div className="content">{content}</div>
							</Collapsible>
						</li>
					))}
				</ul>
			</div>

			<section className="support-banner">
				<h1>Did you solve the problem?</h1>
				<Link className="btn-link" to="/support">
					Go to Support
				</Link>
			</section>
		</section>
	);
};
