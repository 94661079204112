import Notification from 'components/Notification/Notification';

import ScrollToTop from 'utils/scrollToTop';

import GlobalFonts from 'fonts/Fonts';

import GlobalStyles from 'styles/GlobalStyles';

import { Routes } from './routes';
import { NFTStateWrapper } from './wrappers/NFTStateWrapper';

export const App = () => (
	<>
		<GlobalFonts />
		<GlobalStyles />
		<NFTStateWrapper>
			<Routes>
				<Notification />
				<ScrollToTop />
			</Routes>
		</NFTStateWrapper>
	</>
);
