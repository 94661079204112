import { useState } from 'react';
import { useDispatch } from 'react-redux';

import ButtonSpinner from 'components/Common/ButtonSpinner/ButtonSpinner';

import { setNotification } from 'state/features/notificationSlice';

import * as S from './styles';

const ButtonLoader = ({ apiCall, children }) => {
	const dispatch = useDispatch();

	const [isLoading, setIsLoading] = useState(false);

	const onClickHandler = async () => {
		try {
			setIsLoading(true);
			await apiCall();
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			const errors = error?.response?.data?.errors;
			if (errors && Array.isArray(errors)) {
				(errors || []).forEach(error =>
					dispatch(
						setNotification({
							msg: error.msg,
							alertType: 'error',
						})
					)
				);
			}
		}
	};

	return (
		<S.Loader
			onClick={() => {
				if (isLoading) return;
				onClickHandler();
			}}
		>
			{children}
			<div
				style={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					display: isLoading ? 'block' : 'none',
					transition: '.4s all',
				}}
			>
				<ButtonSpinner />
			</div>
		</S.Loader>
	);
};

export default ButtonLoader;
