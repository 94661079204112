import { RefreshOutline } from '@styled-icons/evaicons-outline/RefreshOutline';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setNotification } from 'state/features/notificationSlice';

import * as AS from 'styles/AppStyles';

import ListingsCard from '../ListingsCard';
import * as S from '../styles';

const ProfileForSale = ({ gridSelected, platformFilter, searchNFTsValue }) => {
	const dispatch = useDispatch();
	const [filteredNfts, setFilteredNfts] = useState([]);

	const allNFTs = useSelector(state => state.NFTs.value);

	// Wallet Pub Key

	const walletPubKey = useSelector(state => state.wallet.address);

	useEffect(() => {
		const newNftArray = (allNFTs || []).filter(nft => {
			const b1 = nft.details.isActive;
			const b2 = nft.details.endDate.toNumber() * 1000 > Date.now();
			const b3 = nft.details.seller === walletPubKey;
			const b4 = platformFilter === 'all' ? true : nft.platform === platformFilter;
			const b5 = searchNFTsValue === '' ? true : nft.title?.toLowerCase().includes(searchNFTsValue.toLowerCase());
			return b1 && b2 && b3 && b4 && b5;
		});
		setFilteredNfts(newNftArray);
	}, [platformFilter, allNFTs, searchNFTsValue]);

	return (
		<>
			<S.ListingsFoundDiv>
				<S.RefreshOutlineDiv
					onClick={() =>
						dispatch(
							setNotification({
								msg: 'We have a queued an update for you! Please check back later...',
								alertType: 'success',
							})
						)
					}
				>
					<RefreshOutline />
				</S.RefreshOutlineDiv>
				<AS.Text color="black" fontSize="18px" fontFamily="Helvetica Neue" userSelectNone>
					<span style={{ fontWeight: 'bold' }}>{filteredNfts.length}</span> Listings Found
				</AS.Text>
			</S.ListingsFoundDiv>
			<S.ListingsCardRow gridSelected={gridSelected}>
				{filteredNfts?.map((nft, index) => {
					return <ListingsCard key={nft.id} noHover={false} nft={nft} />;
				})}
			</S.ListingsCardRow>
		</>
	);
};

export default ProfileForSale;
