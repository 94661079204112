import { device } from 'device-dimensions';
import styled, { css } from 'styled-components/macro';

import { ShareNFTDiv } from 'pages/Storefront/styles';

import { Spacer } from 'components/Spacer/styles';

import * as AS from 'styles/AppStyles';

export const ProfileContainer = styled.div`
	position: relative;
	@media ${device.mobileL} {
		padding: 0 20px;
	}
`;

export const ProfileSpacer = styled(Spacer)`
	height: 100px;
	@media ${device.mobileL} {
		height: 75px;
	}
`;

export const ProfileSpacer2 = styled(Spacer)`
	height: 20px;
	@media ${device.mobileL} {
		height: 10px;
	}
`;

export const ShareProfileDiv = styled(ShareNFTDiv)`
	top: -75px;
	right: 75px;
	@media ${device.mobileL} {
		right: 15px;
		top: -25px;
	}
`;

export const Banner = styled.div`
	width: 100%;
	height: 270px;
	position: relative;
`;

export const AvatarDiv = styled.div`
	position: absolute;
	bottom: -75px;
	left: 50%;
	transform: translateX(-50%);
	width: 160px;
	height: 160px;
	background-color: rgb(244, 244, 244);
	border-radius: 50%;
	border: 4px solid #ffffff;
`;

export const NameRow = styled(AS.FlexRow)`
	justify-content: center;
	@media ${device.mobileL} {
		justify-content: flex-start;
	}
`;

export const WalletRow = styled(AS.FlexRow)`
	justify-content: center;
	@media ${device.mobileL} {
		justify-content: flex-start;
	}
`;

export const WalletDiv = styled.div`
	display: flex;
	justify-content: center;
	width: auto;
	border: 1px solid #8e8e8e;
	color: #8e8e8e;
	border-radius: 20px;
	padding: 10px 20px;
	background-color: white;
	@media ${device.mobileL} {
		width: 75%;
	}
`;

export const SummaryCard = styled.div`
	display: grid;
	grid-template-columns: repeat(4, minmax(0, 1fr));
	border: 1px solid #dbdbdb;
	border-radius: 20px;
	width: 40%;
	height: 100px;
	@media ${device.mobileL} {
		grid-template-columns: repeat(2, minmax(0, 1fr));
		grid-template-rows: repeat(2, minmax(0, 1fr));
		width: 100%;
		height: 150px;
	}
`;

export const SummaryItem = styled(AS.Column)`
	position: relative;
	gap: 5px;
	padding: 15px 10px;
	align-items: center;
	justify-content: center;
	&:not(:last-child)::after {
		content: '';
		width: 1px;
		height: 65%;
		background: #dbdbdb;
		position: absolute;
		right: 5px;
		@media ${device.mobileL} {
			display: none;
		}
	}
	@media ${device.mobileL} {
		&:nth-child(odd)::before {
			content: '';
			width: 1px;
			height: 65%;
			background: #dbdbdb;
			position: absolute;
			right: 5px;
		}
	}
`;

export const TextDiv = styled.div`
	width: 65%;
	@media ${device.mobileL} {
		width: 100%;
	}
`;

export const NFTFiltersDiv = styled.div`
	display: flex;
	width: 65%;
	justify-content: space-evenly;
	height: 60px;
	align-items: center;
	@media ${device.mobileL} {
		display: grid;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		grid-template-rows: repeat(2, minmax(0, 1fr));
		width: 100%;
		height: 100%;
		text-align: center;
	}
`;

export const NFTFiltersText = styled(AS.Text)`
	color: black;
	font-size: 18px;
	padding: 16px 32px;
	transition: all 0.2s ease-in-out;
	${({ filtered }) =>
		filtered &&
		css`
			background-color: #1cbf7b;
			color: white;
			border-radius: 40px;
			&:hover {
				background-color: #1cbf7b !important;
			}
		`}
	&:hover {
		cursor: pointer;
		background-color: #a5ebce;
		border-radius: 40px;
	}
`;

export const Divider = styled.div`
	width: 100%;
	height: 1px;
	background: #dbdbdb;
`;
