import { Spacer } from 'components/Spacer/Spacer';

import * as AS from 'styles/AppStyles';

import * as S from './styles';

const Metadata = ({ storefrontNFT }) => {
	return (
		<S.MetaDataContainer>
			<S.MetaDataCard>
				<S.MetaDataSandboxRow>
					<S.MetaDataColumn>
						<AS.Text color="black" fontSize="24px" userSelectNone>
							metadata
						</AS.Text>
						<Spacer height={20} />
						<S.MetaDataSubRow>
							<AS.Text color="black" fontSize="18px" userSelectNone>
								contract address:
							</AS.Text>
							<AS.Text color="black" fontSize="18px" fontFamily="Helvetica Neue" style={{ textAlign: 'center' }}>
								{storefrontNFT.token.nft.toString()}
							</AS.Text>
							<S.SpacerDiv />
						</S.MetaDataSubRow>
						<Spacer height={20} />
						<S.MetaDataSubRow>
							<AS.Text color="black" fontSize="18px" userSelectNone>
								token id:
							</AS.Text>
							<AS.Text color="black" fontSize="18px" fontFamily="Helvetica Neue" style={{ textAlign: 'center' }}>
								{storefrontNFT.token.tokenId.toString()}
							</AS.Text>
							<S.SpacerDiv />
						</S.MetaDataSubRow>
						<Spacer height={20} />
						<S.MetaDataSubRow>
							<AS.Text color="black" fontSize="18px" userSelectNone>
								token standard:
							</AS.Text>
							<AS.Text color="black" fontSize="18px" fontFamily="Helvetica Neue" style={{ textAlign: 'center' }}>
								ERC-721
							</AS.Text>
							<S.SpacerDiv />
						</S.MetaDataSubRow>
					</S.MetaDataColumn>
					<S.UniqueVisitorsColumn>
						<AS.Text color="black" fontSize="24px" userSelectNone>
							number of
							<br /> unique sales
						</AS.Text>
						<Spacer height={10} />
						<AS.Text color="black" fontSize="24px">
							{storefrontNFT.uniqueSales}
						</AS.Text>
					</S.UniqueVisitorsColumn>
				</S.MetaDataSandboxRow>
			</S.MetaDataCard>
		</S.MetaDataContainer>
	);
};

export default Metadata;
