import { motion } from 'framer-motion';
import styled from 'styled-components/macro';

export const ModalBackdrop = styled(motion.div)`
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background-color: #444444;
	opacity: 0.6;
	z-index: 35;
	overflow: hidden;
`;
export const ModalBody = styled(motion.div)`
	position: fixed;
	max-width: 500px;
	width: 95vw;
	z-index: 36;
	display: flex;
	flex-direction: column;
	padding: 15px;
	background-color: ${({ backgroundColor }) => backgroundColor || 'white'};
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 12px;
	overflow: hidden;
`;

export const ModalBodyHeader = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;
`;
export const ModalBodyHeaderIcon = styled.div`
	width: 27px;
	height: 4px;
	background: #dbe0e3;
	border-radius: 2px;
	margin: 10px 0px -10px;
`;
export const ModalBodyHeaderClose = styled.div``;
export const EmptyDiv = styled.div`
	width: 54px;
`;
export const BackIcon = styled.img`
	cursor: pointer;
`;
