import { Web3Provider } from '@ethersproject/providers';
import { Web3ReactProvider } from '@web3-react/core';
import { StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

// import 'tippy.js/dist/tippy.css';
import { store } from 'state/store';

import 'styles/faq-user-safety.css';
import 'styles/global.css';

import { App } from './App';

const getLibrary = provider => {
	const library = new Web3Provider(provider, 'any');
	library.pollingInterval = 15000;

	return library;
};

const root = createRoot(document.getElementById('root'));

root.render(
	<StrictMode>
		<Web3ReactProvider getLibrary={getLibrary}>
			<Suspense fallback="Loading...">
				<Provider store={store}>
					<App />
				</Provider>
			</Suspense>
		</Web3ReactProvider>
	</StrictMode>
);
