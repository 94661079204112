import { ArrowRight } from '@styled-icons/bootstrap/ArrowRight';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Card } from 'components/Card/Card';
import { Spacer } from 'components/Spacer/Spacer';

import * as AS from 'styles/AppStyles';

const OwnedNFTsCard = ({ title, id, platform, noHover, image }) => {
	let navigate = useNavigate();
	const [toggleBid, setToggleBid] = useState(false);

	// Toggle bid on card hover

	let timeout = null;

	const onMouseEnterFunc = () => {
		timeout = setTimeout(() => setToggleBid(true), 100);
	};

	const onMouseOutFunc = () => {
		if (timeout) {
			clearTimeout(timeout);
		}
		setToggleBid(false);
	};

	return (
		<Card
			noHover={noHover}
			onMouseEnter={!noHover ? onMouseEnterFunc : undefined}
			onMouseLeave={!noHover ? onMouseOutFunc : undefined}
			onClick={() => {
				if (noHover) return;
				navigate(`/list-item/${id}`);
			}}
		>
			<img src={image} alt="ListingsDefaultAvatar" style={{ width: '100%' }} />
			<Spacer height={5} />
			<AS.FlexRow isCentered>
				<AS.Text
					fontFamily="Helvetica"
					fontSize="16px"
					color="black"
					bold
					style={{
						textAlign: 'center',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						width: '55%',
					}}
				>
					{title}
				</AS.Text>
			</AS.FlexRow>
			<Spacer height={5} />
			<AS.FlexRow isCentered>
				<AS.Text fontFamily="Helvetica" fontSize="16px" color="#8E8E8E">
					{platform?.toUpperCase()}
				</AS.Text>
			</AS.FlexRow>
			<Spacer height={5} />
			<AS.FlexRow isCentered>
				<AS.Text fontFamily="Helvetica" fontSize="16px" color="#8E8E8E">
					{`(x, y)`.toUpperCase()}
				</AS.Text>
			</AS.FlexRow>
			<Spacer height={10} />
			<AS.FlexRow>
				{toggleBid && (
					<AS.Column alignItems="center" justifyContent="center" style={{ cursor: 'pointer' }}>
						<AS.FlexRow isCentered>
							<AS.Text fontFamily="Helvetica" fontSize="14px" bold style={{ marginRight: '5px' }}>
								LIST FOR SALE
							</AS.Text>
							<ArrowRight style={{ color: '#1cbf7b', width: '18px' }} />
						</AS.FlexRow>
					</AS.Column>
				)}
			</AS.FlexRow>
		</Card>
	);
};

export default OwnedNFTsCard;
