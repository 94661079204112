import * as S from './styles';

export const Input = ({ label, value, onChange, type, placeholder, password, inputStyle, isCentered }) => {
	return (
		<S.Input
			value={value}
			placeholder={placeholder || label}
			onChange={onChange}
			inputMode={type === 'number' ? 'numeric' : 'none'}
			type={password ? 'password' : 'text'}
			style={{
				...inputStyle,
			}}
		/>
	);
};
