import { FortmaticConnector } from '@web3-react/fortmatic-connector';
import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';

import { RPC_URLS } from 'utils/ETHProvider';

const FORTMATIC_TEST_KEY = 'pk_test_B2BB00DE0ADDAC50';

// Metamask

export const metamask = new InjectedConnector({
	supportedChainIds: [1, 3, 4, 5, 42],
});

// Coinbase

export const coinbase = new WalletLinkConnector({
	url: RPC_URLS[4],
	appName: 'terrena',
	supportedChainIds: [1, 4, 5],
});

// Wallet Connect

export const walletConnect = new WalletConnectConnector({
	rpc: { 4: RPC_URLS[4], 1: RPC_URLS[1] },
	qrcode: true,
	pollingInterval: 15000,
});

// Fortmatic

export const fortmatic = new FortmaticConnector({
	apiKey: FORTMATIC_TEST_KEY ?? '',
	chainId: 1,
});

export const resetWalletConnector = connector => {
	if (connector && (connector instanceof WalletConnectConnector || connector instanceof FortmaticConnector)) {
		connector.walletConnectProvider = undefined;
	}
};

export const activateInjectedProvider = providerName => {
	const { ethereum } = window;

	if (!ethereum) {
		throw new Error('Wallet Extension Not Found!');
	}

	try {
		ethereum.providers.find(({ isFortmatic }) => isFortmatic);
	} catch (error) {
		return;
	}

	let provider;
	switch (providerName) {
		case 'coinbase':
			provider = ethereum.providers.find(({ isCoinbaseWallet }) => isCoinbaseWallet);
			break;
		case 'metamask':
			provider = ethereum.providers.find(({ isMetaMask }) => isMetaMask);
			break;
		case 'fortmatic':
			provider = ethereum.providers.find(({ isFortmatic }) => isFortmatic);
			break;
		case 'web3auth':
			provider = ethereum.providers.find(({ isWeb3auth }) => isWeb3auth);
			break;
		default:
			return undefined;
	}

	if (provider) {
		ethereum.setSelectedProvider(provider);
	}
};
