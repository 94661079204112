import { device } from 'device-dimensions';
import styled, { css } from 'styled-components/macro';

import * as AS from 'styles/AppStyles';

export const WalletConnectContainer = styled.div`
	overflow-x: hidden;
	height: 100%;
	min-height: 100vh;
	position: relative;
	padding: 4%;
	@media ${device.mobileL} {
		padding: 30px 20px;
	}
`;

export const WalletText = styled(AS.Text)`
	font-size: 40px;
	@media ${device.mobileL} {
		font-size: 28px;
		text-align: center;
	}
`;

export const WalletsDiv = styled(AS.Column)`
	height: 350px;
	justify-content: flex-start;
	align-items: center;
`;

export const WalletCard = styled.div`
	display: grid;
	align-items: center;
	grid-template-columns: 45% 55%;
	justify-content: center;
	border: 1px solid #dbdbdb;
	border-radius: 40px;
	width: 70%;
	background-color: white;
	transition: all 0.3s ease-in-out;
	padding: 10px 0;
	font-family: bord;
	color: black;
	font-size: 18px;
	gap: 10px;
	user-select: none;
	&:hover {
		cursor: pointer;
		background: #1cbf7b;
		border: 1px solid #1cbf7b;
		color: white;
		box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
	}
	@media ${device.mobileL} {
		width: 100%;
		grid-template-columns: 50px calc(100% - 50px);
	}
`;

export const WalletLogo = styled.img`
	width: 30px;
	border-radius: 50%;
`;

export const WalletLogoDiv = styled.div`
	display: flex;
	justify-content: flex-end;
`;
