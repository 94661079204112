import { useNavigate } from 'react-router-dom';

import IconsRow from 'components/Icons/IconsRow';
import { Spacer } from 'components/Spacer/Spacer';

import * as AS from 'styles/AppStyles';

import Logo from 'images/Marketplace.webp';

import * as S from './styles';

const MainContainer = () => {
	let navigate = useNavigate();

	return (
		<S.MainContainer>
			<div>
				<AS.FlexRow isCentered>
					<S.MainTitle>TERRENA</S.MainTitle>
				</AS.FlexRow>
				<Spacer height={20} />
				<S.MainContainerRow>
					<AS.Text color="black" fontSize="18px" style={{ textAlign: 'center' }}>
						welcome to the future of digital real estate
					</AS.Text>
				</S.MainContainerRow>
			</div>
			<S.LogoMain src={Logo} alt="logo" />
			<AS.Column style={{ gap: '40px' }}>
				<AS.FlexRow isCentered>
					<S.ExploreButton onClick={() => navigate('/marketplace/all')}>explore</S.ExploreButton>
				</AS.FlexRow>
				<AS.FlexRow isCentered>
					<IconsRow />
				</AS.FlexRow>
			</AS.Column>
		</S.MainContainer>
	);
};

export default MainContainer;
