import { Grid } from '@styled-icons/boxicons-solid/Grid';
import { GridAlt } from '@styled-icons/boxicons-solid/GridAlt';
import { ArrowheadUpOutline } from '@styled-icons/evaicons-outline/ArrowheadUpOutline';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import FiltersSideNav from 'components/Common/Filters/FiltersSideNav';
import { Spacer } from 'components/Spacer/Spacer';

import ListingsRow from './ListingsRow';
import ProfileForSale from './ProfileSections/ProfileForSale';
import ProfileOwned from './ProfileSections/ProfileOwned';
import ProfileTransactions from './ProfileSections/ProfileTransactions';
import ProfileWatchlist from './ProfileSections/ProfileWatchlist';
import * as S from './styles';

const Marketplace = ({ profileSection }) => {
	let navigate = useNavigate();
	const animatedComponents = makeAnimated();
	const { inView } = useInView();
	let { platform: platformURL } = useParams();

	const [openSideNav, setOpenSideNav] = useState(false);
	const [menuIsOpen, setMenuOpen] = useState(false);
	const [platformMenuOpen, setPlatformMenuOpen] = useState(false);
	const [sortByValue, setSortByValue] = useState(null);
	const [platformFilter, setPlatformFilter] = useState({
		value: 'all',
		label: 'Platform',
	});
	const [gridAltSelected, setGridAltSelected] = useState(false);
	const [gridSelected, setGridSelected] = useState(true);
	const [showMarketplaceContainer, setShowMarketplaceContainer] = useState(true);
	const [isMobileFiltersButtonVisible, setIsMobileFiltersButtonVisible] = useState(false);

	// Search bar input

	const [searchNFTsValue, setSearchNFTsValue] = useState('');

	// Sorting Functions sent to FilterSideNav and state sent to different child components in marketplace and profile
	const [NFTStatusFilter, setNFTStatusFilter] = useState(null);
	const [minNFTPrice, setMinNFTPrice] = useState('');
	const [maxNFTPrice, setMaxNFTPrice] = useState('');
	const [minXValue, setMinXValue] = useState('');
	const [maxXValue, setMaxXValue] = useState('');
	const [minYValue, setMinYValue] = useState('');
	const [maxYValue, setMaxYValue] = useState('');
	const [minZValue, setMinZValue] = useState(''); // Somnium Space only
	const [maxZValue, setMaxZValue] = useState('');
	const [applyPrice, setApplyPrice] = useState(false);
	const [applyCoordinates, setApplyCoordinates] = useState(false);
	const [parcelSize, setParcelSize] = useState(''); // Somnium Space only
	const [waterfrontStatus, setWaterfrontStatus] = useState(''); // Somnium Space only
	const [roadsideStatus, setRoadsideStatus] = useState(''); // Somnium Space only
	const [closeToPlazaMin, setCloseToPlazaMin] = useState('0'); // Decentraland only
	const [closeToPlazaMax, setCloseToPlazaMax] = useState('9'); // Decentraland only
	const [closeToRoadMin, setCloseToRoadMin] = useState('0'); // Decentraland only
	const [closeToRoadMax, setCloseToRoadMax] = useState('9'); // Decentraland only
	const [closeToDistrictMin, setCloseToDistrictMin] = useState('0'); // Decentraland only
	const [closeToDistrictMax, setCloseToDistrictMax] = useState('9'); // Decentraland only
	const [applyCloseToRoad, setApplyCloseToRoad] = useState(false); // Decentraland only
	const [applyCloseToPlaza, setApplyCloseToPlaza] = useState(false); // Decentraland only
	const [applyCloseToDistrict, setApplyCloseToDistrict] = useState(false); // Decentraland only
	const [voxelsSize, setVoxelsSize] = useState(''); // Voxels only
	const [island, setIsland] = useState(''); // Voxels only

	// Clear all filters from side nav

	const clearAllFilters = () => {
		setNFTStatusFilter(null);
		setMinNFTPrice('');
		setMaxNFTPrice('');
		setApplyPrice(false);
		setApplyCoordinates(false);
		setMinXValue('');
		setMaxXValue('');
		setMinYValue('');
		setMaxYValue('');
		setMinZValue('');
		setMaxZValue('');
		setCloseToPlazaMin('0');
		setCloseToPlazaMax('9');
		setCloseToRoadMin('0');
		setCloseToRoadMax('9');
		setCloseToDistrictMin('0');
		setCloseToDistrictMax('9');
		setApplyCloseToRoad(false);
		setApplyCloseToPlaza(false);
		setApplyCloseToDistrict(false);
		setParcelSize('');
		setWaterfrontStatus('');
		setRoadsideStatus('');
		setVoxelsSize('');
		setIsland('');
	};

	const isMobile = useMediaQuery({ query: '(max-width: 425px)' });

	// Hide Filters Button when the marketplace container is in view on mobile

	useEffect(() => {
		if (inView) {
			setIsMobileFiltersButtonVisible(false);
		} else {
			setIsMobileFiltersButtonVisible(true);
		}
	}, [inView]);

	// useEffect to hide the body when the mobile filters drawer is open

	useEffect(() => {
		if (openSideNav && isMobile) {
			document.body.style.cssText = 'overflow-y: hidden !important;';
		} else {
			document.body.style.overflowY = '';
		}
	}, [openSideNav, isMobile]);

	// useEffect to change the platform filters when the user changes route

	useEffect(() => {
		if (platformURL) {
			const selectedPlatform = platformOptions.find(platform => platform.value === platformURL);
			setPlatformFilter({
				value: selectedPlatform?.value,
				label: selectedPlatform?.label,
			});
		}
	}, [platformURL]);

	// React Select Styles for Islands

	const sortBySelectStyles = {
		container: base => ({
			...base,
			width: '100%',
		}),
		control: (base, state) => ({
			...base,
			borderRadius: '40px',
			backgroundColor: 'white',
			border: state.isFocused ? '1px solid #838282' : '1px solid #8e8e8e',
			boxShadow: 'none',
			fontFamily: 'Helvetica Neue, sans-serif',
			fontSize: '16px',
			padding: '7px 15px',
			cursor: 'pointer',
			display: 'flex',
			overflow: 'hidden',
			position: 'relative',
			zIndex: 4,
			lineHeight: 1,
			transition: 'all 0.3s ease-in-out',
			alignItems: 'center',
			userSelect: 'none',
			color: '#DBDBDB',
			maxWidth: '250px',
			margin: isMobile && 'auto',
			'&:hover': {
				boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
			},
		}),
		menuPortal: base => ({ ...base, zIndex: 9999 }),
		valueContainer: (styles, { data, isDisabled, isFocused, isSelected }) => {
			return {
				...styles,
				color: 'white',
				padding: 0,
			};
		},
		placeholder: base => ({
			...base,
			color: '#8e8e8e',
			marginRight: '10px',
		}),
		indicatorSeparator: () => ({ display: 'none' }),
		dropdownIndicator: (base, state) => ({
			...base,
			color: '#8e8e8e',
			padding: '2px 2px 2px 0',
			transition: 'transform 0.3s ease-in-out',
			fontSize: '16px',
			transform: menuIsOpen ? 'rotate(180deg)' : null,
			'&:hover': {
				color: '#8e8e8e',
			},
		}),
		option: styles => {
			return {
				...styles,
				position: 'relative',
				backgroundColor: 'white',
				color: 'black',
				textAlign: 'center',
				fontFamily: 'Helvetica Neue, sans-serif',
				fontSize: '16px',
				padding: '15px',
				cursor: 'pointer',
				transition: 'all 0.3s ease-in-out',
				maxWidth: '100%',
				'&:last-child': {
					'&::after': {
						display: 'none',
					},
				},
				'&::after': {
					content: '""',
					height: '1px',
					background: '#ddd8d8',
					width: '90%',
					position: 'absolute',
					left: '10px',
					bottom: 0,
					right: 0,
				},
				'&:hover': {
					backgroundColor: '#F4FCF8',
				},
			};
		},
		menu: base => ({
			...base,
			backgroundColor: '#ffffff',
			borderRadius: '20px',
		}),
		menuList: base => ({
			...base,
			paddingTop: 0,
			paddingBottom: 0,
			borderRadius: '20px',
			zIndex: 3,
			backgroundColor: '#eceaea',
			'  &::-webkit-scrollbar-track': {
				backgroundColor: 'whitesmoke',
				borderRadius: '7px',
				marginRight: '2px',

				'&:hover': {
					backgroundColor: 'whitesmoke',
				},
			},
			'&::-webkit-scrollbar': {
				width: '6px',
				backgroundColor: 'whitesmoke',
				'&:hover': { backgroundColor: 'whitesmoke' },
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: '#8e8e8e',
				borderRadius: '7px',
				transition: 'all 0.3s ease-in-out',
				'&:hover': { backgroundColor: '#757575', cursor: 'pointer !important' },
			},
		}),
	};

	const platformSelectStyles = {
		container: base => ({
			...base,
			width: !profileSection && '100%',
		}),
		control: (base, state) => ({
			...base,
			borderRadius: '40px',
			backgroundColor: 'white',
			border: state.isFocused ? '1px solid #838282' : '1px solid #8e8e8e',
			boxShadow: 'none',
			fontFamily: 'Helvetica Neue, sans-serif',
			fontSize: '16px',
			padding: '7px 15px',
			cursor: 'pointer',
			display: 'flex',
			overflow: 'hidden',
			position: 'relative',
			zIndex: 4,
			lineHeight: 1,
			transition: 'all 0.3s ease-in-out',
			alignItems: 'center',
			userSelect: 'none',
			color: '#DBDBDB',
			maxWidth: !profileSection && '250px',
			margin: isMobile && 'auto',
			width: profileSection && '300px',
			'&:hover': {
				boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
			},
		}),
		menuPortal: base => ({ ...base, zIndex: 9999 }),
		valueContainer: (styles, { data, isDisabled, isFocused, isSelected }) => {
			return {
				...styles,
				color: 'white',
				padding: 0,
			};
		},
		placeholder: base => ({
			...base,
			color: '#8e8e8e',
			marginRight: '10px',
		}),
		indicatorSeparator: () => ({ display: 'none' }),
		dropdownIndicator: (base, state) => ({
			...base,
			color: '#8e8e8e',
			padding: '2px 2px 2px 0',
			transition: 'transform 0.3s ease-in-out',
			fontSize: '16px',
			transform: platformMenuOpen ? 'rotate(180deg)' : null,
			'&:hover': {
				color: '#8e8e8e',
			},
		}),
		option: styles => {
			return {
				...styles,
				position: 'relative',
				backgroundColor: 'white',
				color: 'black',
				textAlign: 'center',
				fontFamily: 'Helvetica Neue, sans-serif',
				fontSize: '16px',
				padding: '15px',
				cursor: 'pointer',
				transition: 'all 0.3s ease-in-out',
				maxWidth: '100%',
				'&:last-child': {
					'&::after': {
						display: 'none',
					},
				},
				'&::after': {
					content: '""',
					height: '1px',
					background: '#ddd8d8',
					width: '90%',
					position: 'absolute',
					left: '10px',
					bottom: 0,
					right: 0,
				},
				'&:hover': {
					backgroundColor: '#F4FCF8',
				},
			};
		},
		menu: base => ({
			...base,
			backgroundColor: '#ffffff',
			borderRadius: '20px',
		}),
		menuList: base => ({
			...base,
			paddingTop: 0,
			paddingBottom: 0,
			borderRadius: '20px',
			zIndex: 3,
			backgroundColor: '#eceaea',
			'  &::-webkit-scrollbar-track': {
				backgroundColor: 'whitesmoke',
				borderRadius: '7px',
				marginRight: '2px',

				'&:hover': {
					backgroundColor: 'whitesmoke',
				},
			},
			'&::-webkit-scrollbar': {
				width: '6px',
				backgroundColor: 'whitesmoke',
				'&:hover': { backgroundColor: 'whitesmoke' },
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: '#8e8e8e',
				borderRadius: '7px',
				transition: 'all 0.3s ease-in-out',
				'&:hover': { backgroundColor: '#757575', cursor: 'pointer !important' },
			},
		}),
	};

	// Dropdown select options

	const sortByOptions = [
		{
			value: 'priceLowToHigh',
			label: 'Price: Low to High',
		},
		{
			value: 'priceHighToLow',
			label: 'Price: High to Low',
		},
		{
			value: 'recentlyListed',
			label: 'Recently Listed',
		},
		{
			value: 'endingSoon',
			label: 'Ending Soon',
		},
	];

	const platformOptions = [
		{
			value: 'all',
			label: 'All',
		},
		{
			value: 'the-sandbox',
			label: 'The Sandbox',
		},
		{
			value: 'decentraland',
			label: 'Decentraland',
		},
		{
			value: 'voxels',
			label: 'Voxels',
		},
		{
			value: 'somnium-space',
			label: 'Somnium Space',
		},
	];

	const sortBySelectFunc = option => {
		const selectedValue = sortByOptions.find(island => island.value === option.value);
		setSortByValue(selectedValue);
		setMenuOpen(false);
	};

	const platformSelectFunc = option => {
		const selectedValue = platformOptions.find(platform => platform.value === option.value);
		if (profileSection) {
			setPlatformMenuOpen(false);
			setPlatformFilter(selectedValue);
			clearAllFilters();
			return;
		}
		setPlatformMenuOpen(false);
		setPlatformFilter(selectedValue);
		clearAllFilters();
		navigate(`/marketplace/${selectedValue.value}`);
	};

	return (
		<S.MarketplaceBody>
			<S.ListingsContainer>
				{!profileSection && (
					<FiltersSideNav
						platform={platformURL}
						openSideNav={openSideNav}
						setOpenSideNav={setOpenSideNav}
						isMobileFiltersButtonVisible={isMobileFiltersButtonVisible}
						NFTStatusFilter={NFTStatusFilter}
						setNFTStatusFilter={setNFTStatusFilter}
						clearAllFilters={clearAllFilters}
						minNFTPrice={minNFTPrice}
						maxNFTPrice={maxNFTPrice}
						setMinNFTPrice={setMinNFTPrice}
						setMaxNFTPrice={setMaxNFTPrice}
						applyPrice={applyPrice}
						setApplyPrice={setApplyPrice}
						minXValue={minXValue}
						setMinXValue={setMinXValue}
						maxXValue={maxXValue}
						setMaxXValue={setMaxXValue}
						minYValue={minYValue}
						setMinYValue={setMinYValue}
						maxYValue={maxYValue}
						setMaxYValue={setMaxYValue}
						minZValue={minZValue}
						setMinZValue={setMinZValue}
						maxZValue={maxZValue}
						setMaxZValue={setMaxZValue}
						applyCoordinates={applyCoordinates}
						setApplyCoordinates={setApplyCoordinates}
						closeToPlazaMin={closeToPlazaMin}
						closeToPlazaMax={closeToPlazaMax}
						setCloseToPlazaMin={setCloseToPlazaMin}
						setCloseToPlazaMax={setCloseToPlazaMax}
						closeToRoadMin={closeToRoadMin}
						closeToRoadMax={closeToRoadMax}
						setCloseToRoadMin={setCloseToRoadMin}
						setCloseToRoadMax={setCloseToRoadMax}
						closeToDistrictMin={closeToDistrictMin}
						closeToDistrictMax={closeToDistrictMax}
						setCloseToDistrictMin={setCloseToDistrictMin}
						setCloseToDistrictMax={setCloseToDistrictMax}
						applyCloseToRoad={applyCloseToRoad}
						setApplyCloseToRoad={setApplyCloseToRoad}
						applyCloseToPlaza={applyCloseToPlaza}
						setApplyCloseToPlaza={setApplyCloseToPlaza}
						applyCloseToDistrict={applyCloseToDistrict}
						setApplyCloseToDistrict={setApplyCloseToDistrict}
						parcelSize={parcelSize}
						setParcelSize={setParcelSize}
						waterfrontStatus={waterfrontStatus}
						setWaterfrontStatus={setWaterfrontStatus}
						roadsideStatus={roadsideStatus}
						setRoadsideStatus={setRoadsideStatus}
						voxelsSize={voxelsSize}
						setVoxelsSize={setVoxelsSize}
						island={island}
						setIsland={setIsland}
						profileSection={profileSection}
					/>
				)}
				<S.SubContainer openSideNav={openSideNav} style={{ width: profileSection && '100%' }}>
					<S.SortByRow openSideNav={openSideNav}>
						<S.SearchInputDiv>
							<S.SearchInputIcon />
							<S.SearchInput
								inputStyle={{ color: 'black' }}
								placeholder="Search"
								type="text"
								value={searchNFTsValue}
								onChange={e => setSearchNFTsValue(e.target.value)}
							/>
						</S.SearchInputDiv>
						<S.ColumnSpacer />
						<S.DropDownRow>
							{!profileSection && (
								<S.SelectRow>
									<Select
										styles={sortBySelectStyles}
										placeholder="Sort by"
										components={animatedComponents}
										options={sortByOptions}
										onChange={sortBySelectFunc}
										closeMenuOnSelect={true}
										value={sortByValue}
										onMenuOpen={() => setMenuOpen(true)}
										onMenuClose={() => setMenuOpen(false)}
										classNamePrefix="select"
										className="react-select"
										menuPortalTarget={document.body}
									/>
								</S.SelectRow>
							)}
							<S.SelectRow style={{ justifyContent: profileSection && 'flex-end' }}>
								<Select
									styles={platformSelectStyles}
									placeholder="Platform"
									components={animatedComponents}
									options={platformOptions}
									onChange={platformSelectFunc}
									closeMenuOnSelect={true}
									value={platformFilter}
									onMenuOpen={() => setPlatformMenuOpen(true)}
									onMenuClose={() => setPlatformMenuOpen(false)}
									classNamePrefix="select"
									className="react-select"
									menuPortalTarget={document.body}
								/>
							</S.SelectRow>
							<S.ReorderIconsRow>
								<S.GridAltIcon
									onClick={() => {
										if (gridAltSelected) {
											return;
										} else if (gridSelected) {
											setGridSelected(false);
											setGridAltSelected(true);
										} else return;
									}}
									gridAltSelected={gridAltSelected}
								>
									<GridAlt style={{ width: '50%' }} />
								</S.GridAltIcon>
								<S.GridIcon
									onClick={() => {
										if (gridSelected) {
											return;
										} else if (gridAltSelected) {
											setGridAltSelected(false);
											setGridSelected(true);
										} else return;
									}}
									gridSelected={gridSelected}
								>
									<Grid style={{ width: '50%' }} />
								</S.GridIcon>
							</S.ReorderIconsRow>
						</S.DropDownRow>
						<S.ArrowheadUpOutlineDiv>
							<ArrowheadUpOutline
								onClick={() => setShowMarketplaceContainer(true)}
								style={{
									width: '25px',
									display: showMarketplaceContainer && 'none',
								}}
							/>
						</S.ArrowheadUpOutlineDiv>
					</S.SortByRow>
					<Spacer height={20} />
					{!profileSection ? (
						<ListingsRow
							gridSelected={gridSelected}
							platformFilter={platformFilter?.value}
							NFTStatusFilter={NFTStatusFilter}
							minNFTPrice={minNFTPrice}
							maxNFTPrice={maxNFTPrice}
							applyPrice={applyPrice}
							minXValue={minXValue}
							maxXValue={maxXValue}
							minYValue={minYValue}
							maxYValue={maxYValue}
							minZValue={minZValue}
							maxZValue={maxZValue}
							applyCoordinates={applyCoordinates}
							closeToPlazaMin={closeToPlazaMin}
							closeToPlazaMax={closeToPlazaMax}
							closeToRoadMin={closeToRoadMin}
							closeToRoadMax={closeToRoadMax}
							closeToDistrictMin={closeToDistrictMin}
							closeToDistrictMax={closeToDistrictMax}
							applyCloseToRoad={applyCloseToRoad}
							applyCloseToPlaza={applyCloseToPlaza}
							applyCloseToDistrict={applyCloseToDistrict}
							parcelSize={parcelSize}
							waterfrontStatus={waterfrontStatus}
							roadsideStatus={roadsideStatus}
							voxelsSize={voxelsSize}
							island={island}
							sortByValue={sortByValue?.value}
							searchNFTsValue={searchNFTsValue}
						/>
					) : profileSection === 'owned' ? (
						<ProfileOwned gridSelected={gridSelected} platformFilter={platformFilter?.value} searchNFTsValue={searchNFTsValue} />
					) : profileSection === 'forSale' ? (
						<ProfileForSale gridSelected={gridSelected} platformFilter={platformFilter?.value} searchNFTsValue={searchNFTsValue} />
					) : profileSection === 'watchlist' ? (
						<ProfileWatchlist gridSelected={gridSelected} platformFilter={platformFilter?.value} searchNFTsValue={searchNFTsValue} />
					) : profileSection === 'transactions' ? (
						<ProfileTransactions gridSelected={gridSelected} platformFilter={platformFilter?.value} searchNFTsValue={searchNFTsValue} />
					) : null}
				</S.SubContainer>
			</S.ListingsContainer>
		</S.MarketplaceBody>
	);
};

export default Marketplace;
