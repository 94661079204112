export const MobileWalletButton = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
		<defs>
			<linearGradient id="a" gradientUnits="userSpaceOnUse" x1="1.333" y1="5.538" x2="47.672" y2="6.751" gradientTransform="scale(.75)">
				<stop offset="0" style={{ stopColor: '#1cbf7b', stopOpacity: '1' }} />
				<stop offset="1" style={{ stopColor: '#00b3cc', stopOpacity: '1' }} />
			</linearGradient>
		</defs>
		<path
			style={{ stroke: 'none', fillRule: 'nonzero', fill: 'url(#a)' }}
			d="M18 0c9.941 0 18 8.059 18 18s-8.059 18-18 18S0 27.941 0 18 8.059 0 18 0Zm0 0"
		/>
		<path
			style={{ stroke: 'none', fillRule: 'nonzero', fill: '#fcfcfc', fillOpacity: '1' }}
			d="M13.29 22.98h10.5c.69 0 1.245-.554 1.245-1.246v-7.468c0-.692-.555-1.246-1.246-1.246h-10.5c-.691 0-1.246.554-1.246 1.246v1.62a1.243 1.243 0 0 0-1.063 1.231v1.781c0 .618.465 1.141 1.063 1.23v1.622c0 .676.559 1.23 1.246 1.23Zm-1.247-5.863c0-.105.078-.183.184-.183h2.308c.3 0 .54.226.54.543v1.062c0 .3-.223.543-.54.543l-2.308-.004c-.106 0-.184-.074-.184-.18Zm1.066 4.617V20.13h1.426c.883 0 1.606-.703 1.606-1.606v-1.062c0-.887-.707-1.606-1.606-1.606H13.11v-1.59c0-.105.075-.18.18-.18h10.5c.106 0 .18.075.18.18v7.47c0 .105-.074.18-.18.18h-10.5a.172.172 0 0 1-.18-.18Zm0 0"
		/>
	</svg>
);
