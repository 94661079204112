import BenefitFromSection from './BenefitFromSection';
import FeaturedListings from './FeaturedListings';
import FutureRealEstate from './FutureRealEstate';
import MainContainer from './MainContainer';

// import GettingStarted from "./GettingStarted";

const Home = () => {
	return (
		<>
			<MainContainer />
			<FeaturedListings />
			<BenefitFromSection />
			<FutureRealEstate />
			{/* This must be added in the future after launch, see with Alejandro */}
			{/* <GettingStarted /> */}
		</>
	);
};

export default Home;
