import { memo } from 'react';

import ListingsCard from 'pages/Marketplace/ListingsCard';

import { chooseRandomElement } from 'utils/chooseRandomElement';

import * as S from './styles';

const MoreFrom = memo(({ allNFTs, storefrontNFT }) => {
	// Calculate more from by choosing 5 or less random NFTs from the same platform

	const moreFromNFTs = nfts => {
		const newNFTArray = nfts.filter(nft => {
			const f1 = nft.details.endDate.toNumber() * 1000 > Date.now();
			const f2 = nft.details.isActive;
			const f3 = nft.platform === storefrontNFT.platform;
			const f4 = nft.id !== storefrontNFT.id;
			return f1 && f2 && f3 && f4;
		});
		const secondNewArray = chooseRandomElement(newNFTArray, 5);
		return secondNewArray;
	};

	return (
		<S.MoreFromRow>
			<>
				{moreFromNFTs(allNFTs).map(nft => {
					return <ListingsCard key={nft.id} nft={nft} />;
				})}
			</>
		</S.MoreFromRow>
	);
});

export default MoreFrom;
