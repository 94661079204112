import { useNavigate } from 'react-router-dom';

import { Button } from 'components/Button/Button';
import { Spacer } from 'components/Spacer/Spacer';

import * as AS from 'styles/AppStyles';

import Decentraland from 'images/Decentraland.webp';
import SomniumSpace from 'images/SomniumSpaceLogo.webp';
import TheSandbox from 'images/TheSandboxLogo.webp';
import Voxels from 'images/Voxels.webp';

import * as S from './styles';

const BenefitFromSection = () => {
	let navigate = useNavigate();

	return (
		<>
			<S.BenefitFromContainer>
				<AS.Column isCentered>
					<S.BenefitFromHeader>
						<AS.Text fontSize="36px" color="black">
							benefit from
						</AS.Text>
						<br />
						<AS.FlexRow>
							<AS.Text fontSize="36px" color="black">
								a <span style={{ color: '#1cbf7b' }}>zero-fee</span> marketplace
							</AS.Text>
						</AS.FlexRow>
					</S.BenefitFromHeader>
					<Spacer height={20} />
					<AS.FlexRow style={{ maxWidth: '800px' }}>
						<AS.Text fontFamily="Helvetica Neue" fontSize="18px" color="black">
							For a limited time, TERRENA will allow users to list their assets for sale/auction for free. There will be zero applicable
							marketplace/service fees. This does not include any potential creator/royalty fees that may be imposed by the original
							creators.
						</AS.Text>
					</AS.FlexRow>
					<Spacer height={20} />
					<AS.FlexRow style={{ maxWidth: '800px' }}>
						<AS.Text fontFamily="Helvetica Neue" fontSize="18px" color="black">
							The zero-fee marketplace is temporary and is the initial incentive for the early users of TERRENA. This will last as long as
							the platform is in beta.
						</AS.Text>
					</AS.FlexRow>
					<Spacer height={30} />
					<S.BenefitFromButtonRow>
						<Button
							text="list my land for sale"
							style={{
								color: 'white',
								background: '#1CBF7B',
								width: '331px',
							}}
							onClick={() => navigate('/list-item')}
						/>
					</S.BenefitFromButtonRow>
				</AS.Column>
			</S.BenefitFromContainer>
			<S.SupportedPlatformsContainer>
				<AS.Column alignItems="center">
					<AS.Text fontSize="36px" color="black" style={{ textAlign: 'center' }}>
						supported platforms
					</AS.Text>
					<Spacer height={50} />
					<S.PlatformRow>
						<AS.Column style={{ gap: '15px' }}>
							<AS.FlexRow isCentered>
								<S.PlatFormDiv onClick={() => navigate('marketplace/the-sandbox')}>
									<S.PlatformImages src={TheSandbox} alt="TheSandbox" />
								</S.PlatFormDiv>
							</AS.FlexRow>
							<AS.FlexRow isCentered>
								<S.PlatFormDiv onClick={() => navigate('marketplace/voxels')}>
									<S.PlatformImages src={Voxels} alt="Voxels" style={{ width: '15%' }} />
								</S.PlatFormDiv>
							</AS.FlexRow>
						</AS.Column>
						<AS.Column style={{ gap: '15px' }}>
							<AS.FlexRow isCentered>
								<S.PlatFormDiv onClick={() => navigate('marketplace/decentraland')}>
									<S.PlatformImages src={Decentraland} alt="Decentraland" style={{ width: '60%' }} />
								</S.PlatFormDiv>
							</AS.FlexRow>
							<AS.FlexRow isCentered>
								<S.PlatFormDiv onClick={() => navigate('marketplace/somnium-space')}>
									<S.PlatformImages src={SomniumSpace} alt="SomniumSpace" style={{ width: '30%' }} />
								</S.PlatFormDiv>
							</AS.FlexRow>
						</AS.Column>
					</S.PlatformRow>
				</AS.Column>
			</S.SupportedPlatformsContainer>
		</>
	);
};

export default BenefitFromSection;
