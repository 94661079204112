import { RefreshOutline } from '@styled-icons/evaicons-outline/RefreshOutline';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getNFTs } from 'state/features/NFTSlice';

import { convertToETH } from 'utils/convertCurrencies';

import * as AS from 'styles/AppStyles';

import ListingsCard from './ListingsCard';
import * as S from './styles';

const ListingsRow = ({
	gridSelected,
	platformFilter,
	NFTStatusFilter,
	minNFTPrice,
	maxNFTPrice,
	applyPrice,
	minXValue,
	maxXValue,
	minYValue,
	maxYValue,
	minZValue,
	maxZValue,
	applyCoordinates,
	closeToPlazaMin,
	closeToPlazaMax,
	closeToRoadMin,
	closeToRoadMax,
	closeToDistrictMin,
	closeToDistrictMax,
	applyCloseToRoad,
	applyCloseToPlaza,
	applyCloseToDistrict,
	parcelSize,
	waterfrontStatus,
	roadsideStatus,
	voxelsSize,
	island,
	sortByValue,
	searchNFTsValue,
}) => {
	const dispatch = useDispatch();

	const [filteredTokens, setFilteredTokens] = useState([]);
	const [refreshTokens, setRefreshTokens] = useState(false);

	// Fetch all NFT's from the store
	const allNFTs = useSelector(state => state.NFTs.value);

	// useEffect to refresh the token list when the user clicks the refresh icon

	useEffect(() => {
		if (refreshTokens) {
			dispatch(getNFTs());
			return () => {
				setRefreshTokens(false);
			};
		}
	}, [dispatch, refreshTokens]);

	// Sort by Filter from market

	const sortByFilter = (token, sortByValue) => {
		if (sortByValue === 'recentlyListed') {
			return token.details.startDate.toNumber() > new Date().getTime() / 1000 - 86400;
		} else if (sortByValue === 'endingSoon') {
			return token.details.endDate.toNumber() - new Date().getTime() / 1000 <= 86400;
		}
		return true;
	};

	// Status Filter from marketplace

	const nftStatus = (token, NFTStatusFilter) => {
		if (NFTStatusFilter === 'onAuction') {
			return token.details.isAuctionAllowed === true;
		} else if (NFTStatusFilter === 'buyNow') {
			return token.details.isBuyNowAllowed === true;
		} else if (NFTStatusFilter === 'hasOffers') {
			return token.details.highestBid > 0;
		} else if (NFTStatusFilter === 'new') {
			return token.details.startDate.toNumber() > new Date().getTime() / 1000 - 86400;
		}
		return true;
	};

	// Apply Price Range Filter when the user clicks apply on the filters side

	const applyPriceFilter = (token, minPrice, maxPrice) => {
		if (applyPrice) {
			return (
				convertToETH(token.details.startingPrice.toString()) >= minPrice && convertToETH(token.details.buyNowPrice.toString()) <= maxPrice
			);
		}
		return true;
	};

	// Apply Coordinates Filter when the user clicks apply on the filters side

	const applyCoordinatesFilter = (token, minXValue, maxXValue, minYValue, maxYValue, minZValue, maxZValue) => {
		if (applyCoordinates) {
			if (platformFilter === 'somnium-space') {
				if (!minXValue && minYValue && minZValue) {
					return token.yValue >= minYValue && token.yValue <= maxYValue && token?.zValue >= minZValue && token?.zValue <= maxZValue;
				} else if (!minYValue && minXValue && minZValue) {
					return token.xValue >= minXValue && token.xValue <= maxXValue && token?.zValue >= minZValue && token?.zValue <= maxZValue;
				} else if (!minZValue && minXValue && minYValue) {
					return token.xValue >= minXValue && token.xValue <= maxXValue && token.yValue >= minYValue && token.yValue <= maxYValue;
				} else if (!minYValue && !minZValue && minXValue) {
					return token.xValue >= minXValue && token.xValue <= maxXValue;
				} else if (!minXValue && !minZValue && minYValue) {
					return token.yValue >= minYValue && token.yValue <= maxYValue;
				} else if (!minXValue && !minYValue && minZValue) {
					return token?.zValue >= minZValue && token.zValue <= maxZValue;
				}
				return (
					token.xValue >= minXValue &&
					token.xValue <= maxXValue &&
					token.yValue >= minYValue &&
					token.yValue <= maxYValue &&
					token?.zValue >= minZValue &&
					token?.zValue <= maxZValue
				);
			} else if (platformFilter === 'decentraland' || platformFilter === 'voxels' || platformFilter === 'the-sandbox') {
				if (!minYValue) {
					return token.xValue >= minXValue && token.xValue <= maxXValue;
				} else if (!minXValue) {
					return token.yValue >= minYValue && token.yValue <= maxYValue;
				}
				return token.xValue >= minXValue && token.xValue <= maxXValue && token.yValue >= minYValue && token.yValue <= maxYValue;
			}
		} else {
			return true;
		}
	};

	// Apply Close To Filters when the user clicks apply on the filters side

	const applyCloseToRoadFilter = (token, closeToRoadMin, closeToRoadMax) => {
		if (applyCloseToRoad) {
			if (!token?.distanceToRoad) {
				return false;
			}
			return token?.distanceToRoad >= closeToRoadMin && token?.distanceToRoad <= closeToRoadMax;
		}
		return true;
	};

	const applyCloseToPlazaFilter = (token, closeToPlazaMin, closeToPlazaMax) => {
		if (applyCloseToPlaza) {
			if (!token?.distanceToPlaza) {
				return false;
			}
			return token?.distanceToPlaza >= closeToPlazaMin && token?.distanceToPlaza <= closeToPlazaMax;
		}
		return true;
	};

	const applyCloseToDistrictFilter = (token, closeToDistrictMin, closeToDistrictMax) => {
		if (applyCloseToDistrict) {
			if (!token?.distanceToDistrict) {
				return false;
			}
			return token?.distanceToDistrict >= closeToDistrictMin && token?.distanceToDistrict <= closeToDistrictMax;
		}
		return true;
	};

	useEffect(() => {
		const filteredTokens = allNFTs
			?.filter(token => {
				const b1 = token.details.isActive;
				const b2 = token.details.endDate.toNumber() * 1000 > Date.now();
				const b3 = platformFilter === 'all' ? true : token.platform === platformFilter;
				const f1 = nftStatus(token, NFTStatusFilter);
				const f2 = applyPriceFilter(token, minNFTPrice, maxNFTPrice);
				const f3 = applyCoordinatesFilter(token, minXValue, maxXValue, minYValue, maxYValue, minZValue, maxZValue);
				const f4 = applyCloseToDistrictFilter(token, closeToDistrictMin, closeToDistrictMax);
				const f5 = applyCloseToPlazaFilter(token, closeToPlazaMin, closeToPlazaMax);
				const f6 = applyCloseToRoadFilter(token, closeToRoadMin, closeToRoadMax);
				const f7 = parcelSize === '' ? true : token.parcelSize === parcelSize;
				const f8 = roadsideStatus === '' ? true : token.roadsideStatus === roadsideStatus;
				const f9 = waterfrontStatus === '' ? true : token.waterfrontStatus === waterfrontStatus;
				const f10 = voxelsSize === '' ? true : token.size === voxelsSize;
				const f11 = island === '' ? true : token.island === island;
				const f12 = sortByFilter(token, sortByValue);
				const f13 = searchNFTsValue === '' ? true : token.title?.toLowerCase().includes(searchNFTsValue.toLowerCase());
				return b1 && b2 && b3 && f1 && f2 && f3 && f4 && f5 && f6 && f7 && f8 && f9 && f10 && f11 && f12 && f13;
			})
			.sort((a, b) => {
				if (sortByValue === 'priceLowToHigh') {
					return a.details.buyNowPrice.toString() - b.details.buyNowPrice.toString();
				} else if (sortByValue === 'priceHighToLow') {
					return b.details.buyNowPrice.toString() - a.details.buyNowPrice.toString();
				}
				return a;
			});
		setFilteredTokens(filteredTokens);
	}, [
		NFTStatusFilter,
		applyPrice,
		applyCoordinates,
		platformFilter,
		applyCloseToDistrict,
		applyCloseToPlaza,
		applyCloseToRoad,
		parcelSize,
		roadsideStatus,
		waterfrontStatus,
		voxelsSize,
		island,
		sortByValue,
		searchNFTsValue,
	]);

	return (
		<>
			<S.ListingsFoundDiv>
				<S.RefreshOutlineDiv onClick={() => setRefreshTokens(true)}>
					<RefreshOutline />
				</S.RefreshOutlineDiv>
				<AS.Text color="black" fontSize="18px" fontFamily="Helvetica Neue" userSelectNone>
					<span style={{ fontWeight: 'bold' }}>{filteredTokens.length}</span> Listings Found
				</AS.Text>
			</S.ListingsFoundDiv>
			<S.ListingsCardRow gridSelected={gridSelected}>
				{filteredTokens?.map(nft => {
					return <ListingsCard key={nft.id} noHover={false} nft={nft} />;
				})}
			</S.ListingsCardRow>
		</>
	);
};

export default ListingsRow;
