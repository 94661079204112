import * as S from './ButtonSpinner.styles';

const ButtonSpinner = ({ style, borderColor }) => {
	return (
		<S.ButtonSpinner style={{ ...style }}>
			<div style={{ border: borderColor }}></div>
		</S.ButtonSpinner>
	);
};

export default ButtonSpinner;
