import { ArrowRight } from '@styled-icons/bootstrap/ArrowRight';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Overlay } from 'pages/ListItem/styles';

import { Card } from 'components/Card/Card';
import { StyledLoader } from 'components/Loader/StyledLoader';
import { Spacer } from 'components/Spacer/Spacer';

import { finalizeSaleNFT } from 'state/features/NFTSlice';

import * as AS from 'styles/AppStyles';

import * as S from '../styles';

const TransactionCard = ({ title, platform, image, NFTData, finalPrice, isSeller, isBuyer }) => {
	const dispatch = useDispatch();

	const [toggleBid, setToggleBid] = useState(false);

	// Get Loading State during List NFT

	const loadingState = useSelector(state => state.loading.loading);

	// useEffect to hide the body when the global spinner is active

	useEffect(() => {
		if (loadingState) {
			document.body.style.cssText = 'overflow-y: hidden !important;';
		} else {
			document.body.style.overflowY = '';
		}
	}, [loadingState]);

	// Toggle bid on card hover

	let timeout = null;

	const onMouseEnterFunc = () => {
		timeout = setTimeout(() => setToggleBid(true), 100);
	};

	const onMouseOutFunc = () => {
		if (timeout) {
			clearTimeout(timeout);
		}
		setToggleBid(false);
	};

	const handleOnClick = () => {
		dispatch(finalizeSaleNFT({ NFTData }));
	};

	return (
		<Card onMouseEnter={onMouseEnterFunc} onMouseLeave={onMouseOutFunc} onClick={handleOnClick}>
			{loadingState && (
				<>
					<Overlay />
					<StyledLoader style={{ zIndex: 6 }} />
				</>
			)}
			<AS.Text color="black" fontSize="18px" fontFamily="Helvetica Neue" style={{ textAlign: 'center', fontWeight: 'bold' }}>
				{isBuyer ? 'Get Purchased NFT' : 'Confirm NFT Sale'}
			</AS.Text>
			<Spacer height={10} />
			<img src={image} alt="ListingsDefaultAvatar" style={{ width: '100%' }} />
			<Spacer height={5} />
			<AS.FlexRow isCentered>
				<AS.Text
					fontFamily="Helvetica"
					fontSize="16px"
					color="black"
					bold
					style={{
						textAlign: 'center',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						width: '55%',
					}}
				>
					{title}
				</AS.Text>
			</AS.FlexRow>
			<Spacer height={5} />
			<AS.FlexRow isCentered>
				<AS.Text fontFamily="Helvetica" fontSize="16px" color="#8E8E8E">
					{platform?.toUpperCase()}
				</AS.Text>
			</AS.FlexRow>
			<Spacer height={5} />
			<AS.FlexRow isCentered>
				<AS.Text fontFamily="Helvetica" fontSize="16px" color="#8E8E8E">
					{`(x, y)`.toUpperCase()}
				</AS.Text>
			</AS.FlexRow>
			<Spacer height={10} />
			<S.Divider />
			<Spacer height={toggleBid ? 20 : 10} />
			<AS.FlexRow style={{ justifyContent: 'space-evenly' }}>
				{!toggleBid ? (
					<>
						<AS.Column alignItems="center">
							<AS.Text fontFamily="Helvetica" fontSize="14px" color="#8E8E8E">
								Final Price
							</AS.Text>
							<AS.Text fontFamily="Helvetica" fontSize="16px" color="black" bold>
								{finalPrice}
							</AS.Text>
						</AS.Column>
					</>
				) : (
					<AS.Column alignItems="center" justifyContent="center" style={{ cursor: 'pointer' }}>
						<AS.FlexRow isCentered>
							<AS.Text fontFamily="Helvetica" fontSize="16px" bold style={{ marginRight: '5px' }}>
								FINALIZE
							</AS.Text>
							<ArrowRight style={{ color: '#1cbf7b', width: '18px' }} />
						</AS.FlexRow>
					</AS.Column>
				)}
			</AS.FlexRow>
		</Card>
	);
};

export default TransactionCard;
