import Tippy from '@tippyjs/react';
import Torus from '@toruslabs/torus-embed';
import { useWeb3React } from '@web3-react/core';
import { useCallback, useState } from 'react';
import Collapsible from 'react-collapsible';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { Burger, ChevronDown, MobileWalletButton, Wallet } from 'svg';
import { useLockedBody } from 'usehooks-ts';

import { setWallet } from 'state/features/walletSlice';

import { cropAddress } from 'utils/cropAddress';

import logoIcon from 'images/logo-icon.webp';

import { communityLinks, navigationLinks, navigationSectionTitle } from '../../constants';
import { useIsInBreakpoint } from '../../hooks/useIsInBreakpoint';
import './style.css';

export const Header = () => {
	const dispatch = useDispatch();
	const { deactivate } = useWeb3React();
	const [tippy, setTippy] = useState({});
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
	const isMobileBreakpoint = useIsInBreakpoint('1200px');
	const walletName = useSelector(state => state.wallet.walletName);
	const { active, address, balance } = useSelector(state => state.wallet);

	useLockedBody(isMobileBreakpoint && isMobileMenuOpen, 'root');

	const deactivateWallet = useCallback(async () => {
		try {
			if (walletName === 'Torus') {
				const torus = new Torus({});
				await torus.init({ enableLogging: false });
				await torus.login();
				await torus.logout();
				dispatch(setWallet({ active: false, address: '', balance: null, walletName: '' }));
			} else {
				deactivate();
				dispatch(setWallet({ active: false, address: '', balance: null, walletName: '' }));
			}
		} catch (error) {
			console.error('🚀 ~ file: index.js ~ line 36 ~ deactivateWal ~ error', error);
		}
	}, [deactivate, dispatch, walletName]);

	const handleBurgerButtonClick = useCallback(() => {
		setIsMobileMenuOpen(isMobileMenuOpen => !isMobileMenuOpen);
	}, []);

	return (
		<header className="header">
			<Link className="logo" to="/" title="Home page" onClick={handleBurgerButtonClick}>
				<img src={logoIcon} alt="Logo" />
				terrena
			</Link>
			<nav className="desktop-navigation">
				<ul>
					{Object.entries(navigationLinks).map(([name]) => (
						<li key={name}>
							<Tippy
								trigger="click"
								interactive
								offset={[-10, 20]}
								onCreate={instance => setTippy(instance)}
								content={
									<div className="popover-content">
										<ul>
											{navigationLinks[name].map(({ title, to }) => (
												<li key={title}>
													<NavLink to={to} onClick={() => tippy.hide(0)}>
														{title}
													</NavLink>
												</li>
											))}
										</ul>
									</div>
								}
							>
								<div className="popover-trigger">
									{navigationSectionTitle[name]}
									<ChevronDown />
								</div>
							</Tippy>
						</li>
					))}
				</ul>
			</nav>
			{active ? (
				<Tippy
					trigger="click"
					interactive
					className="wallet-view"
					offset={[-40, 20]}
					onCreate={instance => setTippy(instance)}
					content={
						<div className="popover-content">
							<div className="popover-content-item">
								<strong>wallet address: </strong>
								{cropAddress(address, 13)}
							</div>
							<div className="popover-content-item">
								<strong>wallet balance: </strong>
								{balance}
							</div>
							<button className="btn" onClick={deactivateWallet}>
								Disconnect Wallet
							</button>
						</div>
					}
				>
					{isMobileBreakpoint ? (
						<button className="mobile-wallet-button">
							<MobileWalletButton />
						</button>
					) : (
						<button className="btn popover-trigger">
							<Wallet /> | View Wallet
						</button>
					)}
				</Tippy>
			) : isMobileBreakpoint ? (
				<Link to="/wallet-connect" title="Connect Wallet">
					<MobileWalletButton />
				</Link>
			) : (
				<Link className="btn-link" to="/wallet-connect" title="Connect Wallet">
					<Wallet /> | Connect Wallet
				</Link>
			)}

			{isMobileBreakpoint && (
				<button className={`burger-btn ${isMobileMenuOpen ? 'is-open' : ''}`} onClick={handleBurgerButtonClick}>
					<Burger />
				</button>
			)}

			{isMobileBreakpoint && (
				<nav className={`mobile-navigation ${isMobileMenuOpen ? 'is-open' : ''}`}>
					<ul className="mobile-navigation-list">
						{Object.entries(navigationLinks).map(([name]) => (
							<Collapsible
								trigger={
									<button className="navigation-section-button">
										<strong>{navigationSectionTitle[name]}</strong>
										<svg width="21" height="11" viewBox="0 0 23 13">
											<path
												d="M11.666 12.765a1.579 1.579 0 0 1-1.118-.443L1.442 3.194A1.595 1.595 0 0 1 1.43.942 1.591 1.591 0 0 1 3.683.93l7.983 8.01L19.648.93a1.587 1.587 0 0 1 2.242 2.242l-9.106 9.151c-.3.29-.7.448-1.118.442Z"
												fill="#111"
											/>
										</svg>
									</button>
								}
							>
								<div className="navigation-section-content">
									<ul>
										{navigationLinks[name].map(({ title, to }) => (
											<li key={title}>
												<NavLink to={to} onClick={handleBurgerButtonClick}>
													{title}
												</NavLink>
											</li>
										))}
									</ul>
								</div>
							</Collapsible>
						))}
					</ul>
					<div className="community-links">
						<strong>join the community</strong>
						<ul>
							{communityLinks.map(({ icon, href, title }) => (
								<li key={href}>
									<a href={href} target="_blank" rel="noopener noreferrer" title={title}>
										{icon}
									</a>
								</li>
							))}
						</ul>
					</div>
				</nav>
			)}
		</header>
	);
};
