import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	withWallet: null,
	noWallet: null,
};

export const marketplaceContractSlice = createSlice({
	name: 'marketplaceContract',
	initialState,
	reducers: {
		setMarketplaceContractWithWallet: (state, action) => {
			return {
				...state,
				withWallet: action.payload,
			};
		},
		setMarketplaceContractNoWallet: (state, action) => {
			return {
				...state,
				noWallet: action.payload,
			};
		},
	},
});

export const { setMarketplaceContractWithWallet, setMarketplaceContractNoWallet } = marketplaceContractSlice.actions;

export default marketplaceContractSlice.reducer;
